import { Box } from '@mui/material'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ProjectsIcon } from '../img/customIcons/ProjectsIcon'
import { ProjectsPage } from '../pages/ProjectsPage/ProjectsPage'
import { MappingIcon } from '../img/customIcons/MappingIcon'
import { MappingPage } from '../pages/_OLD/mapping/mapping-page'
import PersonIcon from '@mui/icons-material/Person'
import { BudgetsIcon } from '../img/customIcons/BudgetsIcon'
import { BDRIcon } from '../img/customIcons/BDRIcon'
import { OldUsers } from '../pages/_OLD/users/users'
import { SettingsPage } from '../pages/_OLD/settings/settings'
import { Class, Dashboard, Settings } from '@mui/icons-material'
import { ProductionProgramPage } from '../pages/_OLD/productionProgram/ProductionProgram'
import { BudgetListPage } from '../pages/BudgetListPage/BudgetListPage'
import { AddUser, EditUser, Users } from '../pages/Users'
import { BDDSIcon } from '../img/customIcons/BDDSIcon'
import { BudgetPageNew } from '../pages/BudgetPageNew/BudgetPageNew'
import { BdrReportPage } from '../pages/ReportPage/BdrReportPage'
import { ReportListPage } from '../pages/ReportListPage/ReportListPage'
import { ProjectMenu } from '../pages/ProjectMenu/ProjectMenu'
import { useContext } from 'react'
import { InterfaceContext } from '../contexts/context.interface'
import React from 'react'
import { ProductionProgram } from '../pages/ProductionProgram/ProductionProgram'
import { ReferencePage } from '../pages/ReferencePage/ReferencePage'
import { SettingsPageNew } from '../pages/SettingsPage/SettingsPageNew'
import { MappingPageNew } from '../pages/MappingPage/MappingPage'
import { Fact } from '../pages/Fact/Fact'
import { FactIcon } from '../img/customIcons/FactIcon'
import { FactContainer } from '../pages/Fact/FactContainer'
import { MappingInvestIcon } from '../img/customIcons/MappingInvestIcon'
import { BddsReportPage } from 'pages/ReportPage/BddsReportPage'
import { ReportPage } from 'pages/ReportPage/ReportPage'

export interface IPage {
  title: string
  path: string
  exact?: boolean
  component: React.ReactNode | JSX.Element
  icon?: React.ReactNode
  subPages?: IPage[]
  groupBy?: 'Проекты' | 'Бюджеты' | 'БДР' | 'БДДС' | 'Капвложения'
  pageType: 'main' | 'sub' | 'internal'
}

export type TSubPageGroup = 'Проекты'
/* FactContainer */
export const PAGES: IPage[] = [
  {
    title: 'Проекты',
    path: '/projects',
    exact: true,
    component: <ProjectsPage />,
    icon: <ProjectsIcon />,
    pageType: 'main',
  },
  {
    title: 'Меню проекта',
    path: '/projects/:projectID/menu',
    component: <ProjectMenu />,
    icon: <Dashboard />,
    pageType: 'sub',
    groupBy: 'Проекты',
  },
  {
    title: 'Бюджеты',
    exact: true,
    path: '/projects/:projectID/budgets',
    component: <BudgetListPage />,
    icon: <BudgetsIcon />,
    pageType: 'sub',
    groupBy: 'Проекты',
  },
  {
    title: 'Бюджет ЦФО проекта',
    exact: true,
    path: '/projects/:projectID/budgets/budget/:financeCenterID',
    component: <BudgetPageNew />,
    icon: <ProjectsIcon />,
    groupBy: 'Бюджеты',
    pageType: 'internal',
  },
  {
    title: 'Факт',
    exact: true,
    path: '/projects/:projectID/fact',
    component: <FactContainer />,
    icon: <FactIcon />,
    pageType: 'sub',
    groupBy: 'Проекты',
  },
  {
    title: 'БДР',
    exact: true,
    path: '/projects/:projectID/reports/bdr/',
    component: <ReportListPage />,
    icon: <BDRIcon />,
    pageType: 'sub',
    groupBy: 'Проекты',
  },
  {
    title: 'Капвложения',
    exact: true,
    path: '/projects/:projectID/reports/capital',
    component: <ReportListPage />,
    icon: <BDRIcon />,
    pageType: 'sub',
    groupBy: 'Проекты',
  },
  {
    title: 'БДР отчет',
    exact: true,
    path: '/projects/:projectID/reports/bdr/report',
    component: <BdrReportPage />,
    icon: <BDRIcon />,
    groupBy: 'БДР',
    pageType: 'internal',
  },
  {
    title: 'Отчет Капвложений',
    exact: true,
    path: '/projects/:projectID/reports/capital/report',
    component: <BdrReportPage />,
    icon: <BDRIcon />,
    groupBy: 'Капвложения',
    pageType: 'internal',
  },
  {
    title: 'БДДС',
    exact: true,
    path: '/projects/:projectID/reports/bdds',
    component: <ReportListPage />,
    icon: <BDDSIcon />,
    pageType: 'sub',
    groupBy: 'Проекты',
  },
  {
    title: 'БДДС отчет',
    exact: true,
    path: '/projects/:projectID/reports/bdds/report',
    component: <BddsReportPage />,
    icon: <BDRIcon />,
    groupBy: 'БДДС',
    pageType: 'internal',
  },
  // { title: 'БДДС отчет', exact: true, path: '/projects/:projectID/reports/bdds/capital/report', component: <ReportPage />, icon: <BDRIcon />, groupBy: "БДДС", pageType: "internal"},
  {
    title: 'Производственная программа проекта',
    path: '/projects/:projectID/productionProgram/:financeCenterID',
    component: <ProductionProgram />,
    icon: <ProjectsIcon />,
    groupBy: 'Бюджеты',
    pageType: 'internal',
  },
  // { title: 'Производственная программа проекта', path: '/projects/:projectID/productionProgram/:financeCenterID/:budgetStartYear/:contentType', component: <ProductionProgramPage />, icon: <ProjectsIcon />, groupBy: "Бюджеты", pageType: "internal"},
  // { title: 'БДР отчет', exact: true, path: '/projects/:projectID/BDRReport/:savedReportID?', component: <BDRReportPage />, pageType: "internal", groupBy: "БДР"},
  // { title: 'Мэппинг статей', path: '/mapping', component: <MappingPage />, icon: <MappingIcon />, pageType: "main" },
  {
    title: 'Мэппинг для генподряда',
    path: '/mappingNew',
    exact: true,
    component: <MappingPageNew budgetType={'GEN'} />,
    icon: <MappingIcon />,
    pageType: 'main',
  },
  {
    title: 'Мэппинг для инвестпроектов',
    path: '/mappingNewInvest',
    exact: true,
    component: <MappingPageNew budgetType={'INVEST'} />,
    icon: <MappingInvestIcon />,
    pageType: 'main',
  },
  {
    title: 'Справочник',
    path: '/reference',
    exact: true,
    component: <ReferencePage />,
    icon: <Class />,
    pageType: 'main',
  },
  { title: 'Пользователи', path: '/users', exact: true, component: <Users />, icon: <PersonIcon />, pageType: 'main' },
  { title: 'Создание пользователя', path: '/users/add', exact: true, component: <AddUser />, pageType: 'sub' },
  {
    title: 'Управление пользователем',
    path: '/users/edit/:userId',
    exact: true,
    component: <EditUser />,
    pageType: 'sub',
  },
  // { title: 'Настройки', path: '/settings', component: <SettingsPage />, icon: <Settings />, pageType: "main"},
  { title: 'Настройки', path: '/settingsNew', component: <SettingsPageNew />, icon: <Settings />, pageType: 'main' },
]

export function ActivePage() {
  const location = useLocation()

  React.useEffect(() => {
    location.pathname == '' && <Redirect to="/projects" />
  }, [location.pathname])

  return (
    <Box>
      <Switch>
        {PAGES.map((page: IPage) => {
          return (
            <Route key={page.title} exact={page.exact} path={page.path}>
              {page.component}
            </Route>
          )
        }).reverse()}
        <Redirect from="*" to="/projects" />
      </Switch>
    </Box>
  )
}
