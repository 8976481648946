import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { TFormatSelectionValue } from 'pages/BudgetPageNew/components/FormatSelectionDialog/FormatSelectionDialog.types'
import { SyntheticEvent } from 'react'
import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form'
import { UserCompanyInfo } from '../../api/users/types'
import { UserRolesEn, UserRoleTabLabels } from '../../pages/Users'

export type ProjectType = 'GEN' | 'INVEST'

export const typesProjectOptions: ProjectType[] = ['GEN', 'INVEST']

export type TMonthName =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
export type TQuarterName = 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH' | 'ALL'
export type TEEMode = 'edit' | 'view'
export type TFinanceCenterType = 'DEPARTMENT' | 'SUBDIVISION' | 'PRODUCTION_PROGRAM'
export type TBudgetCategory = 'operations' | 'finances'
export type TOperationType = 'INCOME' | 'OUTCOME'
export type TReportType = 'YEARLY' | 'QUARTERLY' | 'MONTHLY'
export type TRangeValue = TMonthName | TQuarterName | string | number | undefined
export type TProjectStatus = 'ACTIVE' | 'ARCHIVED'
export type TBDRArticleType =
  | 'INCOME'
  | 'OUTCOME'
  | 'OTHER_INCOMES_OUTCOMES'
  | 'OTHER_INCOMES'
  | 'OTHER_OUTCOMES'
  | 'EXPENSES'
  | 'DIRECT_EXPENSES'
  | 'INDIRECT_EXPENSES'
  | 'OTHER_INCOME'
  | 'OTHER_EXPENSES'
  | 'TAXES'
  | 'OTHER_INCOME_EXPENSES'
export type TBDDSArticleFlow = 'OPERATIONS' | 'FINANCIAL' | 'INVEST'
export type TAnalisisType = 'PLAN' | 'FACT' | 'BALANCE'
export type TAnalisisTypeLower = 'plan' | 'fact' | 'balance'
export type TESNParamsVariant =
  | 'FULL'
  | 'BENEFITS_FOR_SMALL_AND_MEDIUM_BUSINESSES'
  | 'BENEFITS_FOR_INFORMATION_TECHNOLOGY_BUSINESSES'
  | 'ZERO'
export type TBudgetType = 'bdr' | 'bdds'
export type TControlRules = Omit<
  RegisterOptions<FieldValues, FieldPath<FieldValues>>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>
export type TProductionProgramViews = 'ESTIMATE' | 'PLANNING' | 'ANALITICS'
export type TEstimateType = 'WORKLOAD' | 'LABORIOUS'
export type TOperationCategory = TOperationalActivityCategory | TFinanceOperationsCategory
export type TOperationalActivityCategory =
  | 'WAGE_FUND'
  | 'STAFF_MAINTENANCE'
  | 'OPERATING_EXPENSES'
  | 'INCOME'
  | 'TOTAL'
  | 'ESN'
  | 'OTHER_INCOME'
export type TFinanceOperationsCategory = 'PAYMENTS' | 'INCOME' | 'TOTAL'
export type TEstimateContractType = 'genContract' | 'subContract'
export type TCpTax = 20 | 10 | 0
export type TTaxSystem = 'ОСНО' | 'УСН' | 'УСН (Доходы)' | 'УСН (Доходы минус расходы)'
export type TUSNOptions = '6% на Доходы' | '15% на Доходы - Расходы'
export type TFactType = 'TRANSACTIONS' | 'EXTRACTS'
export type UploadType = 'start' | 'loading' | 'success' | 'error' | 'canceled' | 'canceling'

export interface ISnackBarMessage {
  variant: 'success' | 'error'
  title?: string
  message: string
}

export interface IPeriod {
  type: TReportType
  rangeFrom: TRangeValue
  rangeTo: TRangeValue
}
export interface IPeriodRange {
  rangeFrom: TRangeValue
  rangeTo: TRangeValue
}

export interface IGostBill {
  countSubBills: number
  id: number
  name: string
  number: number
}

export interface ISubBill {
  id: number
  name: string
}

export interface IBillInfo {
  gostBill: IGostBill
  subBill: ISubBill
}

export interface IProjectInfo {
  billType?: string
  address: string
  dateEnd: string
  dateStart: string
  expensesPlan: number
  id: number
  incomePlan: number
  name: string
  netProfit: number
  rentability: number
  status: TProjectStatus
  bddsInfo: ISavedBDRReportInfo
  bdrInfo: ISavedBDRReportInfo
  type: ProjectType
}
export interface IProjectInfoV2 {
  billType?: string
  address: string
  dateEnd: string
  dateStart: string
  id: number
  name: string
  status: TProjectStatus
  cleanCashFlow: number
  income: number
  margin: number
  operationalFlow: number
  profit: number
  type: ProjectType
}

export interface IOperations {
  amount: number
  closingDocument: string
  financeCenterName: string
  gostBill: IGostBill
  subBill: ISubBill
  id: number
  operationDate: {}
  paymentPurpose: string
  project: IProjectInfo
}
export interface IOperationsList {
  data: IOperations[]
  total: number
}

export interface IFinanceCenter {
  budgetYears: number[]
  billType?: string
  createdAt: string
  expensesPlan: number
  genContractSubBill?: IBillInfo
  id: number
  incomePlan: number
  name: string
  project?: IProjectInfo
  rmId: number | null
  rmName: string | null
  subContractSubBill?: IBillInfo
  supervisor?: IUser
  syncDate: string
  type: TFinanceCenterType
  expenseSubBill: IBillInfo
  incomeSubBill: IBillInfo
  finExpenseSubBill: IBillInfo
  finIncomeSubBill: IBillInfo
  fillingMode: TFormatSelectionValue
}

export interface IDownloadsItem {
  downloadBy: {
    id: number
    avatar: string
    avatarPreview: string
    company: UserCompanyInfo
    email: string
    firstName: string | null
    lastName: string | null
    middleName: string | null
    phone: string
    position: string
    role: UserRolesEn
  }
  downloadTo: string
  id: number
  income: number
  outcome: number
  period: string
  totalRecords: number
}
export interface IDownloadsList {
  data: IDownloadsItem[]
  total: number
}
export interface IBDRArticle {
  id: number | null
  name: string
  type: TBDRArticleType
}

export interface IBDDSArticle extends IBDRArticle {
  flow?: TBDDSArticleFlow
  editable?: boolean
}

export interface IOperationUnitScale {
  month?: TMonthName
  quarter?: TQuarterName
  reportType?: TReportType
  year?: number
}

export interface IOperationUnit {
  nominal?: number
  reportType?: string
  scale: IOperationUnitScale | undefined
  sum: number
  type: 'MONTH' | 'QUARTER' | 'YEAR' | 'TOTAL' | 'UNKNOWN'
  plannedAmount: number
  actualAmount: number
  discrepancy: number
  growth: number
}

export interface IProductionProgrammOperationUnit extends IOperationUnit {
  genContractValue: number
  subContractValue: number
  income: number
  percent: number
  rentability: number
}

export interface IOrganization {
  fullName: string
  id: number
  inn: string
}

export interface IUser {
  email?: string
  firstName?: string
  id: number
  lastName?: string
  middleName?: string
  organisation?: IOrganization
  phone: string
  position?: string
  role?: string
}

export interface IESNParams {
  percents: {
    PFR: {
      full: number
      benefit: number | undefined
    }
    VNIM: {
      full: number
      benefit: number | undefined
    }
    OMS: {
      full: number
      benefit: number | undefined
    }
    FSS: {
      full: number
      benefit: number | undefined
    }
  }
  limits: {
    PFR: number | undefined
    VNIM: number | undefined
    OMS: number | undefined
    FSS: number | undefined
  }
}

export interface IFinCenterTotalReportRecord {
  category: TOperationCategory
  operationType: TOperationType
  subBill: ISubBill
  type: 'DEFAULT'
  units: IOperationUnit[]
  activityType: 'BDR' | 'BDDS'
}

export interface IFullReportRecord {
  article: IBDRArticle
  articleType: string
  subBill: ISubBill
  type: 'DEFAULT'
  units: IOperationUnit[]
  activityType: 'BDR' | 'BDDS'
}

export interface IReportRecord {
  children: IReportRecord[]
  isRoot: boolean
  value: IFinCenterTotalReportRecord & IFullReportRecord
}

export type TabsEntities = UserRoleTabLabels
export interface TabsProps<T extends TabsEntities> {
  currentTab: T
  onTabChange: (e: SyntheticEvent, tabValue: T) => void
  tabsData: TabData<T>[]
}

export interface TabData<T> {
  value: T
  label: string
}

export interface ISavedBDRReportInfo {
  createdAt: string
  createdBy: {
    email: string
    firstName: string
    id: number
    lastName: string
    middleName: string
    organisation: {
      fullName: string
      id: number
      inn: string
    }
    phone: string
    position: string
    role: 'owner' | 'staff'
  }
  reportID: number | string
  totalSummary: {
    financeCenters: IFinanceCenter[] | null
    saldo: {
      financial: number
      invest: number
      operations: number
      cleanCashFlow: number
    }
    incomes: number
    marja: number
    net_profit: number
    otherIncomesOutcomes: number
    outcomes: number
    profit_before_tax: number
    rentability: number
    payment: number
    factExpenses: number | null
  }
  synchronizable: boolean
  isActualState: boolean
}

export interface IFinCenterOperation {
  new?: boolean
  category?: TOperationCategory
  type: 'DEFAULT' | 'TOTAL'
  esnTotal?: {
    units: IBudgetESNUnit[]
  }
  id?: number | null
  name?: string
  operationType?: TOperationType
  relationships?: IReportRelationshipItem
  unitPriceWithoutVAT?: number
  units: IOperationUnit[]
}

export interface IBudgetESNUnit extends IOperationUnit {
  detailed?: number
  month: TMonthName
  total: number
  sum: number
}

export interface IPrPrEstimateOperation {
  id: number | null
  unitType: string | null
  name: string | null
  genContractUnitPrice: number | null
  genContractPricePerHour: number | null
  genContractTotal: number | null
  subContractUnitPrice: number | null
  subContractPricePerHour: number | null
  subContractContractor: string | null
  subContractTotal: number | null
  humanHoursQuantity: number | null
  machineryHoursQuantity: number | null
  income: number
  incomePerHour: number
  quantity: number | null
  rentability: number
  relationships: {
    genContract: IReportRelationshipItem | null
    subContract: IReportRelationshipItem | null
  }
}

export interface IPrPrEstimateOperationWithLvl {
  lvl: number
  parentIds: number[]
  hasChild: boolean
  id: number | null
  uId: string
  name: string | null
  relationships: {
    genContract: IReportRelationshipItem | null
    subContract: IReportRelationshipItem | null
  }

  unitType?: string | null
  genContractUnitPrice?: number | null
  genContractPricePerHour?: number | null
  genContractTotal?: number | null
  subContractUnitPrice?: number | null
  subContractPricePerHour?: number | null
  subContractContractor?: string | null
  subContractTotal?: number | null
  humanHoursQuantity?: number | null
  machineryHoursQuantity?: number | null
  income?: number
  incomePerHour?: number
  quantity?: number | null
  rentability?: number
}

export interface IPrPrEstimateTotal {
  genContractPricePerHour: number | null
  genContractTotal: number | null
  humanHoursQuantity: number | null
  income: number
  incomePerHour: number
  machineryHoursQuantity: number | null
  rentability: number
  subContractPricePerHour: number | null
  subContractTotal: number | null
}

export interface IReportRelationshipItem {
  label?: string
  articles: IBDRArticle[] & IBDDSArticle[]
  deep: number
  gostBill: IGostBill
  subBill: ISubBill
  type: TBDRArticleType
}

export interface IRelationshipRecord {
  name: string
  relationships: IReportRelationshipItem
}

export interface IWorkloadOperationValues {
  changed?: boolean
  id?: number
  key?: number
  new?: boolean
  isMarkedToDelete: boolean
  name: string
  quantity: number
  unitType: string
  genContractUnitPrice: number
  genContractTotal: number
  subContractUnitPrice: number
  subContractTotal: number
  subContractContractor: string
  income: number
  rentability: number
  relationships: {
    genContract: IReportRelationshipItem
    subContsct: IReportRelationshipItem
  }
}

export interface IEstimatePlanningItem {
  genContractValue: number
  income: number
  percent: number
  rentability: number
  subContractValue: number
}

export interface IPrPrOperationUnit extends IOperationUnit, IEstimatePlanningItem {}

export interface IPrPrPlanningAndAnaliticsOperation {
  id: number | null
  name: string
  activityType: TBudgetType
  estimate: IPrPrEstimateOperation
  leftToRealizedTotal: IEstimatePlanningItem
  realizedTotal: IEstimatePlanningItem
  type: 'DEFAULT'
  units: IPrPrOperationUnit[]
}

export interface IPaymentTermTemplate {
  title: string
  data: IPaymentTermTemplateCondition[]
}

export interface IPaymentTermTemplateCondition {
  title: string
  offset: number // Период оплаты:
  percent: number // Процент от суммы:
  position: number
}

export type ExcelParseErrorType =
  | 'unknown'
  | 'sheet_not_found'
  | 'many_sheets'
  | 'missed_columns'
  | 'cell_not_found'
  | 'empty_cell'
  | 'invalid_cell_value'
  | 'unacceptable_cell_value'

export const errorTextByType: Record<ExcelParseErrorType, string> = {
  unknown: 'Неизвестная ошибка',
  sheet_not_found: 'Книга не содержит данных',
  many_sheets: 'Книга содержит несколько листов',
  missed_columns: 'Отсутствуют обязательные данные',
  cell_not_found: 'Не найдена колонка',
  empty_cell: 'Не найдено значение',
  invalid_cell_value: 'Неприемлемые данные',
  unacceptable_cell_value: 'Неуникальные данные',
}

export interface ExcelParseError {
  cell: string
  col: number
  row: number
  message: string
  type: ExcelParseErrorType
}

export interface ExcelUploadResponse {
  error: ExcelParseError | null
  errorList: ExcelParseError[] | null
  success: boolean
}

export type TMonthByQuarter = Record<TQuarterName, TMonthName>

export const monthByQuarterStart: TMonthByQuarter = {
  FIRST: 'JANUARY',
  SECOND: 'APRIL',
  THIRD: 'JULY',
  FOURTH: 'OCTOBER',
  ALL: 'JANUARY',
}

export const monthByQuarterEnd: TMonthByQuarter = {
  FIRST: 'MARCH',
  SECOND: 'JUNE',
  THIRD: 'SEPTEMBER',
  FOURTH: 'DECEMBER',
  ALL: 'DECEMBER',
}

export const periodByReportType: Record<TReportType, string> = {
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  YEARLY: 'year',
}

export type MUIColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'

export const validExcelFormats = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

export type FetchQueryError = NonNullable<FetchBaseQueryError | SerializedError | undefined>

export const isFetchError = (error: FetchQueryError): error is FetchBaseQueryError => {
  if (!error) return false

  return 'data' in error && 'status' in error
}
