import { Stack, Typography } from '@mui/material'
import {
  BodyScrollEndEvent,
  ColDef,
  Column,
  FirstDataRenderedEvent,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { OperationForCreateOrUpdate } from 'global/api/constructors'
import { IFinCenterBudgetQuery } from 'global/api/definitions'
import { getMonthRange, getQueryParam } from 'global/functions'
import { theme } from 'global/styles/theme'
import { IBudgetESNUnit, IFinCenterOperation, TMonthName } from 'global/types/commos-def'
import { getTotals } from 'pages/BudgetPageNew/components/FinanceCenterRecords/components/utils'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BudgetCellWrapper, BudgetTotalCellStyle, StyledAgGrid, TABLE_CELL_HEIGHT } from './BudgetAgGrid.styles'
import { IAgGridTestProps } from './BudgetAgGrid.types'
import { suppressKeyboardEvent } from './BudgetAgGrid.utils'
import { UnitPriceCell } from 'pages/BudgetPageNew/components/BudgetAgGrid/cells/UnitPriceCell'
import { EditableCellNumber } from 'pages/BudgetPageNew/components/BudgetAgGrid/cells/EditableCellNumber'
import { EditableCell } from 'pages/BudgetPageNew/components/BudgetAgGrid/cells/EditableCell'
import { MONTHS } from 'global/variables'

const excludeColumns = ['menu', 'number', 'name', 'unitPriceWithoutVAT', 'group']

export const BudgetAgGrid: FC<IAgGridTestProps> = ({ operations, total, fillingMode, category }) => {
  const history = useHistory()
  const gridRef = React.useRef<AgGridReact<any>>(null)
  const rowAutoAdding = getQueryParam(history, 'isCreateNextRow') as string
  const [isTableShown, setIsTableShown] = useState<boolean>(false)

  const { t: translate } = useTranslation('ENUMS')

  useEffect(() => {
    if (operations?.length) setIsTableShown(true)
  }, [operations])

  const getRangeColumns = () => {
    let rangeList: any[] = getMonthRange('JANUARY' as TMonthName, 'DECEMBER' as TMonthName, MONTHS).map(
      (month: TMonthName) => translate('MONTHS.' + month),
    )

    rangeList = rangeList.map((item, index) => {
      const fieldName: string = `month-${item}-pos-${index}`

      return {
        headerName: item,
        field: fieldName,
        minWidth: 90,
        initialWidth: 90,
        resizable: true,
        cellDataType: false,
        flex: 1,
        suppressKeyboardEvent,
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node, api } = params

          if (data?.type === 'TOTAL') {
            const localPinnedBottomRowData = api.getPinnedBottomRow(0)?.data

            return (
              <Stack>
                <Stack sx={{ borderRight: '1px solid #d1d8fa' }}>
                  <EditableCellNumber
                    value={data?.units?.[index]?.sum}
                    viewCellProps={{ style: BudgetTotalCellStyle }}
                  />
                </Stack>

                {category === 'WAGE_FUND' && (
                  <Stack sx={{ borderRight: '1px solid #d1d8fa', borderTop: '1px solid #d1d8fa' }}>
                    <EditableCellNumber
                      value={localPinnedBottomRowData?.esnTotal?.units?.[index]?.sum || 0}
                      viewCellProps={{ style: BudgetTotalCellStyle }}
                    />
                  </Stack>
                )}
              </Stack>
            )
          }

          return (
            <Stack>
              <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units?.[index]?.nominal}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                />
              </BudgetCellWrapper>
              <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units?.[index]?.sum}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                />
              </BudgetCellWrapper>
              {category === 'WAGE_FUND' && (
                <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                  <EditableCellNumber
                    value={data?.esnTotal?.units[index].sum || 0}
                    viewCellProps={{
                      style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                    }}
                  />
                </BudgetCellWrapper>
              )}
            </Stack>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      }
    })

    return rangeList
  }

  const totalGroupByCategory = useMemo((): string[] => {
    switch (category) {
      case 'WAGE_FUND':
        return ['Зарплата', 'ЕСН']

      default:
        return ['Сумма']
    }
  }, [category])

  const getRowStyle = ({ data, node }: ICellRendererParams<IFinCenterOperation>) => {
    if (data?.type === 'DEFAULT') {
      return {
        backgroundColor: theme.palette.bg.white,
        border: 'none',
        padding: 0,
      }
    } else {
      return {
        backgroundColor: '#1976d214',
        height: `${TABLE_CELL_HEIGHT * totalGroupByCategory.length}px`,
        border: 'none',
        padding: 0,
      }
    }
  }

  const getHeaderForUnitPrice = useMemo(
    () => (category === 'WAGE_FUND' ? 'Сумма, без ЕСН' : `Цена за ед. (без НДС)`),
    [category],
  )

  const columnDefs = useMemo((): ColDef[] => {
    return [
      {
        headerName: '№',
        field: 'number',
        minWidth: 50,
        initialWidth: 50,
        resizable: true,
        flex: 1,
        pinned: 'left',
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node } = params

          if (data?.type === 'TOTAL') return <></>

          return (
            <BudgetCellWrapper justifyContent={'center'}>
              <Typography variant="body2" textAlign={'center'}>
                {(node.rowIndex || 0) + 1}
              </Typography>
            </BudgetCellWrapper>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      {
        headerName: 'Наименование',
        field: 'name',
        minWidth: 240,
        initialWidth: 240,
        resizable: true,
        flex: 1,
        pinned: 'left',
        suppressKeyboardEvent,
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data } = params

          return <EditableCell value={data?.type === 'TOTAL' ? 'Всего:' : data?.name} maxLines={2} />
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      {
        headerName: getHeaderForUnitPrice,
        field: 'unitPriceWithoutVAT',
        minWidth: 164,
        initialWidth: 164,
        resizable: true,
        cellDataType: false,
        flex: 1,
        pinned: 'left',
        suppressKeyboardEvent,
        cellRenderer: (params: ICellRendererParams<IFinCenterOperation>) => {
          const { data, node } = params
          if (data?.type === 'TOTAL') return <BudgetCellWrapper />

          return <UnitPriceCell params={params} fillingMode={fillingMode} forceFocus={false} />
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      {
        headerName: '',
        field: 'group',
        minWidth: 90,
        initialWidth: 90,
        resizable: true,
        flex: 1,
        pinned: 'left',
        cellRenderer: ({ data }: ICellRendererParams<IFinCenterOperation>) => {
          if (data?.type === 'TOTAL') {
            return (
              <Stack>
                {totalGroupByCategory.map((item: string, i: number) => (
                  <Stack
                    justifyContent={'center'}
                    sx={{
                      borderRight: '1px solid #d1d8fa',
                      borderTop: i > 0 ? '1px solid #d1d8fa' : 'none',
                      borderLeft: 'none',
                      borderBottom: 'none',
                    }}>
                    <Typography variant="subtitle2" textAlign={'center'} color={'#5C6E8C'}>
                      {item}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )
          }

          return (
            <Stack>
              {bodyGroupByCategory.map((item: string) => (
                <BudgetCellWrapper padding={'4px 8px'}>
                  <Typography variant="body2" textAlign={'center'}>
                    {item}
                  </Typography>
                </BudgetCellWrapper>
              ))}
            </Stack>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
      ...getRangeColumns(),
      {
        headerName: 'Итого',
        field: 'result',
        minWidth: 90,
        initialWidth: 90,
        resizable: true,
        flex: 1,
        suppressKeyboardEvent,
        cellRenderer: ({ data, api }: ICellRendererParams<IFinCenterOperation>) => {
          if (data?.type === 'TOTAL') {
            const localPinnedBottomRowData = api.getPinnedBottomRow(0)?.data

            return (
              <Stack>
                <Stack sx={{ borderRight: '1px solid #d1d8fa' }}>
                  <EditableCellNumber
                    value={data.units.find((el) => el.type === 'TOTAL')?.sum || 0}
                    viewCellProps={{ style: BudgetTotalCellStyle }}
                  />
                </Stack>

                {category === 'WAGE_FUND' && (
                  <Stack sx={{ borderRight: '1px solid #d1d8fa', borderTop: '1px solid #d1d8fa' }}>
                    <EditableCellNumber
                      value={
                        localPinnedBottomRowData?.esnTotal?.units.find((el: IBudgetESNUnit) => el.type === 'TOTAL')
                          ?.sum || 0
                      }
                      viewCellProps={{ style: BudgetTotalCellStyle }}
                    />
                  </Stack>
                )}
              </Stack>
            )
          }

          return (
            <Stack>
              <BudgetCellWrapper height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units.slice(-1)[0].nominal}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                />
              </BudgetCellWrapper>
              <BudgetCellWrapper justifyContent={'center'} height={`${TABLE_CELL_HEIGHT}px !important`}>
                <EditableCellNumber
                  value={data?.units.slice(-1)[0].sum}
                  viewCellProps={{
                    style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                  }}
                />
              </BudgetCellWrapper>
              {category === 'WAGE_FUND' && (
                <BudgetCellWrapper justifyContent={'center'} height={`${TABLE_CELL_HEIGHT}px !important`}>
                  <EditableCellNumber
                    value={getTotals(data!, 'ESN') || 0}
                    viewCellProps={{
                      style: { textAlign: 'center', height: 'fit-content', fontSize: '14px' },
                    }}
                  />
                </BudgetCellWrapper>
              )}
            </Stack>
          )
        },
        cellStyle: (params: ICellRendererParams<IFinCenterOperation>) => getRowStyle(params),
      },
    ]
  }, [fillingMode, rowAutoAdding])

  const bodyGroupByCategory = useMemo((): string[] => {
    switch (category) {
      case 'WAGE_FUND':
        return ['Кол-во', 'Зарплата', 'ЕСН']

      default:
        return ['Кол-во', 'Сумма']
    }
  }, [category])

  const gridOptions: GridOptions = {
    headerHeight: 50,
    rowHeight: (bodyGroupByCategory.length || 1) * TABLE_CELL_HEIGHT,
    enableCellTextSelection: true,
  }

  const updateColumns = useCallback(
    (params: BodyScrollEndEvent<any, any> | FirstDataRenderedEvent<any, any>) => {
      let visibleWidth = 0
      let allWidth = 0
      const colIDs =
        params?.columnApi
          ?.getColumns()
          ?.map((col) => {
            // if (!grid && col.gridOptionService) {
            // }
            return col.getColId()
          })
          ?.filter((_) => !excludeColumns.includes(_)) || []

      const leftColumns = params.columnApi.getAllDisplayedVirtualColumns() || []

      leftColumns.forEach((col) => {
        visibleWidth += col.getActualWidth()
      })
      ;(params.columnApi.getColumns() || []).forEach((col) => {
        allWidth += col.getActualWidth()
      })
      if (allWidth > visibleWidth) {
        params?.columnApi?.autoSizeColumns(colIDs)
        onColumnResized()
      } else {
        let isColsOverflow = false
        const equalColumns =
          gridRef.current?.columnApi?.getColumns()?.filter((col) => !excludeColumns.includes(col.getColId())) || []
        // устанавливаем автоширину колонок, если хотя бы одна не вмещает значение
        for (const col of equalColumns) {
          const cells = document.querySelectorAll(`.ag-cell[col-id="${col.getColId()}"] .table-input`)
          for (let i = 0; i < cells.length; i++) {
            const cell = cells[i]
            if (cell.scrollWidth > cell.clientWidth) {
              isColsOverflow = true
              break
            }
          }
          if (isColsOverflow) break
        }
        if (isColsOverflow) {
          params?.columnApi?.autoSizeColumns(colIDs)
          onColumnResized()
        }
      }
    },
    [gridRef.current],
  )

  const onColumnResized = useCallback(() => {
    let maxColWidth = 0
    const equalColumns =
      gridRef.current?.columnApi?.getColumns()?.filter((col) => !excludeColumns.includes(col.getColId())) || []
    const equalColumnsIds = equalColumns.map((col) => col.getColId())
    equalColumns.forEach((col) => {
      if (col.getActualWidth() > maxColWidth) {
        maxColWidth = col.getActualWidth()
      }
    })
    gridRef.current?.columnApi?.setColumnWidths(equalColumnsIds.map((colId) => ({ key: colId, newWidth: maxColWidth })))
  }, [gridRef.current])

  const updatedColumns = useRef<Column<any>[]>([])

  return (
    <Stack height={'100%'}>
      {/* @ts-ignore */}
      <Stack display={!isTableShown ? 'none' : 'auto'}>
        <StyledAgGrid
          gridId={'StyledAgGrid'}
          ref={gridRef}
          columnDefs={columnDefs}
          onGridReady={(params) => {
            console.log(params)
            updatedColumns.current = params.columnApi.getColumns() || []
          }}
          rowData={operations}
          gridOptions={gridOptions}
          onFirstDataRendered={updateColumns}
          // pinnedBottomRowData={[total || getInitialTotal()]}
          isBudgetTable={true}
          footerHeight={totalGroupByCategory.length * TABLE_CELL_HEIGHT}
          suppressCellFocus={true}
          noRowsOverlayComponent={() => <></>}
        />
      </Stack>

      {/* @ts-ignore */}
      {!isTableShown && <></>}
    </Stack>
  )
}
