import { Download, List as ListIcon, Save as SaveIcon } from '@mui/icons-material'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import {
  BodyScrollEndEvent,
  CellClassParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community'
import { ColDef, ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { AgGridReact } from 'ag-grid-react'
import qs from 'qs'
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { PercentBadge } from '../../components/PercentBadge/PercentBadge'
import { formatter } from '../../components/Report/Report'
import { EENumber } from '../../components/editableElements/EENumber'
import { InterfaceContext } from '../../contexts/context.interface'
import { FinanceCenterAPI } from '../../global/api/APIMethods/FinCenterAPI'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { ReportAPI } from '../../global/api/APIMethods/ReportAPI'
import {
  ICompareReportResponse,
  IReportResponse,
  IReportRow,
  IReportStat,
  IReportUnitNew,
} from '../../global/api/definitions'
import { changeQueryParams, createFullReport, getCompareReportValueName, getQueryParam } from '../../global/functions'
import { tableCellInputSmall_ViewMode } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import {
  IFinanceCenter,
  IOperationUnit,
  IProjectInfo,
  TAnalisisTypeLower,
  TBudgetType,
} from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { clearBdrParameters, setBdrYears } from '../../store/slices/bdrParameters'
import { bdrParametersSelector, bdrReportRequestBodySelector } from '../../store/slices/bdrParameters/selectors'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { SelectFinCenter } from '../ReportListPage/components/SelectFinCenter'
import { StyledAgGrid } from './ReportPage.style'
import { BdrParametersDrawer } from './components/BdrParametersDrawer'
import { SubBillModal } from './components/SubBillModal/SubBillModal'
import {
  generateUniqueId,
  getExcelReport,
  getYearsArrFromProject,
  initiateBreadcrumbs,
  saveReport,
} from './ReportPage.functions'
import { IBudgetsModalState } from './components/SubBillModal/SubBillModal.types'

export interface IBdrReportPageState {
  saveReportDetails: {
    date?: Date
    status: 'readyToSave' | 'pending' | 'saved'
  }
  allProjectFinCenters: IFinanceCenter[] | null
  selectedFinCentersIDs: string[]
  isSelectFinCentersDrawerOpen: boolean
  project: null | IProjectInfo
  data: null | ICompareReportResponse
  globalLvl: number
  availableYears: null | number[]
}

type TBdrDrawerType = 'bdrParameters'
export interface IHeaderGenerate {
  title: string | number | undefined
  children: IHeaderGenerate[]
}
export interface IFlatReportData {
  title: string
  units: IOperationUnit[]
  deep: number
  type: 'folder' | 'row'
  id: string
  parentID: string | null
  children: IFlatReportData[]
}
export interface IReportDataNew {
  title: string
  unitsByReportId: Record<string, IReportUnitNew[]>
  deep: number
  isFolder: boolean
  uuid: string
  parentUUID: string | null
}

export function BdrReportPage() {
  const { projectID } = useParams() as Params

  const gridRef = React.useRef<AgGridReact<IFlatReportData>>(null)

  const [isFetching, setIsFetching] = useState(false)

  const [openedDrawer, setOpenedDrawer] = useState<TBdrDrawerType | null>(null)
  const bdrParameters = useTypedSelector(bdrParametersSelector)
  const bdrParametersRequestBody = useTypedSelector(bdrReportRequestBodySelector)

  const [budgetsModal, setBudgetsModal] = useState<IBudgetsModalState>({
    opened: false,
    subBill: null,
  })

  const dispatch = useAppDispatch()
  const history = useHistory()

  const isCapital = useMemo(() => {
    return history.location.pathname.includes('capital')
  }, [history.location.pathname])

  const budgetType = getQueryParam(history, 'budgetType')

  const typeUrl = qs.parse(history.location.search.slice(1)).type as TAnalisisTypeLower //TAnalisisType;
  //const typeUrl = localStorage.getItem('typeReportPage') as 'plan' | 'fact';

  const interfaceCTX = useContext(InterfaceContext)
  const { t } = useTranslation('ENUMS')

  const [analisisType, setAnalisisType] = React.useState<TAnalisisTypeLower>(typeUrl)

  useEffect(() => {
    setAnalisisType(typeUrl)
  }, [typeUrl])

  const [state, setState] = React.useState({
    saveReportDetails: {
      status: 'readyToSave',
    },
    selectedFinCentersIDs: qs.parse(history.location.search).financeCenters,
    isSelectFinCentersDrawerOpen: false,
    allProjectFinCenters: null,
    globalLvl: 1,
    project: null,
    data: null,
    availableYears: null,
  } as IBdrReportPageState)

  const historyYearStart = Number(getQueryParam(history, 'yearStart'))
  const historyYearEnd = Number(getQueryParam(history, 'yearEnd'))
  const historyReportType = getQueryParam(history, 'reportType')
  const projectYearStart = Number(state.project?.dateStart.split('.')[2])
  const projectYearEnd = Number(state.project?.dateEnd.split('.')[2])

  useEffect(() => {
    if (!state.project) return
    // let projectYearEnd = Number(state.project?.dateEnd.split('.')[2])

    if (historyYearStart !== projectYearStart || historyYearEnd !== projectYearEnd || historyReportType !== 'YEARLY') {
      changeQueryParams(history, {
        yearStart: projectYearStart,
        yearEnd: projectYearEnd,
        reportType: 'YEARLY',
      })
    }
  }, [state.project])

  useEffect(() => {
    if (
      bdrParameters.periods.length === 1 &&
      !bdrParameters.yearStart &&
      !bdrParameters.yearEnd
      // &&
      // bdrParameters.yearEnd !== historyYearEnd
    ) {
      dispatch(setBdrYears({ yearStart: projectYearStart, yearEnd: projectYearEnd }))
    }
  }, [projectYearStart, projectYearEnd])

  useEffect(() => {
    if (!state.project) {
      Promise.all([ProjectsAPI.getProjectById(projectID), FinanceCenterAPI.getBudgets(projectID)]).then((data) => {
        const project = data[0]
        const financeCenters = data[1].data
        setState((prevState) => ({
          ...prevState,
          project: project,
          allProjectFinCenters: financeCenters,
        }))
      })
    }
  }, [state.project])

  useEffect(() => {
    state.project &&
      state.allProjectFinCenters &&
      initiateBreadcrumbs(
        state.project,
        state.allProjectFinCenters,
        qs.parse(history.location.search).financeCenters as string[],
        interfaceCTX,
        isCapital,
        history,
        t,
        openSelectFinCenterDrawer,
      )
  }, [history.location.search, state.project])

  const historyFinanceCenters = String(qs.parse(history.location.search).financeCenters)
  const historySavedReportID = getQueryParam(history, 'savedReportID')

  useEffect(() => {
    const params: any = { ...qs.parse(history.location.search, { ignoreQueryPrefix: true }), type: analisisType }
    if (bdrParameters.yearStart && bdrParameters.yearEnd) {
      setIsFetching(() => true)
      // if (bdrParameters.compared != null && bdrParameters.compared.filter((el) => el.val != null).length > 0) {
      const savedReportIDs = bdrParameters.compared
        ? bdrParameters.compared.filter((el) => el.val != null).map((el) => el.val)
        : []
      if (historySavedReportID) {
        ReportAPI.getBdrCompareReport(
          budgetType as TBudgetType,
          {
            ...params,
            reportType: bdrParameters.periodType,
            yearStart: Number(bdrParameters.yearStart),
            yearEnd: Number(bdrParameters.yearEnd),
            savedReportIDs: [...savedReportIDs, params.savedReportID],
          },
          bdrParametersRequestBody,
        ).then((data) => {
          const parseFinCenters = qs.parse(history.location.search).financeCenters as string | string[]
          const selectedFinCentersIDs: string[] = Array.isArray(parseFinCenters) ? parseFinCenters : [parseFinCenters]
          setState((prevState) => ({
            ...prevState,
            selectedFinCentersIDs,
            data: data,
            availableYears: data?.availableYears,
          }))
          setIsFetching(false)
        })
      } else {
        let selectedFinanceCenters
        if (params.financeCenters) {
          selectedFinanceCenters = params.financeCenters
        } else {
          selectedFinanceCenters = state.allProjectFinCenters?.map((finCenter) => String(finCenter.id))
        }
        ReportAPI.getNewBdrReport(
          {
            ...params,
            reportType: bdrParameters.periodType,
            yearStart: Number(bdrParameters.yearStart),
            yearEnd: Number(bdrParameters.yearEnd),
            financeCenters: selectedFinanceCenters,
          },
          bdrParametersRequestBody,
        ).then((data) => {
          const parseFinCenters = qs.parse(history.location.search).financeCenters as string | string[]
          const selectedFinCentersIDs: string[] = Array.isArray(parseFinCenters) ? parseFinCenters : [parseFinCenters]
          setState((prevState) => ({
            ...prevState,
            selectedFinCentersIDs,
            data: data,
            availableYears: data?.availableYears,
          }))
          setIsFetching(false)
        })
      }
    }
  }, [bdrParameters, historyFinanceCenters, historySavedReportID, budgetType])

  useEffect(() => {
    return () => {
      setState((prevState) => ({
        ...prevState,
        data: null,
      }))
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearBdrParameters())
    }
  }, [])

  function closeSelectFinCenterDrawer() {
    setState((prevState) => ({
      ...prevState,
      isSelectFinCentersDrawerOpen: false,
    }))
  }

  function openSelectFinCenterDrawer() {
    setState((prevState) => ({
      ...prevState,
      isSelectFinCentersDrawerOpen: true,
    }))
  }

  function changeSaveReportDetails(
    newStatus: IBdrReportPageState['saveReportDetails']['status'],
    date?: IBdrReportPageState['saveReportDetails']['date'],
  ) {
    setState((prevState) => ({
      ...prevState,
      saveReportDetails: {
        ...prevState.saveReportDetails,
        status: newStatus,
        date: date,
      },
    }))
  }

  function onDrawerOpen(drawerType: TBdrDrawerType) {
    setOpenedDrawer(drawerType)
  }

  const onDrawerClose = useCallback(() => {
    setOpenedDrawer(null)
  }, [])

  /**
   * локальное состояние для таблицы,
   * в row лежат динамические данные которые раскрываются
   * в pinnedRow данные которые прибиты к низу таблицы
   */
  const [agData, setAgData] = useState<{ row: IReportRow[]; pinnedRow: IReportDataNew[] }>({
    row: [],
    pinnedRow: [],
  })

  /**
   * локальное состояние для скрытия и раскрытия групп
   */
  const [hiddenRowsIds, setHiddenRowsIds] = useState<string[]>([])

  /**
   * эффект следит за получаемыми данными с сервера,
   * строится плоская структура
   */
  useEffect(() => {
    if (isFetching) {
      setAgData(() => ({ row: [], pinnedRow: [] }))
      setFilteredAgData([])
    } else {
      if (state.data && state.data?.stats && 'flattenData' in state.data) {
        const pinnedRow = state.data.stats.map((_) => {
          return {
            title: t('BDR_STATS.' + _.title),
            deep: -1,
            parentUUID: null,
            uuid: generateUniqueId(),
            isFolder: false,
            unitsByReportId: _.units,
            children: [] as IFlatReportData[],
          }
        })
        if (state.data.flattenData.length) {
          setAgData({ row: state.data.flattenData, pinnedRow })
          // gridRef.current?.api?.hideOverlay()
        } else {
          setAgData({ row: [], pinnedRow: [] })
          // gridRef.current?.api?.showNoRowsOverlay()
        }
      }
    }
  }, [state.data?.data, state.data?.stats, isFetching])

  /**
   * здесь генерируются данные для построения заголовка таблицы,
   * выясняется многолетний он или нет,
   * если да, то на верхний уровень добавляется отображение годов,
   * а ниже в зависимости от вида либо месяца либо кварталы
   */
  const mapHeaders = React.useMemo(() => {
    if (!state.data || !(agData && agData.row[0])) return

    function getAvailableData(years: number[], type?: 'YEARLY' | 'QUARTERLY' | 'MONTHLY') {
      if (!type || !(agData && agData.row[0])) return

      const key = type === 'YEARLY' ? 'year' : type === 'QUARTERLY' ? 'quarter' : 'month'
      const translateKey = key === 'year' ? null : key === 'quarter' ? 'QUARTERS.' : 'MONTHS.'
      const reportIds = Object.keys(agData.pinnedRow[0].unitsByReportId)

      console.log(reportIds, agData.pinnedRow[0])

      if (years.length <= 1 || type === 'YEARLY') {
        return agData.pinnedRow[0].unitsByReportId[reportIds[0]].map((item) => {
          return {
            title: item?.scale?.[key as keyof typeof item.scale]
              ? translateKey
                ? t(translateKey + item.scale?.[key])
                : item.scale[key as keyof typeof item.scale]
              : 'Итого',
            children: reportIds,
          }
        })
        //changed the output of numbers in the column
      } else {
        return agData.pinnedRow[0].unitsByReportId[reportIds[0]].map((item) => {
          return {
            title: item?.scale?.[key as keyof typeof item.scale]
              ? translateKey
                ? // ? t(translateKey + item.scale?.[key]) + ',\u00A0' + item.scale?.year
                  t(translateKey + item.scale?.[key]) + (item.scale?.year ? ',\u00A0' + item.scale?.year : '')
                : item.scale[key as keyof typeof item.scale] + (item.scale ? ',\u00A0' + item.scale : '')
              : 'Итого',
            children: reportIds,
          }
        })
      }
    }
    return getAvailableData(state.data!.availableYears, state.data.type)
  }, [state.data, agData])

  /**
   * возвращает массив настроек таблицы, генерируются заголовки и выводятся данные
   *
   * не успел допилить стили и сворачивание и разворачивание строк
   */
  const getHeaderTable: (ColDef<IReportRow, any> | ColGroupDef<IReportRow>)[] = React.useMemo(() => {
    const templateEndArr = (
      index: number,
      settings: { plan: boolean; fact: boolean; deviation: boolean },
      ids: string[],
    ): (ColDef<IReportRow, any> | ColGroupDef<IReportRow>)[] => [
      {
        headerName: 'План',
        headerClass: 'header-cell-center',
        hide: !settings.plan,
        // suppressAutoSize: false,
        // suppressSizeToFit: false,
        minWidth: 125,
        cellStyle: {
          textAlign: 'right',
        },
        // lockPosition: true,
        // suppressMovable:true,
        initialWidth: 125,
        cellClass: (params) => {
          return params.node.rowPinned === 'bottom'
            ? 'row-pinned-bottom'
            : params.data?.isFolder === false
            ? 'mute-row'
            : `row-style-${params.data?.lvl || 1}`
        },
        ...(ids.length > 1
          ? {
              children: ids.map((id) => ({
                headerName: `ID ${id}`,
                headerClass: 'header-cell-center',
                minWidth: 125,
                cellStyle: {
                  textAlign: 'right',
                },
                hide: !settings.plan,
                initialWidth: 125,
                cellClass: (params) => {
                  return params.node.rowPinned === 'bottom'
                    ? 'row-pinned-bottom'
                    : params.data?.isFolder === false
                    ? 'mute-row'
                    : `row-style-${params.data?.lvl || 1}`
                },
                valueGetter: (params) => {
                  if (!params || !params.data || !params.data.unitsByReportId) {
                    return null
                  }
                  if (!params.data.unitsByReportId[id]) {
                    return null
                  }
                  if (index === undefined || index < 0 || index >= params.data.unitsByReportId[id].length) {
                    console.error('index is out of bounds')
                    return null
                  }
                  return params.data.unitsByReportId[id][index].plannedAmount || 0
                },
                cellRenderer: ({ data, value }: ICellRendererParams<IReportRow, any, any>) => {
                  if (value === null) {
                    return ''
                  }
                  return (
                    <EENumber
                      align="right"
                      mode={'view'}
                      name={`plan${id}${index}`}
                      value={value}
                      NumberFormatProps={{
                        format: (value) => formatter.format(Number(value)),
                      }}
                      viewModeStyle={tableCellInputSmall_ViewMode}
                    />
                  )
                },
              })),
            }
          : {
              valueGetter: (params) => {
                return params.data?.unitsByReportId[ids[0]][index]?.plannedAmount || 0
              },
              cellClass: (params) => {
                return params.node.rowPinned === 'bottom'
                  ? 'row-pinned-bottom'
                  : params.data?.isFolder === false
                  ? 'mute-row'
                  : `row-style-${params.data?.lvl || 1}`
              },
              cellRenderer: ({ data, value }: ICellRendererParams<IReportRow, any, any>) => {
                return (
                  <EENumber
                    align="right"
                    mode={'view'}
                    name={`plan${ids[0]}`}
                    value={value}
                    NumberFormatProps={{
                      format: (value) => formatter.format(Number(value)),
                    }}
                    viewModeStyle={tableCellInputSmall_ViewMode}
                  />
                )
              },
            }),
      },
      {
        headerName: 'Факт',
        headerClass: 'header-cell-center',
        hide: !settings.fact,
        minWidth: 125,
        cellStyle: {
          textAlign: 'right',
        },
        cellDataType: 'string',
        initialWidth: 125,
        cellClass: (params) => {
          return params.node.rowPinned === 'bottom'
            ? 'row-pinned-bottom'
            : params.data?.isFolder === false
            ? 'mute-row'
            : `row-style-${params.data?.lvl || 1}`
        },
        ...(ids.length > 1
          ? {
              children: ids.map((id) => ({
                headerName: `ID ${id}`,
                headerClass: 'header-cell-center',
                hide: !settings.fact,
                minWidth: 125,
                cellStyle: {
                  textAlign: 'right',
                },
                initialWidth: 125,
                cellClass: (params) => {
                  return params.node.rowPinned === 'bottom'
                    ? 'row-pinned-bottom'
                    : params.data?.isFolder === false
                    ? 'mute-row'
                    : `row-style-${params.data?.lvl || 1}`
                },
                valueGetter: (params) => {
                  if (!params || !params.data || !params.data.unitsByReportId) {
                    return null
                  }
                  if (!params.data.unitsByReportId[id]) {
                    return null
                  }
                  if (index === undefined || index < 0 || index >= params.data.unitsByReportId[id].length) {
                    console.error('index is out of bounds')
                    return null
                  }
                  return params.data.unitsByReportId[id][index].actualAmount || 0
                },
                cellRenderer: ({ data, value }: ICellRendererParams<IReportRow, any, any>) => {
                  if (value === null) {
                    return ''
                  }
                  return (
                    <EENumber
                      align="right"
                      mode={'view'}
                      name={`fact${id}${index}`}
                      value={value}
                      NumberFormatProps={{
                        format: (value) => formatter.format(Number(value)),
                      }}
                      viewModeStyle={tableCellInputSmall_ViewMode}
                    />
                  )
                },
              })),
            }
          : {
              valueGetter: (params) => {
                return params.data?.unitsByReportId[ids[0]][index]?.actualAmount || 0
              },
              cellClass: (params) => {
                return params.node.rowPinned === 'bottom'
                  ? 'row-pinned-bottom'
                  : params.data?.isFolder === false
                  ? 'mute-row'
                  : `row-style-${params.data?.lvl || 1}`
              },
              cellRenderer: ({ data, value }: ICellRendererParams<IReportRow, any, any>) => {
                return (
                  <EENumber
                    align="right"
                    mode={'view'}
                    name={`fact${ids[0]}`}
                    value={value}
                    NumberFormatProps={{
                      format: (value) => formatter.format(Number(value)),
                    }}
                    viewModeStyle={tableCellInputSmall_ViewMode}
                  />
                )
              },
            }),
      },
      {
        headerName: 'Отклонение',
        headerClass: 'header-cell-center',
        hide: !settings.deviation,
        minWidth: 170,
        initialWidth: 200,
        flex: 2,
        cellClass: (params) => {
          return params.node.rowPinned === 'bottom'
            ? 'row-pinned-bottom'
            : params.data?.isFolder === false
            ? 'mute-row'
            : `row-style-${params.data?.lvl || 1}`
        },
        ...(ids.length > 1
          ? {
              children: ids.map((id) => ({
                headerName: `ID ${id}`,
                headerClass: 'header-cell-center',
                hide: !settings.deviation,
                minWidth: 170,
                flex: 5,
                cellStyle: {
                  textAlign: 'right',
                },
                cellClass: (params) => {
                  return params.node.rowPinned === 'bottom'
                    ? 'row-pinned-bottom'
                    : params.data?.isFolder === false
                    ? 'mute-row'
                    : `row-style-${params.data?.lvl || 1}`
                },
                valueGetter: (params) => {
                  if (!params || !params.data || !params.data.unitsByReportId) {
                    return null
                  }
                  if (!params.data.unitsByReportId[id]) {
                    return null
                  }
                  if (index >= params.data.unitsByReportId[id].length) {
                    console.error('index is out of bounds')
                    return null
                  }
                  return params.data.unitsByReportId[id][index].discrepancy || 0
                },
                cellRenderer: ({ data, value }: ICellRendererParams<IReportRow, any, any>) => {
                  const getGrowth = () => {
                    if (!data || !data.unitsByReportId) {
                      return 0
                    }
                    if (!data.unitsByReportId[id]) {
                      return 0
                    }
                    if (index === undefined || index < 0 || index >= data.unitsByReportId[id].length) {
                      console.error('index is out of bounds')
                      return 0
                    }
                    return data.unitsByReportId[id][index].growth || 0
                  }
                  if (value === null) {
                    return ''
                  }
                  return (
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      spacing={'2px'}
                      sx={{
                        color: (theme) => {
                          if (!value) return

                          return value < 0 ? theme.palette.text.red : theme.palette.text.green
                        },
                      }}
                      justifyContent={'flex-end'}>
                      <EENumber
                        align="right"
                        mode={'view'}
                        name={`discrepancy${id}${index}`}
                        value={value}
                        NumberFormatProps={{
                          format: (value) => formatter.format(Number(value)),
                        }}
                        TextFieldProps={{
                          sx: {},
                        }}
                        viewModeStyle={tableCellInputSmall_ViewMode}
                      />
                      <PercentBadge value={getGrowth()} isShow={bdrParameters.isPercentageShown} />
                    </Stack>
                  )
                },
              })),
            }
          : {
              valueGetter: (params) => {
                return params.data?.unitsByReportId[ids[0]][index]?.discrepancy || 0
              },
              cellClass: (params) => {
                return params.node.rowPinned === 'bottom'
                  ? 'row-pinned-bottom'
                  : params.data?.isFolder === false
                  ? 'mute-row'
                  : `row-style-${params.data?.lvl || 1}`
              },
              cellRenderer: ({ data, value }: ICellRendererParams<IReportRow, any, any>) => {
                return (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={'2px'}
                    sx={{
                      color: (theme) => {
                        if (!value) return

                        return value < 0 ? theme.palette.text.red : theme.palette.text.green
                      },
                    }}
                    justifyContent={'flex-end'}>
                    <EENumber
                      align="right"
                      mode={'view'}
                      name={`discrepancy${ids[0]}`}
                      value={value}
                      NumberFormatProps={{
                        format: (value) => formatter.format(Number(value)),
                      }}
                      TextFieldProps={{
                        sx: {},
                      }}
                      viewModeStyle={tableCellInputSmall_ViewMode}
                    />
                    <PercentBadge
                      value={data?.unitsByReportId[ids[0]][index]?.growth as number}
                      isShow={bdrParameters.isPercentageShown}
                    />
                  </Stack>
                )
              },
            }),
      },
    ]

    const { isResultShown, periods, result } = bdrParameters

    const MapPeriods = new Map()
    periods.forEach((period) => {
      MapPeriods.set(period.columnsHeader, period)
    })

    const arrPeriods = Array.from(MapPeriods.keys())

    console.log(periods)
    console.log(arrPeriods)
    let index = 0
    return (
      mapHeaders?.map((col, i) => {
        const indexPeriod = arrPeriods.findIndex((periods) => periods.includes(col.title))
        let settingsCol = {
          plan: true,
          fact: true,
          deviation: true,
        }
        let isTotal = col.title === 'Итого'
        if (indexPeriod >= 0 && MapPeriods.has(arrPeriods[indexPeriod])) {
          const target = MapPeriods.get(arrPeriods[indexPeriod])
          settingsCol = { ...target }
        } else {
          if (isTotal) {
            isTotal = !isResultShown
            if (!isResultShown) {
              settingsCol.plan = false
              settingsCol.fact = false
              settingsCol.deviation = false
            } else {
              settingsCol = { ...result }
            }
          }
        }
        return {
          headerName: String(col.title),
          hide: isTotal,
          // flex: 1, // suppressSizeToFit: true,
          headerClass: 'header-cell-center',
          // suppressAutoSize: false,
          // suppressSizeToFit: false,
          // children: templateEndArr(index++, settingsCol) ,
          children: templateEndArr(index++, settingsCol, col.children),
        }
      }) || []
    )
  }, [mapHeaders, bdrParameters, state.data, budgetType, state?.data?.type])

  /**
   * состояние для хранения отфильтрованных строк таблицы
   */
  const [filteredAgData, setFilteredAgData] = useState<IReportRow[]>([])

  /**
   * Хранит настройку заголовков таблицы и вывод в ячейки
   * (для кастомного добавления стиля ячеек можно добавить cellRenderer, пример выше)
   */
  const columnsDef = React.useMemo<(ColDef<IReportRow, any> | ColGroupDef<IReportRow>)[]>(() => {
    return [
      {
        headerName: 'Наименование статьи/субсчета',
        minWidth: 300,
        initialWidth: 300,
        flex: 12,
        initialFlex: 12,
        suppressSizeToFit: true,
        // pinned: 'left',
        suppressAutoSize: true,
        // lockPinned: false,
        // suppressMovable: true,
        cellClass: (params) => {
          return params.node.rowPinned === 'bottom'
            ? 'row-pinned-bottom'
            : params.data?.isFolder === false
            ? 'mute-row'
            : `row-style-${params.data?.lvl || 1}`
        },
        cellStyle: (cellClassParams) => {
          return { paddingLeft: (cellClassParams.data?.lvl ?? 1) * 8 }
        },
        valueGetter: (params) => {
          return params?.data ? getCompareReportValueName(params.data, t) || '' : ''
        },
      },
      ...(getHeaderTable || []),
    ]
  }, [state.data, agData, getHeaderTable])

  /**
   * Добавляет стандартные данные для управления заголовком таблицы
   */
  const defaultColDef: ColDef<IFlatReportData, any> = React.useMemo(() => {
    return {
      editable: false,
      resizable: true,
    }
  }, [])

  const gridOptions: GridOptions = {
    enableCellTextSelection: true,
  }

  /**
   * функция для сворачивания и разворачивания строк
   * принимает id строки
   */
  const collapse = (id: string) => {
    setHiddenRowsIds((d) => {
      const copy = [...d]
      const addIndex = copy.findIndex((hrId) => hrId === id)
      if (addIndex >= 0) {
        copy.splice(addIndex, 1)
      } else {
        copy.push(id)
      }
      return copy
    })
  }

  useEffect(() => {
    if (!isFetching) {
      setFilteredAgData(() => {
        if (agData.row) {
          const indexes: string[] = []
          const filledArr = agData.row.reduce((acc: IReportRow[], curr) => {
            const newItem: typeof curr = Object.assign({}, curr)
            let idx = false
            if (newItem && newItem.parentUUID && newItem.uuid) {
              idx = hiddenRowsIds.includes(newItem.parentUUID) || indexes.includes(newItem.uuid)
              if (idx && newItem.childrenUuids.length) {
                indexes.push(...newItem.childrenUuids)
              }
            }
            return !idx ? [...acc, newItem] : acc
          }, [])
          return filledArr?.length ? filledArr : agData.row
        } else {
          return []
        }
      })
    }
  }, [hiddenRowsIds, agData.row, state.data])

  useEffect(() => {
    if (!isFetching) {
      if (agData.row.length) {
        setHiddenRowsIds((d) => {
          const copy = [...d]
          // if (!copy.length) {
          agData.row.filter((item) => item.isFolder).forEach((file) => copy.push(file.uuid))
          // }
          return copy
        })
      }
    }
  }, [agData.row, isFetching])

  useEffect(() => {
    if (isFetching) {
      setTimeout(() => {
        gridRef.current?.api?.showLoadingOverlay()
      })
    } else {
      if (filteredAgData.length) {
        setTimeout(() => {
          gridRef.current?.api?.hideOverlay()
        })
      } else {
        gridRef.current?.api?.showNoRowsOverlay()
      }
    }
  }, [gridRef.current, isFetching, filteredAgData])

  const updateColumns = (params: BodyScrollEndEvent<any, any> | GridReadyEvent<any, any>) => {
    const gridContainer = document.querySelector(`.ag-root-wrapper`)
    let allWidth = 0
    const colIDs =
      params?.columnApi?.getColumns()?.map((col) => {
        // if (!grid && col.gridOptionService) {
        // }
        return col.getColId()
      }) || []

    ;(params.columnApi.getColumns() || []).forEach((col: { getActualWidth: () => number }) => {
      allWidth += col.getActualWidth()
    })

    if (gridContainer && allWidth > gridContainer.clientWidth) {
      params?.columnApi?.autoSizeColumns(colIDs)
    } else {
      let cols = params?.columnApi?.getColumns() || []
      let isColsOverflow = false
      for (const col of cols) {
        const cells = document.querySelectorAll(`.ag-cell[col-id="${col.getColId()}"] .table-input`)
        for (let i = 0; i < cells.length; i++) {
          const cell = cells[i]
          if (cell.scrollWidth > cell.clientWidth) {
            isColsOverflow = true
            break
          }
        }
        if (isColsOverflow) {
          params?.columnApi?.autoSizeColumns([col.getId()])
          isColsOverflow = false
        } else {
          params?.api.sizeColumnsToFit()
        }
      }
    }
  }

  return (
    <Box>
      {state.allProjectFinCenters && state.selectedFinCentersIDs && (
        <>
          <SelectFinCenter
            isCapital={isCapital}
            allFinCenters={state.allProjectFinCenters}
            availableFinCenters={state.allProjectFinCenters?.filter((projectFinCenter) => {
              return !Object.values(state.selectedFinCentersIDs).includes(String(projectFinCenter.id))
            })}
            filteredFinCenters={state.allProjectFinCenters?.filter(
              (projectFinCenter) => !Object.values(state.selectedFinCentersIDs).includes(String(projectFinCenter.id)),
            )}
            selectedFinCenters={state.allProjectFinCenters?.filter((projectFinCenter) =>
              Object.values(state.selectedFinCentersIDs).includes(String(projectFinCenter.id)),
            )}
            budgetType={getQueryParam(history, 'budgetType') as TBudgetType}
            isOpen={state.isSelectFinCentersDrawerOpen}
            onCreateReport={(finCentersIDArr) => {
              if (state.project) {
                createFullReport(
                  state.project,
                  getQueryParam(history, 'budgetType') as TBudgetType,
                  history,
                  undefined,
                  finCentersIDArr,
                  typeUrl,
                )
                closeSelectFinCenterDrawer()
                changeSaveReportDetails('readyToSave', undefined)
              }
            }}
            onClose={closeSelectFinCenterDrawer}
            onCancel={closeSelectFinCenterDrawer}
          />
        </>
      )}
      <>
        {state.project && (
          <ControlPanel.Wrapper
            sx={
              getQueryParam(history, 'savedReportID')
                ? {
                    '@media (max-width: 1120px)': {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      '.InnerContainer': {
                        margin: 0,
                        ':nth-of-type(3)': {
                          marginTop: '-46px',
                          marginLeft: 'auto',
                        },
                      },
                      gap: 1,
                    },
                  }
                : {
                    '@media (max-width: 1350px)': {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      '.InnerContainer': {
                        margin: 0,
                        ':nth-of-type(3)': {
                          marginTop: '-46px',
                          marginLeft: 'auto',
                        },
                      },
                      gap: 1,
                    },
                  }
            }>
            {/* <ControlPanel.InnerContainer>
                            <ControlPanel.PercentageDisplay />
                        </ControlPanel.InnerContainer> */}

            <ControlPanel.InnerContainer>
              <Typography variant="body2" textAlign={'center'} color={theme.palette.primary.main} fontSize={12}>
                Для гибкой настройки отображения откройте параметры.
              </Typography>
            </ControlPanel.InnerContainer>

            <ControlPanel.InnerContainer align="right">
              <Stack spacing={2.25} direction={'row'}>
                {!getQueryParam(history, 'savedReportID') && state.data && state.data.flattenData.length > 0 && (
                  <ControlPanel.Btn
                    variant="contained"
                    startIcon={
                      <>
                        {state.saveReportDetails.status == 'readyToSave' && <SaveIcon />}
                        {state.saveReportDetails.status == 'pending' && (
                          <CircularProgress
                            size="20px"
                            sx={{
                              textAlign: 'left',
                              display: 'block',
                              position: 'relative !important',
                              color: theme.palette.text.light!,
                              left: 0,
                              top: 0,
                            }}
                          />
                        )}
                      </>
                    }
                    onClick={() => {
                      const notEmptySelectedFinCentersIDs = state.selectedFinCentersIDs.filter((el) => el)
                      return (
                        state.saveReportDetails.status == 'readyToSave' &&
                        saveReport(
                          history,
                          projectID,
                          notEmptySelectedFinCentersIDs.length > 0
                            ? state.selectedFinCentersIDs
                            : state.allProjectFinCenters?.map((finCenter) => String(finCenter.id)) || [],
                          changeSaveReportDetails,
                        )
                      )
                    }}>
                    {state.saveReportDetails.status == 'readyToSave' && 'Сохранить'}
                    {state.saveReportDetails.status == 'pending' && 'Сохраняем отчет, подождите'}
                  </ControlPanel.Btn>
                )}

                <ControlPanel.Btn
                  startIcon={<Download />}
                  onClick={() => getExcelReport(history, t, projectID, state.project, state.selectedFinCentersIDs)}>
                  Скачать .xls
                </ControlPanel.Btn>
                <ControlPanel.Btn
                  variant="contained"
                  startIcon={<ListIcon />}
                  onClick={() => onDrawerOpen('bdrParameters')}>
                  Параметры
                </ControlPanel.Btn>
              </Stack>
            </ControlPanel.InnerContainer>

            <BdrParametersDrawer
              availableYears={getYearsArrFromProject(state.project)}
              availableYearsResponse={state.data?.availableYears ?? []}
              isOpen={openedDrawer === 'bdrParameters'}
              onClose={onDrawerClose}
            />
          </ControlPanel.Wrapper>
        )}
        <div style={{ height: 'calc(100vh - 142px)', minHeight: '150px' }}>
          {/*<div style={{ height:"calc(100vh - 142px)"}}>*/}
          <div
            className="ag-theme-alpine"
            // style={{ width: "100%", maxHeight: "calc(100vh - 142px)", minHeight:'150px', height:'auto', paddingLeft: 20, paddingRight: 8 }}>
            style={{ width: '100%', height: '100%', paddingLeft: 20, paddingRight: 8 }}>
            <StyledAgGrid
              columnDefs={columnsDef}
              rowData={filteredAgData}
              defaultColDef={defaultColDef}
              onRowClicked={(params) => {
                if (params.data?.isFolder && params.data?.uuid) {
                  collapse(params.data.uuid)
                } else if (bdrParameters.compared === null && historySavedReportID && !params.rowPinned) {
                  setBudgetsModal({
                    opened: true,
                    subBill: params.data?.subBill || null,
                  })
                }
              }}
              // onGridSizeChanged={(event) => {
              //     event.columnApi.autoSizeAllColumns()
              //     // event.api.sizeColumnsToFit();
              // }}
              //     onViewportChanged={(event) => {
              //         event.columnApi.autoSizeAllColumns()
              //         // event.api.sizeColumnsToFit();
              //     }}
              onGridReady={(event) => {
                event.columnApi.autoSizeAllColumns()
                event.api.sizeColumnsToFit()
              }}
              onBodyScrollEnd={updateColumns}
              onGridColumnsChanged={updateColumns}
              suppressCellFocus={true}
              suppressScrollOnNewData
              pinnedBottomRowData={filteredAgData.length ? agData.pinnedRow : []}
              ref={gridRef}
              loadingOverlayComponent={CircularProgress}
              noRowsOverlayComponent={() => <div>В выбранном году нет строк</div>}
              gridOptions={gridOptions}
            />
          </div>
        </div>
        <SubBillModal
          open={budgetsModal.opened}
          onClose={() => setBudgetsModal({ opened: false, subBill: null })}
          project={state.project}
          budgetType={budgetType}
          reportId={historySavedReportID}
          subBill={budgetsModal.subBill}
        />
      </>
    </Box>
  )
}
