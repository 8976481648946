import qs from 'qs'
import {
  IFinanceCenter,
  IPrPrEstimateOperation,
  IPrPrEstimateOperationWithLvl,
  IProjectInfo,
  IReportRelationshipItem,
  TEstimateType,
} from '../../global/types/commos-def'
import { History } from 'history'
import { ProductionProgramAPI } from '../../global/api/APIMethods/ProductionProgramAPI'
import { EstimateOperation, EstimateTotal } from '../../global/api/constructors'
import { Params } from '../../global/types/params-def'
import { IProductionProgramQueryParams, IProductionProgramState } from './ProductionProgram-def'
import {
  IProductionProgramEstimateGetListQuery,
  IProductionProgramEstimateGroup,
  IProductionProgramEstimateResponse,
  IProductionProgramEstimateResponseV2,
} from '../../global/api/definitions'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BodyScrollEndEvent, FirstDataRenderedEvent, GridApi } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useGetEstimateV2Query } from 'api/production-program/production-program'

export function getAvailableRelationships(
  contractType: 'genContract' | 'subContract',
  relationships: IReportRelationshipItem[],
) {
  return (
    relationships
      ?.map((relationship) => {
        return {
          label: relationship.subBill.name + '',
          ...relationship,
        }
      })
      .filter((relationship) => {
        if (contractType == 'genContract') {
          return (
            relationship.articles[0]?.type == 'INCOME' || relationship.articles[0]?.type == 'OTHER_INCOMES_OUTCOMES'
          )
        } else {
          return relationship.articles[0]?.type != 'INCOME'
        }
      })
      .sort((a, b) => {
        if (a.gostBill.number > b.gostBill.number) {
          return 1
        } else if (a.gostBill.number == b.gostBill.number) {
          return 0
        } else {
          return -1
        }
      }) || []
  )
}

export function isProlongationAvailable(project: IProjectInfo, financeCenter: IFinanceCenter) {
  let projectStartYear = Number(project.dateStart.split('.')[2])
  let projectEndYear = Number(project.dateEnd.split('.')[2])
  let financeCenterLastYear = financeCenter.budgetYears[financeCenter.budgetYears.length - 1]
  if (financeCenterLastYear > projectEndYear) {
    return false
  }
  if (projectStartYear != projectEndYear && financeCenterLastYear != Number(projectEndYear)) {
    return true
  }
  return false
}

export function getPlanningAndAnalitics(
  history: History,
  setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>,
) {
  let targetQuery = qs.parse(history.location.search, { ignoreQueryPrefix: true })
  targetQuery.pageNumber = undefined
  targetQuery.limitRow = undefined
  ProductionProgramAPI.getPlanningAndAnalitics(targetQuery).then((data) => {
    setState((prevState) => ({
      ...prevState,
      operations: data?.operations,
      total: data?.total,
      recalculatedTotal: prevState.operations && prevState.operations.length == 0 ? new EstimateTotal() : data?.total,
    }))
  })
}

export function createOperation(
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  history: History,
  previousOperationID: number | null,
  setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>,
) {
  e.stopPropagation()
  let newOperation = new EstimateOperation()

  if (e.detail == 1) {
    ProductionProgramAPI.createOperation(newOperation, {
      ...(qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      }) as unknown as IProductionProgramEstimateGetListQuery),
      analysisType: 'PLAN',
      beforeEstimateID: previousOperationID ? String(previousOperationID) : null,
    }).then((data) => {
      newOperation.id = data
      setState((prevState) => ({
        ...prevState,
        operations: [
          ...(prevState.operations || []),
          { ...newOperation, lvl: 0, hasChild: false, parentIds: [] },
        ] as IPrPrEstimateOperationWithLvl[],
      }))
    })
  }
}

// export function transformResponse(estimateItems: IProductionProgramEstimateResponseV2['estimateItems']) {
//   let operations: IPrPrEstimateOperationWithLvl[] = []
//   let parentStack: number[] = [] // Стек для хранения текущих parentId

//   estimateItems.forEach((group) => {
//     const currentLevel = group.lvl ?? 0

//     // Убедимся, что стек содержит элементы только для текущего и более высоких уровней
//     while (parentStack.length > currentLevel) {
//       parentStack.pop()
//     }

//     if (group.estimateItem != null) {
//       // Проверяем, существует ли уже группа как отдельный элемент
//       if (group.id && !operations.some((op) => op.id === group.id)) {
//         // Группа с estimateItem, которая еще не добавлена
//         operations.push({
//           id: group.id,
//           name: group.name ?? '',
//           lvl: currentLevel,
//           parentIds: parentStack.slice(), // Копируем текущий стек
//           hasChild: true,
//           relationships: {
//             genContract: null,
//             subContract: null,
//           },
//         })
//       }
//       // Операция внутри группы
//       operations.push({
//         ...group.estimateItem,
//         lvl: group.lvl !== null ? currentLevel + 1 : 0,
//         parentIds: group.id ? parentStack.concat(group.id) : parentStack.slice(), // Добавляем текущий ID группы к стеку, если он не null
//         hasChild: false,
//       })
//     } else {
//       // Обычная группа без estimateItem
//       operations.push({
//         id: group.id,
//         name: group.name ?? '',
//         lvl: currentLevel,
//         parentIds: parentStack.slice(), // Копируем текущий стек
//         hasChild: true,
//         relationships: {
//           genContract: null,
//           subContract: null,
//         },
//       })
//       // Обновляем стек parentId только для групп
//       if (group.id !== null) {
//         // Убедитесь, что id не null перед добавлением в стек
//         parentStack.push(group.id)
//       }
//     }
//   })

//   return operations
// }
