import { Box, SxProps, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'
import { usePrevState } from '../../global/hooks'
import { getFlexJustifyContent } from './utils'

export interface IEENumberProps {
  align?: 'left' | 'center' | 'right'
  name: string
  mode: 'edit' | 'view'
  value?: any
  onNumberChange?: (values: NumberFormatValues) => void
  TextFieldProps?: Partial<TextFieldProps>
  NumberFormatProps?: NumberFormatProps
  viewModeStyle?: SxProps
  allowNegative?: boolean
}

export const NumberFormatPropsSchema = {
  default: {
    decimalSeparator: ',',
    thousandSeparator: ' ',
    decimalScale: 2,
    getInputRef: (el: HTMLElement) => {
      let input = el && el.getElementsByTagName('input')[0]
      input &&
        input.addEventListener('focus', () => {
          input.select()
        })
    },
    isAllowed: (v: NumberFormatValues) => {
      return v.value.length <= 15
    },
  },
  nominal: {
    isAllowed: (v: NumberFormatValues) => {
      return v.value.length <= 15
    },
  },
}

export function EENumber(props: IEENumberProps) {
  const {
    align,
    name,
    mode,
    value,
    onNumberChange: onChange,
    TextFieldProps,
    NumberFormatProps,
    viewModeStyle,
    allowNegative,
  } = props

  let prevValue = usePrevState(value)

  function replaceLeadingZeros(formattedValue: NumberFormatValues['formattedValue']) {
    let targetValue = clearSuffix(formattedValue)
    if (Number(targetValue) > 1) {
      targetValue = targetValue.replace(/^0+/, '')
    }
    return addSuffix(targetValue)
  }

  function clearSuffix(formattedValue: NumberFormatValues['formattedValue']) {
    let suffix = NumberFormatProps?.suffix || ''
    return formattedValue.replace(suffix, '')
  }

  function addSuffix(formattedValue: NumberFormatValues['formattedValue']) {
    let suffix = NumberFormatProps?.suffix || ''
    return formattedValue + suffix
  }

  let NumberFormatRef = React.useRef()

  React.useEffect(() => {
    return () => {
      if (mode == 'edit') {
        //@ts-ignore
        NumberFormatRef?.current?.onBlur(NumberFormatRef?.current)
      }
    }
  }, [mode])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return (
    // @ts-ignore
    <NumberFormat
      ref={NumberFormatRef}
      className="table-input"
      onDoubleClick={(e) => mode === 'edit' && e.stopPropagation()}
      displayType={mode === 'edit' ? 'input' : 'text'}
      allowNegative={allowNegative || false}
      allowLeadingZeros={false}
      key={name}
      customInput={TextField}
      fixedDecimalScale
      value={isNaN(value) ? 0 : parseFloat(Number(value)?.toFixed(2)) || 0}
      onBlur={(e) => console.log(e)}
      onValueChange={(values, sourceInfo) => {
        // Кусок, отвечающий за замену 0 на значение при вводе
        let valuesCopy
        if (value == 0) {
          valuesCopy = { ...values }
          //@ts-ignore
          if (sourceInfo.event?.nativeEvent?.inputType != 'insertFromPaste') {
            let suffix = NumberFormatProps?.suffix || ''

            // Предыдущая стабильная версия
            // valuesCopy.value = values.value.replace("0" + suffix, "")
            // valuesCopy.formattedValue = values.formattedValue.replace("0" + suffix, "")
            // valuesCopy.floatValue = Number(values.formattedValue.replace("0" + suffix, "").trim()) || undefined

            // WEB. Не ставит символ, на экране "Производственная программа", при выделении символов внутри окна. Багфикс
            valuesCopy.value = values.formattedValue.replace('0' + suffix, '')
            valuesCopy.formattedValue = values.formattedValue.replace('0' + suffix, '')
            valuesCopy.floatValue = Number(values.value.replace('0', '').replace(suffix, '').trim()) || 0
          }
        }

        onChange && onChange(valuesCopy || values)
        //  =========================
        // onChange && onChange(values)
      }}
      {...TextFieldProps}
      {...NumberFormatPropsSchema.default}
      {...NumberFormatProps}
      sx={{
        '.MuiFormHelperText-root': {
          margin: 0,
        },
        span: {
          textAlign: align || 'center',
        },
        ...TextFieldProps?.sx,
        input: {
          textAlign: align || 'center',
          //@ts-ignore
          ...TextFieldProps?.sx['input'],
        },
      }}
      style={{
        whiteSpace: 'nowrap',
        ...viewModeStyle,
        justifyContent: (align && getFlexJustifyContent(align)) || 'center',
      }}
    />
  )
}
