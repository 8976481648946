import { AgGridReact } from "ag-grid-react"
import styled from "styled-components"

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { CircularProgress } from "@mui/material"
import { tableCellInput, tableCellInput_ViewMode } from "global/styles/presets"
import { CellRenderer } from "components/TableAGGrid/CellRenderer/CellRenderer"

export const WrapperTableAGGrid = styled.div`
  //width: 100%;
  //height: 100%;
  //position: relative;
  //overflow: hidden;
`

export const StyledAgGrid = styled(AgGridReact)`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  --ag-cell-horizontal-padding: 8px;
  --ag-header-height-border-radius: 8px 8px 0 0;
  --ag-header-foreground-color: white;
  --ag-header-background-color: #7890b2;
  --ag-header-cell-hover-background-color: #7890b2;
  --ag-header-cell-moving-background-color: #7890b2;
  --ag-border-color: transparent;
  --ag-background-color: transparent;
  --ag-borders: solid 0px;
  --ag-odd-row-background-color: white;
  --ag-alpine-active-color: transparent;
  --ag-range-selection-border-color: transparent;
  --ag-row-hover-color: transparent;

  height: min-content !important;
  max-height: calc(100vh - 180px);
  padding: 0 20px;

  .ag-layout-normal {
    .ag-body {
      flex-grow: 0;
    }
  }

  .ag-root.ag-unselectable.ag-layout-normal {
    height: auto;
    min-height: 150px;
  }

  .ag-root-wrapper.ag-ltr.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 180px);
  }

  .ag-root-wrapper.ag-ltr.ag-keyboard-focus.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 180px);
  }

  .ag-root-wrapper-body.ag-focus-managed.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 180px);
  }

  & .ag-theme-alpine .ag-header {
  }

  & .ag-header {
    border-radius: 8px 8px 0 0;
  }
  & .ag-body {
    background-color: white;
  }

  & .ag-header-cell-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.17px;
    user-select: text;
    cursor: text;
  }
  & .icon-center .ag-sort-indicator-icon {
    padding-left: 0;
  }
  & .ag-center-cols-container,
  & .ag-center-cols-viewport {
    min-height: 100% !important;
  }
  & .ag-row:hover {
    background-color: #f6f7fb;
  }
  & .ag-row:has(.cell-error),
  & .ag-row.row-error {
    background-color: rgb(253, 225, 225);

    & .MuiInputBase-input:invalid + fieldset {
      border-color: #f46b6b;
    }
  }
  & .ag-row-pinned {
    background-color: rgb(237, 244, 251) !important;
  }
  & .ag-row-pinned div,
  .ag-row-pinned span {
    font-weight: 500;
    color: rgb(0, 68, 180) !important;
  }

  & .column-center .ag-header-cell-label,
  & .column-center {
    justify-content: center;
    text-align: center;
  }
  & .dark-background {
    background: rgb(92, 110, 140) !important;
  }
  & .ag-cell-wrapper {
    height: 100%;
    justify-content: center;
  }
  & .ag-cell-value {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // & .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
  //   width: fit-content;
  // }
  & .ag-cell,
  & .ag-header-cell {
    border-right: none;
    border-left: none;
  }
  & .ag-header-cell::after,
  & .ag-header-group-cell::after {
    display: none;
  }
  & .ag-cell {
    line-height: 1.1;
  }
  & .ag-cell-inline-editing {
    box-shadow: none;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: transparent;
    border-color: transparent !important;

    padding-left: calc(var(--ag-cell-horizontal-padding) - 1px);
    padding-right: calc(var(--ag-cell-horizontal-padding) - 1px);
    // & input {
    //     text-align: left;
    // }
  }
  & .ag-tooltip-custom {
    margin-top: auto;
    // top: 5px !important;
    // position: absolute;
    // bottom: 0px !important;
    left: 5px !important;
    // height: 100px;
  }
  .table-input {
    max-width: 100%;
  }
`

StyledAgGrid.defaultProps = {
  getRowId: (params: any) => params.data.id,
  className: "ag-theme-alpine",
  tooltipShowDelay: 0,
  defaultColDef: {
    autoHeight: true,
    editable: false,
    sortable: false,
    filter: false,
    resizable: false,
    suppressMovable: true,
    cellRenderer: CellRenderer.String,
    tooltipComponent: CellRenderer.Tooltip,
    cellRendererParams: {
      EEStringProps: {
        align: "left",
        viewModeStyle: { ...tableCellInput_ViewMode },
        TextFieldProps: {
          sx: tableCellInput,
        },
        mode: "view",
        name: "",
        value: "",
      },
      hideTotal: false,
    },
    cellEditorParams: {
      EEStringProps: {
        align: "left",
        viewModeStyle: { ...tableCellInput_ViewMode },
        TextFieldProps: {
          sx: tableCellInput,
        },
        mode: "edit",
        name: "",
        value: "",
      },
      hideTotal: false,
    },
    wrapHeaderText: true,
    autoHeaderHeight: true,
  },
  // domLayout: 'autoHeight',
  // onFirstDataRendered: event => {
  // 	event.api.sizeColumnsToFit();
  // },
  // onGridReady: event => {
  // 	event.api.sizeColumnsToFit();
  // },
  // onGridSizeChanged: event => {
  // 	event.api.sizeColumnsToFit();
  // },
  // onViewportChanged: event => {
  // 	event.api.sizeColumnsToFit();
  // },
}
