import { Download, Save as SaveIcon } from '@mui/icons-material'
import { Box, CircularProgress, Stack, Tab } from '@mui/material'
import { CellClassParams, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { ColDef, ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { AgGridReact } from 'ag-grid-react'
import { BudgetControls } from 'components/BudgetControls/BudgetControls'
import qs from 'qs'
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { Report, formatter } from '../../components/Report/Report'
import { EENumber } from '../../components/editableElements/EENumber'
import { InterfaceContext } from '../../contexts/context.interface'
import { FinanceCenterAPI } from '../../global/api/APIMethods/FinCenterAPI'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { ReportAPI } from '../../global/api/APIMethods/ReportAPI'
import { IReportResponse } from '../../global/api/definitions'
import { changeQueryParams, createFullReport, getQueryParam, getReportValueName } from '../../global/functions'
import { STYLED, tableCellInputSmall_ViewMode } from '../../global/styles/presets'
import { theme } from '../../global/styles/theme'
import {
  IFinanceCenter,
  IOperationUnit,
  IProjectInfo,
  IReportRecord,
  TAnalisisTypeLower,
  TBudgetType,
} from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { StyledTabs } from '../Fact/styles'
import { SelectFinCenter } from '../ReportListPage/components/SelectFinCenter'
import { StyledAgGrid } from './ReportPage.style'
import { getExcelReport, getYearsArrFromProject, initiateBreadcrumbs, saveReport } from './ReportPage.functions'

export interface IBddsReportPageState {
  saveReportDetails: {
    date?: Date
    status: 'readyToSave' | 'pending' | 'saved'
  }
  allProjectFinCenters: IFinanceCenter[] | null
  selectedFinCentersIDs: string[]
  isSelectFinCentersDrawerOpen: boolean
  globalLvl: number
  project: null | IProjectInfo
  data: null | IReportResponse
  availableYears: null | number[]
}

function generateUniqueId() {
  const timestamp = new Date().getTime() // Получаем текущую дату и время в миллисекундах
  const randomNum = Math.floor(Math.random() * 1000) // Генерируем случайное число от 0 до 999
  return `${timestamp}-${randomNum}`
}

export interface IHeaderGenerate {
  title: string | number | undefined
  children: IHeaderGenerate[]
}
export interface IFlatReportData {
  title: string
  units: IOperationUnit[]
  deep: number
  type: 'folder' | 'row'
  id: string
  parentID: string | null
  children: IFlatReportData[]
}

/**
 * функция возвращает пересобранный массив данных, плоскую структуру для таблицы
 */
function flatChildren(
  reportRecord: IReportRecord[],
  t: TFunction<'ENUMS', undefined>,
  isDeep: number,
  parent?: string,
): IFlatReportData[] {
  return reportRecord.reduce((acc: IFlatReportData[], report) => {
    const id = generateUniqueId()

    const temp: IFlatReportData = {
      title: getReportValueName(report, t) || '',
      units: report.value.units,
      deep: isDeep,
      id,
      parentID: parent ?? null,
      type: report.children.length ? 'folder' : 'row',
      children: [],
    }
    if (report.children) {
      const children = [...flatChildren(report.children || ([] as IFlatReportData[]), t, isDeep + 1, id).flat()]
      temp.children.push(...children)
      acc.push(temp)
      acc.push(...children)
    } else {
      acc.push(temp)
    }

    return acc
  }, [] as IFlatReportData[])
}

export function BddsReportPage() {
  const { projectID } = useParams() as Params

  const gridRef = React.useRef<AgGridReact<IFlatReportData>>(null)

  const [isFetching, setIsFetching] = useState(false)

  const history = useHistory()

  const isCapital = useMemo(() => {
    return history.location.pathname.includes('capital')
  }, [history.location.pathname])

  const budgetType = getQueryParam(history, 'budgetType')

  const typeUrl = qs.parse(history.location.search.slice(1)).type as TAnalisisTypeLower //TAnalisisType;
  //const typeUrl = localStorage.getItem('typeReportPage') as 'plan' | 'fact';

  const interfaceCTX = useContext(InterfaceContext)
  const { t } = useTranslation('ENUMS')

  const [analisisType, setAnalisisType] = React.useState<TAnalisisTypeLower>(typeUrl)

  useEffect(() => {
    setAnalisisType(typeUrl)
  }, [typeUrl])

  // Следим только за этими параметрами query и при изменении делаем запрос через useEffect
  const bddsObservedQuery = React.useMemo(() => {
    const result = new URLSearchParams()
    const observedKeys = ['type', 'monthStart', 'monthEnd', 'yearStart', 'yearEnd', 'reportType']

    const query = new URLSearchParams(history.location.search)
    observedKeys.forEach((key) => {
      if (query.has(key)) {
        result.set(key, query.get(key) as string)
      }
    })
    return result
  }, [history.location.search])

  // const handleChangeTable = React.useCallback((value: TAnalisisTypeLower) => {
  //     setAnalisisType(value)
  //     changeQueryParams(history, {
  //         type: value,
  //     })
  // }, [])

  const [state, setState] = React.useState({
    saveReportDetails: {
      status: 'readyToSave',
    },
    selectedFinCentersIDs: qs.parse(history.location.search).financeCenters,
    isSelectFinCentersDrawerOpen: false,
    allProjectFinCenters: null,
    globalLvl: 1,
    project: null,
    data: null,
    availableYears: null,
  } as IBddsReportPageState)

  useEffect(() => {
    if (!state.project) {
      Promise.all([ProjectsAPI.getProjectById(projectID), FinanceCenterAPI.getBudgets(projectID)]).then((data) => {
        const project = data[0]
        const financeCenters = data[1].data
        setState((prevState) => ({
          ...prevState,
          project: project,
          allProjectFinCenters: financeCenters,
        }))
      })
    }
  }, [state.project])

  useEffect(() => {
    state.project &&
      state.allProjectFinCenters &&
      initiateBreadcrumbs(
        state.project,
        state.allProjectFinCenters,
        qs.parse(history.location.search).financeCenters as string[],
        interfaceCTX,
        isCapital,
        history,
        t,
        openSelectFinCenterDrawer,
      )
  }, [history.location.search, state.project])

  const historyFinanceCenters = String(qs.parse(history.location.search).financeCenters)
  const historySavedReportID = getQueryParam(history, 'savedReportID')

  useEffect(() => {
    return () => {
      setState((prevState) => ({
        ...prevState,
        data: null,
      }))
    }
  }, [])

  useEffect(() => {
    const query = {
      ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
      type: bddsObservedQuery.get('type') as TAnalisisTypeLower,
    }
    gridRef.current?.api?.showLoadingOverlay()
    ReportAPI.getReport(budgetType as TBudgetType, { ...query, budgetType: isCapital ? 'INVEST' : 'GEN' })
      .then((data) => {
        const parseFinCenters = qs.parse(history.location.search).financeCenters as string | string[]
        const selectedFinCentersIDs: string[] = Array.isArray(parseFinCenters) ? parseFinCenters : [parseFinCenters]

        setState((prevState) => ({
          ...prevState,
          selectedFinCentersIDs,
          data: data,
          maxDeep: data?.maxDeep,
          availableYears: data?.availableYears,
        }))
      })
      .finally(() => {
        gridRef.current?.api?.hideOverlay()
      })

    // return () => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         data: null,
    //     }))
    // }
  }, [bddsObservedQuery, historyFinanceCenters, historySavedReportID, budgetType])

  function changeGlobalLvl(lvl: number) {
    setState((prevState) => ({
      ...prevState,
      globalLvl: lvl,
    }))
  }

  function closeSelectFinCenterDrawer() {
    setState((prevState) => ({
      ...prevState,
      isSelectFinCentersDrawerOpen: false,
    }))
  }

  function openSelectFinCenterDrawer() {
    setState((prevState) => ({
      ...prevState,
      isSelectFinCentersDrawerOpen: true,
    }))
  }

  function changeSaveReportDetails(
    newStatus: IBddsReportPageState['saveReportDetails']['status'],
    date?: IBddsReportPageState['saveReportDetails']['date'],
  ) {
    setState((prevState) => ({
      ...prevState,
      saveReportDetails: {
        ...prevState.saveReportDetails,
        status: newStatus,
        date: date,
      },
    }))
  }

  /**
   * локальное состояние для таблицы,
   * в row лежат динамические данные которые раскрываются
   * в pinnedRow данные которые прибиты к низу таблицы
   */
  const [agData, setAgData] = useState<{ row: IFlatReportData[]; pinnedRow: IFlatReportData[] }>({
    row: [],
    pinnedRow: [],
  })

  /**
   * локальное состояние для скрытия и раскрытия групп
   */
  const [hiddenRowsIds, setHiddenRowsIds] = useState<string[]>([])

  /**
   * эффект следит за получаемыми данными с сервера,
   * строится плоская структура
   */
  useEffect(() => {
    if (isFetching) {
      setAgData(() => ({ row: [], pinnedRow: [] }))
      setFilteredAgData([])
    } else {
      if (state.data?.data && state.data?.stats) {
        const row: IFlatReportData[] = []
        state.data.data.children.forEach((item) => {
          row.push(...flatChildren(item.children, t, 1).flat())
        })
        const pinnedRow = state.data.stats.map((_) => {
          return {
            title: t('BDR_STATS.' + _.title),
            deep: -1,
            parentID: null,
            id: generateUniqueId(),
            type: 'row' as IFlatReportData['type'],
            units: _.units,
            children: [] as IFlatReportData[],
          }
        })
        if (row.length) {
          setAgData({ row: row.flat(), pinnedRow })
          // gridRef.current?.api?.hideOverlay()
        } else {
          setAgData({ row: [], pinnedRow: [] })
          // gridRef.current?.api?.showNoRowsOverlay()
        }
      }
    }
  }, [state.data?.data, state.data?.stats, isFetching])

  /**
   * здесь генерируются данные для построения заголовка таблицы,
   * выясняется многолетний он или нет,
   * если да, то на верхний уровень добавляется отображение годов,
   * а ниже в зависимости от вида либо месяца либо кварталы
   */
  const mapHeaders = React.useMemo(() => {
    if (!state.data || !(agData && agData.row[0])) return

    function getAvailableData(years: number[], type?: 'YEARLY' | 'QUARTERLY' | 'MONTHLY') {
      if (!type || !(agData && agData.row[0])) return
      const key = type === 'YEARLY' ? 'year' : type === 'QUARTERLY' ? 'quarter' : 'month'
      const translateKey = key === 'year' ? null : key === 'quarter' ? 'QUARTERS.' : 'MONTHS.'

      if (years.length <= 1 || type === 'YEARLY') {
        return agData.pinnedRow[0].units.map((item) => {
          return {
            title: item?.scale?.[key as keyof typeof item.scale]
              ? translateKey
                ? t(translateKey + item.scale?.[key])
                : item.scale[key as keyof typeof item.scale]
              : 'Итого',
            children: [],
          }
        })
        //changed the output of numbers in the column
      } else {
        return agData.pinnedRow[0].units.map((item) => {
          return {
            title: item?.scale?.[key as keyof typeof item.scale]
              ? translateKey
                ? // ? t(translateKey + item.scale?.[key]) + ',\u00A0' + item.scale?.year
                  t(translateKey + item.scale?.[key]) + (item.scale?.year ? ',\u00A0' + item.scale?.year : '')
                : item.scale[key as keyof typeof item.scale] + (item.scale ? ',\u00A0' + item.scale : '')
              : 'Итого',
            children: [],
          }
        })
      }
    }
    return getAvailableData(state.data!.availableYears, state.data.type)
  }, [state.data, agData])

  /**
   * возвращает массив настроек таблицы, генерируются заголовки и выводятся данные
   *
   * не успел допилить стили и сворачивание и разворачивание строк
   */
  const getHeaderTable: (ColDef<IFlatReportData, any> | ColGroupDef<IFlatReportData>)[] = React.useMemo(() => {
    let index = 0
    return (
      mapHeaders?.map((col, i) => {
        let isTotal = col.title === 'Итого'
        return {
          headerName: String(col.title),
          hide: isTotal,
          // flex: 1, // suppressSizeToFit: true,
          headerClass: 'header-cell-center',
          // suppressAutoSize: false,
          // suppressSizeToFit: false,
          cellClass: (params: CellClassParams<IFlatReportData, any>) => {
            return params.node.rowPinned === 'bottom'
              ? 'row-pinned-bottom'
              : params.data?.type === 'row'
              ? 'mute-row'
              : 'row-style-' + params.data?.deep
          },
          minWidth: 200,
          initialWidth: 200,
          initialFlex: 1,
          cellRenderer: ({ data }: ICellRendererParams<IFlatReportData, any, any>) => {
            // const value = data?.units?.find((_, index) => i === index)?.sum ?? 0
            const quarters = { FIRST: 'I кв', SECOND: 'II кв', THIRD: 'III кв', FOURTH: 'IV кв' }
            const months = {
              JANUARY: 'Январь',
              FEBRUARY: 'Февраль',
              MARCH: 'Март',
              APRIL: 'Апрель',
              MAY: 'Май',
              JUNE: 'Июнь',
              JULY: 'Июль',
              AUGUST: 'Август',
              SEPTEMBER: 'Сентябрь',
              OCTOBER: 'Октябрь',
              NOVEMBER: 'Ноябрь',
              DECEMBER: 'Декабрь',
            }
            const value =
              data?.units?.find((_, index) => {
                switch (state?.data?.type) {
                  case 'MONTHLY':
                    return _.scale?.month
                      ? months[_.scale!.month as keyof typeof months] === String(col.title)
                      : !_.scale?.month
                  case 'YEARLY':
                    return _.scale?.year ? _.scale?.year.toString() === String(col.title) : !_.scale?.year
                  case 'QUARTERLY':
                    return _.scale?.quarter
                      ? quarters[_.scale?.quarter as keyof typeof quarters] === String(col.title)
                      : !_.scale?.quarter
                }
              })?.sum ?? 0

            return (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <EENumber
                  align="right"
                  mode={'view'}
                  name={data?.units?.[index]?.scale?.year?.toString() || String(index)}
                  value={value}
                  NumberFormatProps={{
                    format: (value) => formatter.format(Number(value)),
                  }}
                  viewModeStyle={tableCellInputSmall_ViewMode}
                />
              </Stack>
            )
          },
        }
      }) || []
    )
  }, [mapHeaders, state.data, budgetType, state?.data?.type])

  /**
   * состояние для хранения отфильтрованных строк таблицы
   */
  const [filteredAgData, setFilteredAgData] = useState<IFlatReportData[]>([])

  /**
   * Хранит настройку заголовков таблицы и вывод в ячейки
   * (для кастомного добавления стиля ячеек можно добавить cellRenderer, пример выше)
   */
  const columnsDef = React.useMemo<(ColDef<IFlatReportData, any> | ColGroupDef<IFlatReportData>)[]>(() => {
    return [
      {
        field: 'title',
        minWidth: 300,
        initialWidth: 300,
        flex: 12,
        initialFlex: 12,
        suppressSizeToFit: true,
        // pinned: 'left',
        suppressAutoSize: true,
        // lockPinned: false,
        // suppressMovable: true,
        cellClass: (params) => {
          return params.node.rowPinned === 'bottom'
            ? 'row-pinned-bottom'
            : params.data?.type === 'row'
            ? 'mute-row'
            : 'row-style-' + params.data?.deep
        },
        cellStyle: (cellClassParams) => {
          return { paddingLeft: (cellClassParams.data?.deep ?? 1) * 8 }
        },
        headerName: 'Наименование статьи/субсчета',
      },
      ...(getHeaderTable || []),
    ]
  }, [state.data, agData, getHeaderTable])

  /**
   * Добавляет стандартные данные для управления заголовком таблицы
   */
  const defaultColDef: ColDef<IFlatReportData, any> = React.useMemo(() => {
    return {
      editable: false,
      resizable: true,
    }
  }, [])

  const gridOptions: GridOptions = {
    enableCellTextSelection: true,
  }

  /**
   * функция для сворачивания и разворачивания строк
   * принимает id строки
   */
  const collapse = (id: string) => {
    setHiddenRowsIds((d) => {
      const copy = [...d]
      const addIndex = copy.findIndex((hrId) => hrId === id)
      if (addIndex >= 0) {
        copy.splice(addIndex, 1)
      } else {
        copy.push(id)
      }
      return copy
    })
  }

  useEffect(() => {
    if (!isFetching) {
      setFilteredAgData(() => {
        if (agData.row) {
          const indexes: string[] = []
          const filledArr = agData.row.reduce((acc: IFlatReportData[], curr) => {
            const newItem: typeof curr = Object.assign({}, curr)
            let idx = false
            if (newItem && newItem.parentID && newItem.id) {
              idx = hiddenRowsIds.includes(newItem.parentID) || indexes.includes(newItem.id)
              if (idx && newItem.children.length) {
                indexes.push(...newItem.children.map((item) => item.id))
              }
            }
            return !idx ? [...acc, newItem] : acc
          }, [])
          return filledArr?.length ? filledArr : agData.row
        } else {
          return []
        }
      })
    }
  }, [hiddenRowsIds, agData.row, state.data])

  useEffect(() => {
    if (!isFetching) {
      if (agData.row.length) {
        setHiddenRowsIds((d) => {
          const copy = [...d]
          // if (!copy.length) {
          agData.row.filter((item) => item.type === 'folder').forEach((file) => copy.push(file.id))
          // }
          return copy
        })
      }
    }
  }, [agData.row, isFetching])

  useLayoutEffect(() => {
    if (isFetching) {
      gridRef.current?.api?.showLoadingOverlay()
    } else {
      if (filteredAgData.length) {
        gridRef.current?.api?.hideOverlay()
      } else {
        gridRef.current?.api?.showNoRowsOverlay()
      }
    }
    // gridRef.current?.columnApi?.getColumns()?.forEach((column) => {
    //     allColumnIds.push(column.getId());
    // });
    // console.log(allColumnIds);
    // gridRef.current?.columnApi?.autoSizeColumns(allColumnIds,false);
    // gridRef.current?.api?.size({columnLimits:allColumnIds,defaultMaxWidth:250});
  }, [gridRef.current, isFetching, filteredAgData, columnsDef])

  // useEffect(() => {
  //     // if (gridRef.current && !isFetching && columnsDef.length&&columnsDef.length > 3 ) {
  //         const colIds = gridRef.current?.columnApi?.getColumns()?.map(col => col.getColId())||[];
  //         console.log(colIds);
  //         gridRef.current?.columnApi?.autoSizeColumns(colIds);
  //     // }
  // }, [gridRef.current,columnsDef,isFetching]);

  return (
    <Box>
      {state.allProjectFinCenters && state.selectedFinCentersIDs && (
        <>
          <SelectFinCenter
            isCapital={isCapital}
            allFinCenters={state.allProjectFinCenters}
            availableFinCenters={state.allProjectFinCenters?.filter((projectFinCenter) => {
              return !Object.values(state.selectedFinCentersIDs).includes(String(projectFinCenter.id))
            })}
            filteredFinCenters={state.allProjectFinCenters?.filter(
              (projectFinCenter) => !Object.values(state.selectedFinCentersIDs).includes(String(projectFinCenter.id)),
            )}
            selectedFinCenters={state.allProjectFinCenters?.filter((projectFinCenter) =>
              Object.values(state.selectedFinCentersIDs).includes(String(projectFinCenter.id)),
            )}
            budgetType={getQueryParam(history, 'budgetType') as TBudgetType}
            isOpen={state.isSelectFinCentersDrawerOpen}
            onCreateReport={(finCentersIDArr) => {
              if (state.project) {
                createFullReport(
                  state.project,
                  getQueryParam(history, 'budgetType') as TBudgetType,
                  history,
                  undefined,
                  finCentersIDArr,
                  typeUrl,
                )
                closeSelectFinCenterDrawer()
                changeSaveReportDetails('readyToSave', undefined)
              }
            }}
            onClose={closeSelectFinCenterDrawer}
            onCancel={closeSelectFinCenterDrawer}
          />
        </>
      )}
      <>
        {state.project && (
          <ControlPanel.Wrapper
            sx={
              getQueryParam(history, 'savedReportID')
                ? {
                    '@media (max-width: 1120px)': {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      '.InnerContainer': {
                        margin: 0,
                        ':nth-of-type(3)': {
                          marginTop: '-46px',
                          marginLeft: 'auto',
                        },
                      },
                      gap: 1,
                    },
                  }
                : {
                    '@media (max-width: 1350px)': {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      '.InnerContainer': {
                        margin: 0,
                        ':nth-of-type(3)': {
                          marginTop: '-46px',
                          marginLeft: 'auto',
                        },
                      },
                      gap: 1,
                    },
                  }
            }>
            {/* <ControlPanel.InnerContainer>
                            <ControlPanel.PercentageDisplay />
                        </ControlPanel.InnerContainer> */}

            <ControlPanel.InnerContainer>
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <StyledTabs
                  sx={STYLED.TABS.OUTLINED}
                  value={getQueryParam(history, 'type')}
                  onChange={(e, value: 'plan' | 'fact' | 'balance') => changeQueryParams(history, { type: value })}>
                  <Tab label="План" value="plan"></Tab>
                  <Tab label="Факт" value="fact"></Tab>
                  <Tab label="Отклонение" value="balance"></Tab>
                </StyledTabs>
                <BudgetControls variant="report" availableYears={getYearsArrFromProject(state.project)} />
              </Stack>
            </ControlPanel.InnerContainer>

            <ControlPanel.InnerContainer align="right">
              <Stack spacing={2.25} direction={'row'}>
                {/* {!getQueryParam(history, 'savedReportID') &&
                                    state.data &&
                                    state.data.data.children.length > 0 && (
                                        <ControlPanel.InnerContainer align="center" gap={2}>
                                            {state.saveReportDetails.status == 'readyToSave' ? (
                                                <Typography variant="tooltip" sx={statusBar}>
                                                    Для сохранения нажмите
                                                </Typography>
                                            ) : state.saveReportDetails.status == 'pending' ? (
                                                <Typography variant="tooltip" sx={statusBarSuccess}>
                                                    Сохраняем отчет, подождите
                                                </Typography>
                                            ) : (
                                                <Typography variant="tooltip" sx={statusBarSuccess}>
                                                    Отчет {t('BUDGET_TYPE.' + getQueryParam(history, 'budgetType'))}{' '}
                                                    сохранен{' '}
                                                    {format(state.saveReportDetails!.date!, 'dd.MM.yyyy, HH:MM')}
                                                </Typography>
                                            )}
                                        </ControlPanel.InnerContainer>
                                    )} */}
                {!getQueryParam(history, 'savedReportID') && state.data && state.data.data.children.length > 0 && (
                  <ControlPanel.Btn
                    variant="contained"
                    startIcon={
                      <>
                        {state.saveReportDetails.status == 'readyToSave' && <SaveIcon />}
                        {state.saveReportDetails.status == 'pending' && (
                          <CircularProgress
                            size="20px"
                            sx={{
                              textAlign: 'left',
                              display: 'block',
                              position: 'relative !important',
                              color: theme.palette.text.light!,
                              left: 0,
                              top: 0,
                            }}
                          />
                        )}
                      </>
                    }
                    onClick={() =>
                      state.saveReportDetails.status == 'readyToSave' &&
                      saveReport(history, projectID, state.selectedFinCentersIDs, changeSaveReportDetails)
                    }>
                    {state.saveReportDetails.status == 'readyToSave' && 'Сохранить'}
                    {state.saveReportDetails.status == 'pending' && 'Сохраняем отчет, подождите'}
                  </ControlPanel.Btn>
                )}

                <ControlPanel.Btn
                  startIcon={<Download />}
                  onClick={() => getExcelReport(history, t, projectID, state.project, state.selectedFinCentersIDs)}>
                  Скачать .xls
                </ControlPanel.Btn>
              </Stack>
              {state.data && state.data?.data.children.length > 0 && (
                <Report.LvlControl disabled={!state.data} onLvlChange={changeGlobalLvl} maxDeep={state.data.maxDeep} />
              )}
            </ControlPanel.InnerContainer>
          </ControlPanel.Wrapper>
        )}
        <div style={{ height: 'calc(100vh - 142px)', minHeight: '150px' }}>
          {/*<div style={{ height:"calc(100vh - 142px)"}}>*/}
          <div
            className="ag-theme-alpine"
            // style={{ width: "100%", maxHeight: "calc(100vh - 142px)", minHeight:'150px', height:'auto', paddingLeft: 20, paddingRight: 8 }}>
            style={{ width: '100%', height: '100%', paddingLeft: 20, paddingRight: 8 }}>
            <StyledAgGrid
              columnDefs={columnsDef}
              rowData={filteredAgData}
              defaultColDef={defaultColDef}
              onRowClicked={(params) => {
                if (params.data?.type === 'folder' && params.data?.id) {
                  collapse(params.data.id)
                }
              }}
              // onGridSizeChanged={(event) => {
              //     event.columnApi.autoSizeAllColumns()
              //     // event.api.sizeColumnsToFit();
              // }}
              //     onViewportChanged={(event) => {
              //         event.columnApi.autoSizeAllColumns()
              //         // event.api.sizeColumnsToFit();
              //     }}
              // onGridReady={(event) => {
              //     event.columnApi.autoSizeAllColumns()
              //     event.api.sizeColumnsToFit();
              // }}
              onBodyScrollEnd={(params) => {
                if (columnsDef.length > 4) {
                  const colIds =
                    params.columnApi
                      .getColumns()
                      ?.map((col) => col.getColId())
                      ?.filter((_) => _ !== 'title') || []

                  params.columnApi.autoSizeColumns(colIds)
                }
              }}
              onFirstDataRendered={(params) => {
                if (columnsDef.length > 4) {
                  const colIds = params.columnApi.getColumns()?.map((col) => col.getColId()) || []
                  params.columnApi.autoSizeColumns(colIds)
                }
              }}
              suppressCellFocus={true}
              pinnedBottomRowData={filteredAgData.length ? agData.pinnedRow : []}
              ref={gridRef}
              loadingOverlayComponent={CircularProgress}
              noRowsOverlayComponent={() =>
                budgetType === 'bdr' ? <div>В выбранном году нет строк</div> : <div>Нет данных для отображения</div>
              }
              gridOptions={gridOptions}
            />
          </div>
        </div>
        {/*{state.data && state.project ? (*/}
        {/*    state.data.data.children.length > 0 ? (*/}
        {/*        <Report.Wrapper*/}
        {/*            sx={{*/}
        {/*                height: 'calc(100vh - 142px)',*/}
        {/*                '@media (max-width: 1650px)': {*/}
        {/*                    height: 'calc(100vh - 188px)',*/}
        {/*                    // marginTop: 1*/}
        {/*                },*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <Report.Headings />*/}
        {/*            {state.data && (*/}
        {/*                <Report.Body*/}
        {/*                    maxDeep={state.data?.maxDeep}*/}
        {/*                    globalLvl={state.globalLvl}*/}
        {/*                    data={state.data?.data}*/}
        {/*                />*/}
        {/*            )}*/}
        {/*            {state.data?.stats && <Report.Stats stats={state.data?.stats} />}*/}
        {/*        </Report.Wrapper>*/}
        {/*    ) : (*/}
        {/*        <HelpPlug.Wrapper>*/}
        {/*            <HelpPlug.Title>Нет данных</HelpPlug.Title>*/}
        {/*            <HelpPlug.Image />*/}
        {/*        </HelpPlug.Wrapper>*/}
        {/*    )*/}
        {/*) : (*/}
        {/*    <CircularProgress />*/}
        {/*)}*/}
      </>
    </Box>
  )
}
