import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material"
import styled from "styled-components"

export const DrawerScrollableWrapper = styled(Stack)`
  overflow: auto;
  scrollbar-gutter: stable;
  padding: 0 16px 0 20px;
  height: 100%;
`

export const WarningWrapper = styled(Stack)`
  border-radius: 4px;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.error.main};
  gap: 12px;
`
export const DrawerAccordion = styled(Accordion)`
  margin: 0;
  box-shadow: none;

  &::before {
    display: none;
  }
`
export const DrawerAccordionSummary = styled(AccordionSummary)`
  min-height: 0;
  padding: 0 0 12px;
  border-bottom: 1px solid #7890b2;

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`
export const DrawerAccordionContent = styled(AccordionDetails)`
  padding: 12px 0 0;
`
