import { Tab, Tabs, Tooltip, Typography } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import { ICategoryTabsProps } from './CategoryTabs.types'
import { IBDRBudgetCategoryInfo } from 'global/api/definitions'

export const CategoryTabs: React.FC<ICategoryTabsProps> = ({ category, setState, options, isProductionProgram }) => {
  const changeCategory = (_: SyntheticEvent<Element, Event>, categoryVal: IBDRBudgetCategoryInfo) => {
    setState((prev) => ({
      ...prev,
      operations: null,
      category: categoryVal,
      subcategory: categoryVal.availableCategories[0] || null,
      ppSubcategory: null,
    }))
  }

  return isProductionProgram ? (
    <Tabs
      value={'ESTIMATE'}
      sx={{
        mr: 2,
      }}>
      <Tab
        label={
          <Typography
            sx={{
              maxWidth: '100%',
              fontSize: 'inherit',
              fontWeight: 'inherit',
              lineHeight: 'inherit',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            СМЕТА
          </Typography>
        }
        value={'ESTIMATE'}
        sx={{
          letterSpacing: '0.46px !important',
          lineHeight: '22px !important',
        }}
      />
    </Tabs>
  ) : (
    <Tabs
      value={category}
      onChange={changeCategory}
      sx={{
        mr: 2,
      }}>
      {options &&
        options.map((item) => {
          return (
            <Tab
              key={item.textName}
              label={
                <Tooltip title={item.textName.length > 43 ? item.textName : undefined}>
                  <Typography
                    sx={{
                      maxWidth: '100%',
                      fontSize: 'inherit',
                      fontWeight: 'inherit',
                      lineHeight: 'inherit',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}>
                    {item.textName}
                  </Typography>
                </Tooltip>
              }
              value={item}
              sx={{
                letterSpacing: '0.46px !important',
                lineHeight: '22px !important',
              }}
            />
          )
        })}
    </Tabs>
  )
}
