import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IBdrParametersFormData } from '../../../pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrParametersDrawerForm.types'
import { ISetBdrParameters, ISetBdrYears } from './bdrParameters.types'

let initialState: IBdrParametersFormData = {
  periodType: 'YEARLY',
  periods: [
    {
      id: Date.now(),
      start: { month: 'JANUARY', quarter: null, year: 2024 },
      end: { month: 'DECEMBER', quarter: null, year: 2024 },
      plan: true,
      fact: true,
      deviation: true,
      columnsHeader: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
    },
  ],
  result: {
    plan: true,
    fact: true,
    deviation: true,
  },
  isResultShown: true,
  // isResultDisabled: false,
  isPercentageShown: true,
  yearStart: null,
  yearEnd: null,
  compared: null,
}

const bdrParametersSlice = createSlice({
  name: 'bdrParametersState',
  initialState,
  reducers: {
    setBdrParameters: (state, { payload }: PayloadAction<ISetBdrParameters>) => {
      return { ...state, ...payload }
    },
    setBdrYears: (state, { payload }: PayloadAction<ISetBdrYears>) => {
      state.yearStart = payload.yearStart
      state.yearEnd = payload.yearEnd

      state.periods[0].start.year = payload.yearStart
      state.periods[0].end.year = payload.yearEnd
    },
    clearBdrParameters: (state) => {
      return { ...initialState }
    },
  },
})

export const { reducer: bdrParametersReducer } = bdrParametersSlice
export const { setBdrParameters, setBdrYears, clearBdrParameters } = bdrParametersSlice.actions
