import { Stack, TextField, Typography } from "@mui/material"
import { theme } from "global/styles/theme"
import styled, { css } from "styled-components"

interface ICellValueProps {
  maxLines: number
}

interface IEditableCellWrapperProps {
  isEditMode: boolean
  isError: boolean
}

export const CellValueStyles = css<ICellValueProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.maxLines};
  -webkit-box-orient: vertical;
  white-space: normal;
`

export const EditableCellWrapper = styled(Stack)<IEditableCellWrapperProps>`
  padding: ${(props) => (props.isEditMode ? "2px" : "4px 8px")};
  justify-content: center;

  border: ${(props) => (props.isError ? `1px solid ${props.theme.palette.error.main}` : "none")};
`

export const CellValue = styled(Typography)<ICellValueProps>`
  ${CellValueStyles}
`

export const EditableCellValue = styled.input`
  height: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
  padding: 6px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.palette.primary.main};
`

export const TooltipErrorStyles = {
  sx: {
    "& .MuiTooltip-tooltip": {
      padding: 2,
      backgroundColor: theme.palette.error.main,
    },
    // zIndex: 1,
  },
}
