import { History } from 'history'
import qs from 'qs'
import { TFunction } from 'react-i18next'
import { Route, matchPath } from 'react-router-dom'
import { ReportAPI } from './api/APIMethods/ReportAPI'
import { IQueryObj, errorHandler, parseObjectToQueryString } from './api/api'
import { IPage, PAGES } from './routing'
import {
  IFinanceCenter,
  IPeriod,
  IProjectInfo,
  IReportRecord,
  TAnalisisTypeLower,
  TBudgetType,
  TESNParamsVariant,
  TFinanceCenterType,
  TMonthName,
  TQuarterName,
  TRangeValue,
} from './types/commos-def'
import { BDDS_OPERATION_CATEGORIES, BDR_OPERATION_CATEGORIES } from './variables'
import { IReportRow } from './api/definitions'

export function getActivePage(locationPathname: string) {
  let activePage = {} as IPage
  PAGES.map((page) => {
    if (matchPath(locationPathname, { path: page.path })) {
      activePage = page
    }
  })
  return activePage
}

export function checkWhiteSpace(v: any) {
  let trimmedValue = v.trim()
  if (v.length > 0 && trimmedValue == '') {
    return 'Поле не должно состоять только из пробелов'
  } else {
    return true
  }
}

export function getAllSubPages(pages: IPage[]) {
  let targetSubPages: IPage[] = []
  pages.map((page: IPage) => {
    if (page.subPages) {
      targetSubPages = targetSubPages.concat(page.subPages)
    }
  })
  console.log(targetSubPages)
  return targetSubPages
}

export function getRoutesArray(pages: IPage[]) {
  return pages.map((page: IPage) => {
    return <Route path={page.path}>{page.component}</Route>
  })
}

export function getQuarterRange(quarterFrom: TQuarterName, quarterTo: TQuarterName, quarters: TQuarterName[]) {
  let _quarters = [...quarters]
  return _quarters.filter((quarter: TQuarterName, index: number) => {
    return index >= quarters.indexOf(quarterFrom) && index <= quarters.indexOf(quarterTo)
  })
}

export function getMonthRange(rangeFrom: TRangeValue, rangeTo: TRangeValue, months: TMonthName[]) {
  let _months = [...months]
  return _months.filter((month: TMonthName, index: number) => {
    return index >= months.indexOf(rangeFrom as TMonthName) && index <= months.indexOf(rangeTo as TMonthName)
  })
}

export function getYearRange(rangeFrom: TRangeValue, rangeTo: TRangeValue) {
  let targetArray = [rangeFrom]
  if (rangeFrom && rangeTo) {
    while (rangeFrom < rangeTo) {
      rangeFrom = String(Number(rangeFrom) + 1)
      targetArray.push(rangeFrom)
    }
  }
  return targetArray
}

export function getMonthOptions(rangeType: 'from' | 'to', period: Partial<IPeriod>, months: TMonthName[]) {
  let targetArray: TRangeValue[] = []
  if (rangeType == 'from') {
    targetArray = months.filter((month: TMonthName, index: number) => {
      return index <= months.indexOf(period.rangeTo as TMonthName)
    })
  }
  if (rangeType == 'to') {
    targetArray = months.filter((month: TMonthName, index: number) => {
      return index >= months.indexOf(period.rangeFrom as TMonthName)
    })
  }
  return targetArray
}

export function millionToThousands(value: string) {
  if (value == null || value == undefined) return <div></div>
  let arr = value.split(' ')
  arr.splice(-2, 1, 'т.')
  return <div>{value.length >= 12 ? arr.join(' ') : value}</div>
}

export function getActualEsnParams(variant: TESNParamsVariant, FSS: number) {
  if (!variant) {
    return {
      percents: {
        PFR: {
          full: 22,
          benefit: 10,
        },
        VNIM: {
          full: 2.9,
          benefit: 0,
        },
        OMS: {
          full: 5.1,
          benefit: undefined,
        },
        FSS: {
          full: 0,
          benefit: undefined,
        },
      },
      limits: {
        PFR: 1565000,
        VNIM: 1032000,
        OMS: undefined,
        FSS: undefined,
      },
    }
  }
  switch (variant) {
    case 'FULL':
      return {
        percents: {
          PFR: {
            full: 22,
            benefit: 10,
          },
          VNIM: {
            full: 2.9,
            benefit: 0,
          },
          OMS: {
            full: 5.1,
            benefit: undefined,
          },
          FSS: {
            full: FSS,
            benefit: undefined,
          },
        },
        limits: {
          PFR: 1565000,
          VNIM: 1032000,
          OMS: undefined,
          FSS: undefined,
        },
      }
    case 'BENEFITS_FOR_SMALL_AND_MEDIUM_BUSINESSES':
      return {
        percents: {
          PFR: {
            full: 10,
            benefit: undefined,
          },
          VNIM: {
            full: 0,
            benefit: undefined,
          },
          OMS: {
            full: 5,
            benefit: undefined,
          },
          FSS: {
            full: FSS,
            benefit: undefined,
          },
        },
        limits: {
          PFR: undefined,
          VNIM: undefined,
          OMS: undefined,
          FSS: undefined,
        },
      }
    case 'BENEFITS_FOR_INFORMATION_TECHNOLOGY_BUSINESSES':
      return {
        percents: {
          PFR: {
            full: 6,
            benefit: undefined,
          },
          VNIM: {
            full: 1.5,
            benefit: undefined,
          },
          OMS: {
            full: 0.1,
            benefit: undefined,
          },
          FSS: {
            full: FSS,
            benefit: undefined,
          },
        },
        limits: {
          PFR: undefined,
          VNIM: undefined,
          OMS: undefined,
          FSS: undefined,
        },
      }
    case 'ZERO':
      return {
        percents: {
          PFR: {
            full: 0,
            benefit: undefined,
          },
          VNIM: {
            full: 0,
            benefit: undefined,
          },
          OMS: {
            full: 0,
            benefit: undefined,
          },
          FSS: {
            full: FSS,
            benefit: undefined,
          },
        },
        limits: {
          PFR: undefined,
          VNIM: undefined,
          OMS: undefined,
          FSS: undefined,
        },
      }
  }
}

export function getDeepCopy(data: Object | Array<any> | undefined) {
  if (data == undefined) {
    return undefined
  }
  return JSON.parse(JSON.stringify(data))
}

export function getReportValueName(record: IReportRecord, translate: TFunction<'ENUMS', undefined>, name?: string) {
  if (record.value.operationType) {
    if (name === 'Черное море' && record.value.operationType === 'OUTCOME') {
      return 'КАПВЛОЖЕНИЯ, руб'
    }
    let target = translate('OPERATION_TYPE.' + record.value.operationType).toUpperCase()
    target += ', руб'
    return target
  }
  if (record.value.category) {
    let localCategory: any = record.value.category
    if (localCategory === 'OPERATING_EXPENSES') localCategory = 'OTHER_OPERATING_EXPENSES'
    if (name === 'Черное море' && record.value.category.endsWith('OPERATING_EXPENSES')) {
      return 'ЗАТРАТЫ'
    }
    return translate(`${record.value.activityType}_OPERATION_CATEGORIES.` + localCategory).toUpperCase()
  }
  if (record.value.subBill?.name) {
    return record.value.subBill?.name
  }
  if (record.value.articleType) {
    return translate(`BDR_ARTICLE_CATEGORY.` + record.value.articleType).toUpperCase()
  }
  if (record.value.article) {
    return record.value.article.name
  }
}

export function getCompareReportValueName(record: IReportRow, translate: TFunction<'ENUMS', undefined>, name?: string) {
  if (record.title) {
    return record.title
  }
  if (record.subBill?.name) {
    return record.subBill?.name
  }
  if (record.articleType) {
    return translate(`BDR_ARTICLE_CATEGORY.` + record.articleType).toUpperCase()
  }
  if (record.article) {
    return record.article.name
  }
}

export function getOperationCategoriesByBudgetType(budgetType: TBudgetType) {
  return budgetType == 'bdr' ? BDR_OPERATION_CATEGORIES : BDDS_OPERATION_CATEGORIES
}

export function parseArrayIntoQueryString(key: string, array: Array<string> | undefined) {
  return array ? array.map((value) => `&${key}=${value}`).join('') : ''
}

export function changeQueryParams(history: History, query: IQueryObj) {
  history.replace({
    search: parseObjectToQueryString({
      ...qs.parse(history.location.search, { ignoreQueryPrefix: true }),
      ...query,
    }),
  })
}

export function getQueryParam(history: History, param: string) {
  return new URLSearchParams(history.location.search).get(param)
}

export function getFinCentersByType(type: TFinanceCenterType | undefined, finCenters: IFinanceCenter[]) {
  if (type) {
    return finCenters.filter((finCenter) => type == finCenter.type)
  } else {
    return finCenters
  }
}

export function sortFinCentersByName(finCenters: IFinanceCenter[]) {
  if (finCenters.length == 0) {
    return [] as IFinanceCenter[]
  } else {
    return finCenters.sort((a: IFinanceCenter, b: IFinanceCenter) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      } else if (a.name.toLowerCase() == b.name.toLowerCase()) {
        return 0
      } else {
        return -1
      }
    })
  }
}

export async function checkIsReportEmpty(budgetType: TBudgetType, projectID: string, financeCenters?: string[]) {
  let isEmpty: boolean = false

  await ReportAPI.checkIsReportEmpty(budgetType, {
    projects: [projectID],
    financeCenters: financeCenters,
  }).then((data) => {
    if (data === true) {
      errorHandler({
        response: {
          message:
            'Нет данных для формирования отчета.\n Выберите другой вариант или наполните проект недостающей информацией',
        },
      })
    }
    isEmpty = data
  })

  return isEmpty
}

export function createFullReport(
  project: IProjectInfo,
  budgetType: TBudgetType,
  history: History,
  savedReportID?: string,
  financeCenters?: string[],
  typeUrl?: TAnalisisTypeLower,
  comparedReporsIds?: number[],
) {
  const localDataForHistory = {
    type: typeUrl ?? 'plan',
    savedReportID: savedReportID,
    budgetType: budgetType,
    reportType: 'YEARLY',
    monthStart: 'JANUARY',
    monthEnd: 'DECEMBER',
    yearStart: project.dateStart.split('.')[2],
    yearEnd: project.dateEnd.split('.')[2],
    projects: [project.id],
    financeCenters: financeCenters,
    comparedReporsIds: comparedReporsIds?.toString(),
  }
  const routePath = budgetType === 'bdr' && project.type === 'INVEST' ? 'capital' : budgetType
  !savedReportID
    ? checkIsReportEmpty(budgetType, project.id.toString(), financeCenters).then(
        (isEmpty) =>
          !isEmpty &&
          history.push({
            pathname: `/projects/${project.id}/reports/${routePath}/report`,
            search: qs.stringify(localDataForHistory),
          }),
      )
    : history.push({
        pathname: `/projects/${project.id}/reports/${routePath}/report`,
        search: qs.stringify(localDataForHistory),
      })
}

export const getBase64 = (file: Blob) => {
  return new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}
