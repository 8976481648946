/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components'
import { AgGridReact } from 'ag-grid-react'
import { IFlatReportData } from './BdrReportPage'

interface IStyledAgGridProps {
  isBudgetTable?: boolean
  footerHeight?: number
}

export const StyledAgGrid = styled(AgGridReact<any>)<IStyledAgGridProps>`
  --ag-border-color: #6e86ab !important;
  --ag-header-cell-hover-background-color: #6e86ab !important;
  --ag-cell-horizontal-border: solid rgb(221, 226, 234);
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: #6e86ab !important;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-resize-handle-width: 0px;
  --ag-header-column-resize-handle-color: transparent;
  --ag-header-background-color: #7890b2;
  --ag-header-foreground-color: #7890b2;
  --ag-row-border-color: rgba(237, 239, 243, 1);
  --ag-header-cell-moving-background-color: #8e9db2;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  height: min-content !important;
  max-height: calc(100vh - 142px);

  .ag-header-cell,
  .ag-header-group-cell {
    color: white !important;
  }

  .ag-root-wrapper {
    border-radius: 8px 8px 0 0 !important;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.12));
  }

  .ag-cell {
    padding: 8px;
    border-right: none;

    div,
    span {
      line-height: 1.3em;
      height: 100%;
    }
  }

  .ag-layout-normal {
    .ag-body {
      flex-grow: 0;
    }
  }

  .ag-root.ag-unselectable.ag-layout-normal {
    height: auto;
    min-height: 150px;
  }

  .ag-root-wrapper.ag-ltr.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 142px);
  }

  .ag-root-wrapper.ag-ltr.ag-keyboard-focus.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 142px);
  }

  .ag-root-wrapper-body.ag-focus-managed.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 142px);
  }

  .ag-header-cell-label {
    color: white;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.17px;
  }

  .row-pinned-bottom {
    background: #d1dff5;
    color: #0044b4;
    font-weight: 600;
  }

  .ag-header-cell-label,
  .header-cell-center {
    justify-content: center;
  }

  .ag-cell-last-left-pinned {
    line-height: 1.5em;
  }

  .ag-row-hover {
    .mute-row {
      background: rgba(33, 150, 243, 0.1) !important;
      color: #5c6e8c;
    }
  }

  .mute-row {
    background: #fffcfc;
    color: #5c6e8c;
  }

  .row-style-1 {
    background: #e5ecf7;
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }

  .row-style-2 {
    background: #f2f6fb;
    cursor: pointer;
  }

  .row-style-3 {
    background: #f8fcfc;
    cursor: pointer;
  }

  .row-style-4 {
    background: #fcf9f9;
    cursor: pointer;
  }

  .row-style-5 {
    background: #fcf9f9;
    cursor: pointer;
  }

  .row-style-6 {
    background: #fcf9f9;
    cursor: pointer;
  }

  .row-style-7 {
    background: #fcf9f9;
    cursor: pointer;
  }

  .row-style-8 {
    background: #fcf9f9;
    cursor: pointer;
  }

  .row-style-9 {
    background: #fcf9f9;
    cursor: pointer;
  }

  .row-style-10 {
    background: #fcf9f9;
    cursor: pointer;
  }

  .row-style-11 {
    background: #fcf9f9;
    cursor: pointer;
  }

  ${(props) =>
    props.isBudgetTable &&
    `
        .ag-floating-bottom {
            height: fit-content !important;
            min-height: ${props.footerHeight}px !important;
        }

        .ag-root-wrapper.ag-ltr.ag-layout-normal {
            max-height: calc(100vh - 200px) !important;

            @media (max-width: 1679px) {
                max-height: calc(100vh - 250px) !important;
            }
        }
    `}
`
