import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { CircularProgress } from '@mui/material'

import { EstimateTotal } from 'global/api/constructors'
import { ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi } from 'ag-grid-community'

import { IPrPrEstimateOperation, ProjectType, TEstimateType } from 'global/types/commos-def'
import { IProductionProgramState } from 'pages/ProductionProgram/ProductionProgram-def'
import {
  cellRendererParamsNumber,
  cellRendererParamsString,
} from 'pages/ProductionProgram/ProductionProgram.generateTable.Estimate'
import { StyledAgGrid } from './ProductionProgramAgGrid.styles'
import { CellRenderer } from 'components/TableAGGrid/CellRenderer/CellRenderer'

interface IEstimateTableGridProps {
  isLoading: boolean
  filteredRows: IPrPrEstimateOperation[]
  total: IProductionProgramState['total']
  projectType: ProjectType
  subcategory: TEstimateType | null
}

export const ProductionProgramAgGrid = ({
  filteredRows,
  total,
  isLoading,
  projectType,
  subcategory,
}: IEstimateTableGridProps) => {
  const gridApiRef = useRef<GridApi | null>(null)

  const getRowId = useCallback(({ data }) => {
    return data?.id.toString()
  }, [])

  useLayoutEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        if (gridApiRef.current) {
          gridApiRef.current.showLoadingOverlay()
        }
      })
    } else {
      if (filteredRows?.length) {
        setTimeout(() => {
          gridApiRef.current?.hideOverlay()
        }, 200)
      } else {
        gridApiRef.current?.showNoRowsOverlay()
      }
    }
  }, [gridApiRef.current, isLoading, filteredRows])

  const rowData = useMemo(() => {
    return filteredRows
  }, [filteredRows])

  const columnDefs: (ColDef<any, any> | ColGroupDef<any>)[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Наименование работ',
        headerClass: ['column-center'],
        minWidth: 350,
        flex: 2,
        editable: false,
        resizable: false,
        cellRenderer: CellRenderer.String,
        valueGetter: (params) => params?.data?.name || '',
        cellRendererParams: {
          ...cellRendererParamsString,
          EEStringProps: {
            ...cellRendererParamsString.EEStringProps,
            align: 'left',
          },
        },
      },
      ...(subcategory === 'WORKLOAD'
        ? [
            {
              field: 'quantity',
              headerClass: ['column-center'],
              headerName: 'Кол-во',
              minWidth: 80,
              flex: 1,
              editable: false,
              resizable: false,
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
              },
            },
            {
              field: 'unitType',
              headerClass: ['column-center'],
              headerName: 'Ед. изм.',
              minWidth: 80,
              flex: 1,
              cellRenderer: CellRenderer.String,
              editable: false,
              resizable: false,
              cellRendererParams: {
                ...cellRendererParamsString,
                EEStringProps: {
                  ...cellRendererParamsString.EEStringProps,
                  align: 'left',
                },
              },
            },
            ...(projectType === 'GEN'
              ? ([
                  {
                    headerName: 'ГЕНПОДРЯД',
                    headerClass: ['column-center'],
                    flex: 1,
                    children: [
                      {
                        field: 'genContractUnitPrice',
                        headerName: 'Цена за ед.',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        flex: 1,
                        editable: false,
                        resizable: false,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                      {
                        field: 'genContractTotal',
                        headerName: 'Сумма',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        editable: false,
                        resizable: false,
                        flex: 1,
                        valueGetter: (params) => params.data.genContractTotal,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                    ],
                  },
                  {
                    headerName: 'СУБПОДРЯД',
                    headerClass: ['column-center'],
                    flex: 1,
                    children: [
                      {
                        field: 'subContractUnitPrice',
                        headerName: 'Цена за ед.',
                        headerClass: ['column-center'],
                        flex: 1,
                        minWidth: 80,
                        editable: false,
                        resizable: false,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                      {
                        field: 'subContractTotal',
                        headerName: 'Сумма',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        editable: false,
                        resizable: false,
                        flex: 1,
                        valueGetter: (params) => params.data.subContractTotal,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                    ],
                  },
                  {
                    field: 'income',
                    headerClass: ['column-center'],
                    headerName: 'Прибыль',
                    minWidth: 80,
                    flex: 1,
                    resizable: false,
                    valueGetter: (params) => params.data.income,
                    cellRenderer: CellRenderer.EstimateNumber,
                    cellRendererParams: {
                      ...cellRendererParamsNumber,
                    },
                  },
                  {
                    field: 'rentability',
                    headerClass: ['column-center'],
                    headerName: 'Рентабельность',
                    minWidth: 80,
                    flex: 1,
                    resizable: false,
                    valueGetter: (params) => params.data.rentability,
                    cellRenderer: CellRenderer.EstimateNumber,
                    cellRendererParams: {
                      ...cellRendererParamsNumber,
                    },
                  },
                ] as (ColDef<any, any> | ColGroupDef<any>)[])
              : ([
                  {
                    headerName: 'ЗАТРАТЫ',
                    headerClass: ['column-center'],
                    flex: 1,
                    children: [
                      {
                        field: 'subContractUnitPrice',
                        headerName: 'Цена за ед.',
                        headerClass: ['column-center'],
                        flex: 1,
                        minWidth: 80,
                        editable: false,
                        resizable: false,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                      {
                        field: 'subContractTotal',
                        headerName: 'Сумма',
                        headerClass: ['column-center'],
                        flex: 1,
                        minWidth: 80,
                        editable: false,
                        resizable: false,
                        valueGetter: (params) => params.data.subContractTotal,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                    ],
                  },
                ] as (ColDef<any, any> | ColGroupDef<any>)[])),
          ]
        : [
            {
              field: 'humanHoursQuantity',
              headerClass: ['column-center'],
              headerName: 'Кол-во ч/ч',
              minWidth: 80,
              editable: false,
              resizable: false,
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
              },
            },
            {
              field: 'machineryHoursQuantity',
              headerClass: ['column-center'],
              headerName: 'Кол-во м/ч',
              minWidth: 80,
              editable: false,
              resizable: false,
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
              },
            },
            ...(projectType === 'GEN'
              ? ([
                  {
                    headerName: 'ГЕНПОДРЯД',
                    headerClass: ['column-center'],
                    flex: 1,
                    children: [
                      {
                        field: 'genContractPricePerHour',
                        headerName: 'Цена за час',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        flex: 1,
                        resizable: false,
                        valueGetter: (params) => {
                          return params.data.genContractPricePerHour
                        },
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                      {
                        field: 'genContractTotal',
                        headerName: 'Сумма',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        flex: 1,
                        resizable: false,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                    ],
                  },
                  {
                    headerName: 'СУБПОДРЯД',
                    headerClass: ['column-center'],
                    flex: 1,
                    children: [
                      {
                        field: 'subContractPricePerHour',
                        headerName: 'Цена за час',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        flex: 1,
                        resizable: false,
                        valueGetter: (params) => {
                          return params.data.subContractPricePerHour
                        },
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                      {
                        field: 'subContractTotal',
                        headerName: 'Сумма',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        flex: 1,
                        resizable: false,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                    ],
                  },
                  {
                    field: 'incomePerHour',
                    headerClass: ['column-center'],
                    headerName: 'Прибыль в час',
                    minWidth: 80,
                    flex: 1,
                    resizable: false,
                    valueGetter: (params) => {
                      return params.data.subContractPricePerHour
                    },
                    cellRenderer: CellRenderer.EstimateNumber,
                    cellRendererParams: {
                      ...cellRendererParamsNumber,
                    },
                  },
                  {
                    field: 'income',
                    headerClass: ['column-center'],
                    headerName: 'Прибыль всего',
                    minWidth: 80,
                    flex: 1,
                    resizable: false,
                    cellRenderer: CellRenderer.EstimateNumber,
                    cellRendererParams: {
                      ...cellRendererParamsNumber,
                    },
                  },
                  {
                    field: 'rentability',
                    headerClass: ['column-center'],
                    headerName: 'Рентабельность',
                    minWidth: 80,
                    flex: 1,
                    resizable: false,
                    cellRenderer: CellRenderer.EstimateNumber,
                    cellRendererParams: {
                      ...cellRendererParamsNumber,
                    },
                  },
                ] as (ColDef<any, any> | ColGroupDef<any>)[])
              : ([
                  {
                    headerName: 'ЗАТРАТЫ',
                    headerClass: ['column-center', 'dark-background'],
                    flex: 1,
                    children: [
                      {
                        field: 'subContractPricePerHour',
                        headerName: 'Цена за час',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        flex: 1,
                        resizable: false,
                        valueGetter: (params) => {
                          return params.data.subContractPricePerHour
                        },
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                      {
                        field: 'subContractTotal',
                        headerName: 'Сумма',
                        headerClass: ['column-center'],
                        minWidth: 80,
                        flex: 1,
                        resizable: false,
                        cellRenderer: CellRenderer.EstimateNumber,
                        cellRendererParams: {
                          ...cellRendererParamsNumber,
                        },
                      },
                    ],
                  },
                ] as (ColDef<any, any> | ColGroupDef<any>)[])),
          ]),
    ],
    [projectType, subcategory],
  )

  const updateColumns = (params: FirstDataRenderedEvent<any, any>) => {
    const gridContainer = document.querySelector(`.ag-root-wrapper`)
    let allWidth = 0
    const colIDs =
      params?.columnApi?.getColumns()?.map((col) => {
        // if (!grid && col.gridOptionService) {
        // }
        return col.getColId()
      }) || []

    ;(params.columnApi.getColumns() || []).forEach((col: { getActualWidth: () => number }) => {
      allWidth += col.getActualWidth()
    })

    if (gridContainer && allWidth > gridContainer.clientWidth) {
      console.log(1)
      params?.columnApi?.autoSizeColumns(colIDs)
    } else {
      console.log(2)
      let cols = params?.columnApi?.getColumns() || []
      let isColsOverflow = false
      for (const col of cols) {
        const cells = document.querySelectorAll(`.ag-cell[col-id="${col.getColId()}"] .table-input`)
        for (let i = 0; i < cells.length; i++) {
          const cell = cells[i]
          console.log(cell.scrollWidth, cell.clientWidth)
          if (cell.scrollWidth > cell.clientWidth) {
            isColsOverflow = true
            break
          }
        }
        if (isColsOverflow) {
          params?.columnApi?.autoSizeColumns([col.getId()])
          isColsOverflow = false
        }
      }
    }
  }

  return (
    <StyledAgGrid
      gridId="estimate-workload"
      className="white-table"
      rowData={rowData}
      getRowId={getRowId}
      columnDefs={columnDefs}
      rowHeight={50}
      // pinnedBottomRowData={[
      //   {
      //     name: 'ВСЕГО',
      //     id: 'totalRow',
      //     genContractUnitPrice: null,
      //     genContractPricePerHour: null,
      //     genContractTotal: (total as EstimateTotal)?.genContractTotal,
      //     subContractUnitPrice: null,
      //     subContractPricePerHour: (total as EstimateTotal)?.subContractPricePerHour,
      //     subContractContractor: null,
      //     subContractTotal: (total as EstimateTotal)?.subContractTotal,
      //     humanHoursQuantity: (total as EstimateTotal)?.humanHoursQuantity,
      //     machineryHoursQuantity: (total as EstimateTotal)?.machineryHoursQuantity,
      //     income: (total as EstimateTotal)?.income,
      //     incomePerHour: (total as EstimateTotal)?.incomePerHour,
      //     quantity: null,
      //     rentability: (total as EstimateTotal)?.rentability,
      //   },
      // ]}
      onFirstDataRendered={updateColumns}
      suppressColumnMoveAnimation={true}
      suppressCellFocus
      suppressColumnVirtualisation
      loadingOverlayComponent={CircularProgress}
      noRowsOverlayComponent={() => <div>Нет данных</div>}
      onGridReady={(params) => {
        gridApiRef.current = params.api
      }}
    />
  )
}
