import { FC, useEffect, useMemo, useState } from 'react'
import { Button, FormControl, InputLabel, ListItem, MenuItem, Typography } from '@mui/material'
import { Add as AddIcon, Delete } from '@mui/icons-material'
import { ControlPanel } from 'components/ControlPanel/ControlPanel'
import { theme } from '../../../../../../../global/styles/theme'
import { BdrCompareBlock, BdrCompareItemBlock } from './BdrCompare.styles'
import { IBdrParametersFormData } from '../BdrParametersDrawerForm.types'
import { useFormikContext } from 'formik'
import { IBdrCompareItemProps, IBdrCompareProps } from './BdrCompare.types'
import { ReportAPI } from 'global/api/APIMethods/ReportAPI'
import { useHistory, useParams } from 'react-router-dom'
import { Params } from 'global/types/params-def'
import { getQueryParam } from 'global/functions'

export const BdrCompare: FC<IBdrCompareProps> = ({ drawerMode }) => {
  const maxItems = drawerMode ? 3 : 2
  const { values, setFieldValue } = useFormikContext<IBdrParametersFormData>()
  const history = useHistory()
  const budgetType = useMemo(
    () => (history.location.pathname.includes('capital') || history.location.pathname.includes('bdr') ? 'bdr' : 'bdds'),
    [history],
  )
  const { projectID } = useParams() as Params
  const savedReportID = getQueryParam(history, 'savedReportID')
  const [options, setOptions] = useState<IBdrCompareItemProps['options']>([])
  const filteredOptions = useMemo(() => {
    const valuesIds = values.compared?.map((el) => el.val) || []
    let actualOptions = options
    if (!drawerMode) {
      actualOptions = options.filter((el) => el.reportID != savedReportID)
      console.log(savedReportID, actualOptions)
    }
    return actualOptions.map((el) => {
      if (valuesIds.includes(Number(el.reportID))) {
        return { ...el, used: true }
      }
      return el
    })
  }, [values, options, drawerMode])

  useEffect(() => {
    ReportAPI.getSavedReports(budgetType, {
      projects: [projectID],
    }).then((data) => {
      setOptions(
        data.map((el) => ({
          createdAt: el.createdAt,
          createdBy: el.createdBy,
          reportID: el.reportID,
        })),
      )
    })
  }, [])

  const onDeleteReport = (id: number) => {
    setFieldValue('compared', values.compared ? values.compared.filter((el) => el.id !== id) : null)
  }
  const onAddReport = (val: { id: number; val: number | null }) => {
    const formValues = values.compared || []
    const newValues = formValues.map((el) => {
      if (el.id !== val.id) {
        return el
      } else {
        return val
      }
    })
    setFieldValue('compared', newValues)
  }
  const onAddEmptySelect = () => {
    const val = values.compared || []
    setFieldValue('compared', [...val, { id: Math.floor(Math.random() * 1000), val: null }])
  }

  return (
    <BdrCompareBlock spacing={1} className={drawerMode ? 'no-bg' : undefined}>
      {!drawerMode && (
        <Typography
          variant="body1"
          fontWeight={500}
          fontSize={12}
          color={theme.palette.secondary.gray}
          textAlign={'start'}>
          Отчет
        </Typography>
      )}
      {values.compared?.map((el, i) => (
        <BdrCompareItem
          key={el.id}
          val={el}
          onChange={onAddReport}
          onDelete={onDeleteReport}
          options={filteredOptions}
          drawerMode={drawerMode}
          index={i}
        />
      ))}
      {((!drawerMode &&
        (values.compared === null || (values.compared !== null && values.compared.length < maxItems))) ||
        (drawerMode && values.compared != null && values.compared.length < maxItems)) && (
        <Button
          variant="outlined"
          onClick={onAddEmptySelect}
          disabled={values.compared != null && values.compared.filter((el) => el.val === null).length > 0}>
          <AddIcon />
        </Button>
      )}
    </BdrCompareBlock>
  )
}

export const BdrCompareItem: FC<IBdrCompareItemProps> = ({ onChange, onDelete, val, options, drawerMode, index }) => {
  return (
    <BdrCompareItemBlock direction="row" spacing={1.2}>
      <FormControl>
        {val.val === null && (
          <InputLabel style={{ top: '-11px', fontSize: '14px' }}>Выберите отчет для сравнения</InputLabel>
        )}
        <ControlPanel.Select value={val.val} onSelectChange={(value) => onChange({ id: val.id, val: Number(value) })}>
          {options.length > 0 ? (
            options.map((el) => (
              <MenuItem
                key={el.reportID}
                value={el.reportID}
                sx={{
                  display: el.used && val.val !== el.reportID ? 'none' : 'flex',
                  flexDirection: 'column',
                  gap: '7px',
                  alignItems: 'flex-start',
                  padding: '10px 20px',
                }}>
                <Typography variant="body1" lineHeight={1}>
                  ID {el.reportID}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="14px"
                  lineHeight={1}
                  color={theme.palette.secondary.gray}
                  className="report-info">
                  {el.createdAt},{' '}
                  {`${el.createdBy?.lastName} ${el.createdBy?.firstName[0]}. ${el.createdBy?.middleName?.[0] || ''}`}
                </Typography>
              </MenuItem>
            ))
          ) : (
            <ListItem>
              <Typography variant="body1" lineHeight={1} fontSize="14px">
                Нет доступных отчетов
              </Typography>
            </ListItem>
          )}
        </ControlPanel.Select>
      </FormControl>
      <Button variant="outlined" color="error" onClick={() => onDelete(val.id)} disabled={drawerMode && index < 2}>
        <Delete sx={{ fontSize: '16px' }} />
      </Button>
    </BdrCompareItemBlock>
  )
}
