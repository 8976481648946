import { MyModal } from 'components/MyModal/MyModal'
import { ISubBillModalProps, ISubBillModalState } from './SubBillModal.types'
import { theme } from 'global/styles/theme'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Pagination,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { ControlPanel } from 'components/ControlPanel/ControlPanel'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CategoryTabs } from './components/CategoryTabs'
import { SubcategoryTabs } from './components/SubcategoryTabs'
import { BudgetAgGrid } from './components/BudgetAgGrid'
import { Params } from 'global/types/params-def'
import { StyledSelect, StyledSelectMenuItem, SubBillModalError } from './SubBillModal.styles'
import { ReportAPI } from 'global/api/APIMethods/ReportAPI'
import { ProductionProgramAgGrid } from './components/ProductionProgramAgGrid/ProductionProgramAgGrid'

export function SubBillModal(props: ISubBillModalProps) {
  const { open, onClose, project, budgetType, reportId, subBill } = props
  const { projectID } = useParams() as Params
  const [loading, setLoading] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [state, setState] = useState<ISubBillModalState>({
    financeCenterId: null,
    category: null,
    subcategory: null,
    ppSubcategory: null,
    operations: null,
    total: null,
    ppOperations: null,
    ppTotal: null,
    selectOptions: [],
    year: null,
    yearsOptions: [],
    financeCenters: [],
    error: false,
    maxPage: null,
  })
  const [page, setPage] = useState({ number: 0, needupdate: false })
  const currentBudget = useMemo(
    () => state.financeCenters.find((el) => el.second.id === state.financeCenterId),
    [state.financeCenters, state.financeCenterId],
  )
  const errorHandler = () => {
    setState((prev) => ({ ...prev, error: true }))
  }

  useEffect(() => {
    if (open && reportId && subBill?.id) {
      setLoading(true)
      ReportAPI.getBdrReportBudgetsInfo(
        { projectId: Number(projectID), reportId: Number(reportId), subBillId: subBill.id },
        errorHandler,
      ).then((data) => {
        if (data?.financeCenters.length) {
          const initialBudget = data.financeCenters[0]
          setState((prev) => ({
            ...prev,
            financeCenterId: initialBudget.second.id,
            selectOptions: data.financeCenters.map((budget) => ({
              value: budget.second.id,
              label: budget.first,
              disabled: budget.second.isProductionProgram
                ? budget.second.estimateTypes === null || budget.second.estimateTypes?.length === 0
                : budget.second.categories === null || budget.second.categories?.length === 0,
            })),
            financeCenters: data.financeCenters,
            category: initialBudget.second.isProductionProgram
              ? 'ESTIMATE'
              : initialBudget.second.categories && initialBudget.second.categories.length > 0
              ? initialBudget.second.categories[0]
              : null,
            subcategory: initialBudget.second.isProductionProgram
              ? null
              : initialBudget.second.categories && initialBudget.second.categories.length > 0
              ? initialBudget.second.categories[0].availableCategories[0]
              : null,
            ppSubcategory: initialBudget.second.isProductionProgram
              ? initialBudget.second.estimateTypes && initialBudget.second.estimateTypes.length > 0
                ? initialBudget.second.estimateTypes[0]
                : null
              : null,
            year: data.availableYears[0] || null,
            yearsOptions: data.availableYears || [],
          }))
        }
        setLoading(false)
      })
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      setPage({ number: 0, needupdate: false })
    }
  }, [open])

  useEffect(() => {
    setPage({ number: 1, needupdate: true })
  }, [state.subcategory, state.year, state.financeCenterId, state.ppSubcategory])

  useEffect(() => {
    if (page.needupdate && page.number) {
      if (state.subcategory && state.financeCenterId && subBill?.id && state.year) {
        setLoadingTable(true)
        ReportAPI.getBdrReportBySubBill({
          subBillId: subBill.id,
          year: state.year,
          category: state.subcategory.first,
          page: page.number,
          financeCenterID: Number(state.financeCenterId),
          projectID: Number(projectID),
        }).then((data) => {
          setLoadingTable(false)
          if (!data) return
          setState((prevState) => ({
            ...prevState,
            operations: data?.operations,
            total: data?.total || null,
            maxPage: data.maxPage,
          }))
          setPage((prev) => ({ ...prev, needupdate: false }))
        })
      } else if (state.ppSubcategory && state.financeCenterId && subBill?.id) {
        setLoadingTable(true)
        ReportAPI.getPrPrBySubBill({
          subBillId: subBill.id,
          estimateType: state.ppSubcategory.first,
          page: page.number,
          financeCenterID: Number(state.financeCenterId),
          projectID: Number(projectID),
        }).then((data) => {
          setLoadingTable(false)
          if (!data) return
          setState((prevState) => ({
            ...prevState,
            ppOperations: data?.data,
            ppTotal: data?.total || null,
            maxPage: data?.maxPage || null,
          }))
          setPage((prev) => ({ ...prev, needupdate: false }))
        })
      }
    }
  }, [page])

  const handleChangeBudget = (event: SelectChangeEvent<unknown>) => {
    const newBudgetId = event.target.value as string
    const newBudget = state.financeCenters.find((el) => el.second.id === Number(newBudgetId))
    if (newBudget) {
      setState((prevState) => ({
        ...prevState,
        financeCenterId: newBudgetId,
        category: newBudget.second.isProductionProgram
          ? 'ESTIMATE'
          : newBudget.second.categories && newBudget.second.categories.length > 0
          ? newBudget.second.categories[0]
          : null,
        subcategory: newBudget.second.isProductionProgram
          ? null
          : newBudget.second.categories && newBudget.second.categories.length > 0
          ? newBudget.second.categories[0].availableCategories[0]
          : null,
        ppSubcategory: newBudget.second.isProductionProgram
          ? newBudget.second.estimateTypes && newBudget.second.estimateTypes.length > 0
            ? newBudget.second.estimateTypes[0]
            : null
          : null,
      }))
    }
  }

  const subaccountTitleByCategory = useMemo(() => {
    if (state.category && state.category !== 'ESTIMATE') {
      switch (state.category.operationType) {
        case 'INCOME':
          return 'Субсчет доходов:'
        default:
          return 'Субсчет расходов:'
      }
    } else {
      return 'Субсчет:'
    }
  }, [state.category])

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      setPage({ number: pageNumber, needupdate: true })
    },
    [setPage],
  )

  const getPrPrContent = () => {
    return state.ppOperations && state.ppOperations.length > 0 ? (
      <>
        <Pagination
          disabled={!state.ppOperations}
          onChange={(e, v) => handleChangePage(v)}
          count={state.maxPage || 0}
          shape="rounded"
          page={page.number}
          sx={{
            marginLeft: 'auto',
            marginBottom: '8px',
            '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: 'rgba(109, 154, 220, 0.2)',
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
              },
            },
            '& .MuiPagination-ul': {
              flexWrap: 'nowrap',
            },
          }}
        />
        {loadingTable ? (
          <CircularProgress />
        ) : (
          <ProductionProgramAgGrid
            isLoading={false}
            projectType={project?.type || 'GEN'}
            filteredRows={state.ppOperations || []}
            subcategory={state.ppSubcategory?.first || null}
            total={state.ppTotal}
          />
        )}
      </>
    ) : (
      <SubBillModalError>Нет данных.</SubBillModalError>
    )
  }

  const getBdrContent = () => {
    return state.operations && state.operations.length > 0 && state.subcategory?.first ? (
      <>
        <Pagination
          disabled={!state.operations}
          onChange={(e, v) => handleChangePage(v)}
          count={state.maxPage || 0}
          shape="rounded"
          page={page.number}
          sx={{
            marginLeft: 'auto',
            marginBottom: '8px',
            '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: 'rgba(109, 154, 220, 0.2)',
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
              },
            },
            '& .MuiPagination-ul': {
              flexWrap: 'nowrap',
            },
          }}
        />
        {loadingTable ? (
          <CircularProgress />
        ) : (
          <BudgetAgGrid
            operations={state.operations}
            total={state.total}
            fillingMode="QUANTITY"
            category={state.subcategory.first}
          />
        )}
      </>
    ) : (
      <SubBillModalError>Нет данных.</SubBillModalError>
    )
  }

  return (
    <MyModal.Wrapper open={open as boolean} onClose={onClose} sx={{ margin: '30px', overflowX: 'auto' }}>
      <Stack margin="auto" width="100%" height="100%" bgcolor="white" borderRadius="16px" minWidth="900px">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Stack
              flexDirection="row"
              sx={{
                alignItems: 'center',
                gap: '40px',
                padding: '14px 30px',
                borderBottom: '1px solid #D1D8FA',
              }}>
              <FormControl
                style={{ width: '193px', flexShrink: 0 }}
                sx={{
                  '& .MuiInputBase-root.Mui-disabled': {
                    backgroundColor: '#2B36481F',

                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: `#9aa2b0 !important`,
                    },
                    '& .MuiSvgIcon-root': {
                      color: `#9aa2b0`,
                    },
                  },
                  '& .MuiSelect-outlined': {
                    fontSize: '13px !important',
                    // lineHeight: '22px !important',
                    textAlign: 'left !important',
                    color: `${theme.palette.primary.main} !important`,
                    textTransform: 'uppercase',
                    fontWeight: 500,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.primary.main} !important`,
                    borderRadius: '4px !important',
                  },
                  '& .MuiSvgIcon-root': {
                    right: '14px !important',
                    color: `${theme.palette.primary.main}`,
                  },
                }}>
                <StyledSelect
                  value={state.financeCenterId}
                  onChange={handleChangeBudget}
                  disabled={state.error}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        maxHeight: '336px',
                      },
                    },
                  }}>
                  {state.selectOptions.map((option) => (
                    <StyledSelectMenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        color: option.disabled ? theme.palette.secondary.gray : 'inherit',
                      }}>
                      {option.label}
                    </StyledSelectMenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
              <Typography variant="subtitle1">
                <Typography variant="subtitle1" sx={{ color: theme.palette.secondary.main }} component="span">
                  {subaccountTitleByCategory}
                </Typography>{' '}
                {subBill?.name}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                sx={{ width: '180px', marginLeft: 'auto', boxShadow: 'none' }}
                onClick={onClose}>
                Закрыть
              </Button>
            </Stack>
            {!state.error && (
              <ControlPanel.Wrapper p={'12px 30px'} justifyContent={'space-between'} mb={1} sx={{ background: '#fff' }}>
                <ControlPanel.InnerContainer align="left" m={0} justifyContent={'space-between'} width="100%">
                  <CategoryTabs
                    category={state.category}
                    setState={setState}
                    options={currentBudget?.second.categories || []}
                    isProductionProgram={currentBudget?.second.isProductionProgram || false}
                  />
                  <SubcategoryTabs
                    setState={setState}
                    subcategory={currentBudget?.second.isProductionProgram ? state.ppSubcategory : state.subcategory}
                    options={
                      currentBudget?.second.isProductionProgram
                        ? currentBudget?.second.estimateTypes || []
                        : state.category && state.category !== 'ESTIMATE'
                        ? state.category.availableCategories
                        : []
                    }
                    isProductionProgram={currentBudget?.second.isProductionProgram || false}
                  />
                  {!currentBudget?.second.isProductionProgram && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className="className-ControlPanel"
                      sx={{ marginLeft: '16px' }}>
                      <ControlPanel.FilterTitle title="ПЕРИОД:" />
                      <ControlPanel.Select
                        value={state.year}
                        onSelectChange={(val) => setState((prev) => ({ ...prev, year: val }))}
                        sx={{ width: '73px' }}>
                        {state.yearsOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </ControlPanel.Select>
                    </Box>
                  )}
                </ControlPanel.InnerContainer>
              </ControlPanel.Wrapper>
            )}
            <Stack
              sx={{
                overflow: 'auto',
                scrollbarGutter: 'stable',
                padding: '0 22px 0 30px',
                height: '100%',
              }}>
              {state.error ? (
                <SubBillModalError>
                  Нет данных.
                  <br />
                  Бюджеты, на основании которых был сформирован отчет, удалены
                </SubBillModalError>
              ) : currentBudget?.second.isProductionProgram ? (
                getPrPrContent()
              ) : (
                getBdrContent()
              )}
            </Stack>
          </>
        )}
      </Stack>
    </MyModal.Wrapper>
  )
}
