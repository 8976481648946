import { Stack } from "@mui/material"
import styled from "styled-components"

export const BdrCompareBlock = styled(Stack)`
  border-radius: 8px;
  padding: 16px;
  background-color: #1976d214;
  gap: 12px;
  position: relative;

  &.no-bg {
    background-color: #fff;
    padding: 4px 0;
  }

  & > .MuiButtonBase-root {
    width: 40px;
    height: 40px;
    margin: 4px 0 0 auto;
    border-color: #8cbbe8;
    background: rgba(24, 118, 210, 0);
  }
`
export const BdrCompareItemBlock = styled(Stack)`
  align-items: center;
  margin-top: 0 !important;
  & .MuiFormControl-root {
    flex-grow: 1;
    margin-left: 0;

    & .MuiSelect-select {
      line-height: 20px;

      & .report-info {
        display: none;
      }
      & .MuiTypography-root {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  & > .MuiButtonBase-root {
    width: 30px;
    height: 30px;
    padding: 0;
    border-color: #f46b6b;
    background: rgba(255, 177, 177, 0.15);

    &.Mui-disabled {
      background: #2b36481f;
      border-color: transparent !important;
    }
  }
`
