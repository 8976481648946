import { MenuItem, Select, Typography } from "@mui/material"
import styled from "styled-components"

export const StyledSelectMenuItem = styled(MenuItem)`
  width: 193px;
  font-size: 14px;
  line-height: 16.4px;
  text-align: left;
  white-space: normal;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }
  &.Mui-focusVisible {
    background-color: ${(props) => props.theme.palette.bg.gray};
  }
`
export const StyledSelect = styled(Select)`
  width: 193px;
  background: rgba(25, 118, 210, 0.08);
  height: 36px;

  & .MuiSelect-select {
    padding: 0 !important;
    padding-left: 16px !important;
    padding-right: 32px !important;
    height: 36px;
    padding: 0px;
    line-height: 36px;
  }
`
export const SubBillModalError = styled(Typography)`
  margin: auto;
  color: ${(props) => props.theme.palette.primary.main};
`
