import { Box } from '@mui/material'
import { changeQueryParams, getQueryParam, getReportValueName } from 'global/functions'
import { theme } from 'global/styles/theme'
import { IFinanceCenter, IProjectInfo, IReportRecord, TBDRArticleType, TBudgetType } from 'global/types/commos-def'
import { TFunction } from 'react-i18next'
import { History } from 'history'
import { ReportAPI } from 'global/api/APIMethods/ReportAPI'
import { IBddsReportPageState } from './BddsReportPage'
import { IBdrReportPageState, IFlatReportData } from './BdrReportPage'
import { IReportRow } from 'global/api/definitions'

export function getYearsArrFromProject(project: IProjectInfo) {
  let yearStart = Number(project.dateStart.split('.')[2])
  let yearEnd = Number(project.dateEnd.split('.')[2])
  let targetArr = [] as any

  for (let i = yearStart; i <= yearEnd; i++) {
    if (i > 2100) {
      break
    }
    targetArr.push(i)
  }

  return targetArr
}

export function initiateBreadcrumbs(
  project: IProjectInfo,
  finCenters: IFinanceCenter[],
  selectedFinCentersIDs: string[],
  interfaceCTX: any,
  isCapital: boolean,
  history: History<unknown>,
  t: TFunction<'ENUMS', undefined>,
  onClick: () => void,
) {
  let finCentersQty = selectedFinCentersIDs instanceof Array ? selectedFinCentersIDs.length : 1
  interfaceCTX.setBreadcrumbsProps([
    {
      variant: 'link',
      title: `Проект ${project.name}`,
      path: `/projects/${project.id}/menu`,
    },
    {
      variant: 'link',
      title: `Сохраненные отчеты ${
        isCapital ? 'Капвложений' : t('BUDGET_TYPE.' + getQueryParam(history, 'budgetType'))
      }`,
      path: `/projects/${project.id}/reports/${isCapital ? 'capital' : getQueryParam(history, 'budgetType')}`,
    },
    {
      variant: 'title',
      title: selectedFinCentersIDs ? (
        <Box>
          {isCapital ? 'Капвложения' : t('BUDGET_TYPE.' + getQueryParam(history, 'budgetType'))} проекта &nbsp;
          {finCenters.length > 0 && (
            <span
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'underline',
                whiteSpace: 'pre',
                cursor: 'pointer',
              }}
              onClick={onClick}>
              из {finCentersQty} {finCentersQty == 1 ? 'бюджета' : 'бюджетов'}
            </span>
          )}
        </Box>
      ) : (
        `${isCapital ? 'Капвложения' : t('BUDGET_TYPE.' + getQueryParam(history, 'budgetType'))} проекта`
      ),
    },
  ])
}

export function saveReport(
  history: History<unknown>,
  projectID: any,
  financeCentersIDs: string[],
  changeStatus: (
    newStatus: (IBddsReportPageState | IBdrReportPageState)['saveReportDetails']['status'],
    date?: (IBddsReportPageState | IBdrReportPageState)['saveReportDetails']['date'],
  ) => void,
) {
  changeStatus('pending')
  console.log(financeCentersIDs)
  ReportAPI.saveReport(
    getQueryParam(history, 'budgetType') as TBudgetType,
    {
      projects: [String(projectID)],
      financeCenters: financeCentersIDs,
    },
    (savedReportID: number) => {
      !history.location.search.includes('savedReportID') && changeQueryParams(history, { savedReportID })
    },
  ).then(() => {
    changeStatus('saved', new Date())
  })
}

export function getExcelReport(
  history: History<unknown>,
  t: TFunction<'ENUMS', undefined>,
  projectID: any,
  project: IProjectInfo | null,
  financeCentersIDs: string[],
) {
  const paramsObject = Object.fromEntries(new URLSearchParams(history.location.search))

  project &&
    ReportAPI.getExcelReport({
      query: {
        ...paramsObject,
        projects: [String(projectID)],
        financeCenters: financeCentersIDs,
      },
      file: {
        projectName: project.name,
        typeName: t('BUDGET_TYPE.' + paramsObject.budgetType),
      },
    }).then(() => {})
}

export function generateUniqueId() {
  const timestamp = new Date().getTime() // Получаем текущую дату и время в миллисекундах
  const randomNum = Math.floor(Math.random() * 1000) // Генерируем случайное число от 0 до 999
  return `${timestamp}-${randomNum}`
}

/**
 * функция возвращает пересобранный массив данных, плоскую структуру для таблицы
 */
export function flatChildren(
  reportRecord: IReportRecord[],
  t: TFunction<'ENUMS', undefined>,
  isDeep: number,
  parent?: string,
): IReportRow[] {
  return reportRecord.reduce((acc: IReportRow[], report) => {
    const id = generateUniqueId()

    const temp: IReportRow = {
      title: getReportValueName(report, t) || '',
      unitsByReportId: {
        start: report.value.units,
      },
      uuid: id,
      parentUUID: parent ?? '',
      isFolder: report.children.length > 0,
      childrenUuids: [],
      article: report.value.article,
      articleType: report.value.articleType as TBDRArticleType,
      subBill: report.value.subBill,
      lvl: isDeep,
    }
    if (report.children) {
      const children = flatChildren(report.children, t, isDeep + 1, id)
      // Добавляем только id детей в temp.children
      temp.childrenUuids = children.map((child) => child.uuid)
      // Добавляем детей в аккумулятор
      acc.push(temp)
      acc.push(...children)
    } else {
      acc.push(temp)
    }

    return acc
  }, [] as IReportRow[])
}
