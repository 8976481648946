import * as yup from 'yup'
import { bdrPeriodTypes } from './BdrParametersDrawerForm.types'

export const validationBdrParameters = yup.object({
  compared: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        val: yup.number().required(),
      }),
    )
    .nullable(),
  // periodType: yup.string().oneOf(bdrPeriodTypes).required(),
  // periods: yup.array().of(
  //     yup.object().shape({
  //         id: yup.number().required(),
  //         yearStart: yup.number().required(),
  //         yearEnd: yup.number().required(),
  //         plan: yup.boolean().required(),
  //         fact: yup.boolean().required(),
  //         deviation: yup.boolean().required(),
  //     })
  // ),
  // result: yup.array().of(
  //     yup.object().shape({
  //         plan: yup.boolean().required(),
  //         fact: yup.boolean().required(),
  //         deviation: yup.boolean().required(),
  //     })
  // ),
  // isResultShown: yup.boolean(),
  // isPercentageShown: yup.boolean(),
})
