import qs from 'qs'
import React, { UIEvent, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Add, Visibility, VisibilityOff } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { IPrPrEstimateOperation, IPrPrEstimateOperationWithLvl, ProjectType } from '../../../global/types/commos-def'
import { useAgGridLastRowInView } from '../useGridScroll'

import {
  estimateLaboriousGenerateTable,
  estimateWorkloadGenerateTable,
} from '../ProductionProgram.generateTable.Estimate'
import { StyledAgGrid } from '../ProdctionProgram.styles'
import { IProductionProgramState } from '../ProductionProgram-def'
import { EstimateTotal } from 'global/api/constructors'
import { BodyScrollEndEvent, GridApi, GridReadyEvent } from 'ag-grid-community'
import { ProductionProgramAPI } from 'global/api/APIMethods/ProductionProgramAPI'
import { getDeepCopy } from 'global/functions'
import { AgGridReact } from 'ag-grid-react'

interface IEstimateTableGridProps {
  estimateTableContext: any
  operationsLength: number
  isFetching: boolean
  isLoading: boolean
  hasNextPage: boolean
  fetchNextPage: () => void
  filteredRows: IPrPrEstimateOperationWithLvl[]
  total: IProductionProgramState['total']
  projectType: ProjectType
  setState: React.Dispatch<React.SetStateAction<IProductionProgramState>>
}

export const EstimateTableGridLaboratius = ({
  estimateTableContext,
  operationsLength,
  isFetching,
  hasNextPage,
  fetchNextPage,
  filteredRows,
  total,
  isLoading,
  projectType,
  setState,
}: IEstimateTableGridProps) => {
  const gridApiRef = useRef<GridApi | null>(null)
  const gridRef = useRef<AgGridReact<any> | null>(null)
  const { inView: isLastRowInView, reset: resetInView } = useAgGridLastRowInView(
    gridApiRef.current,
    filteredRows.length,
    '[grid-id="estimate-laboratius"] .ag-body-viewport',
  )
  const [rendering, setRendering] = useState(false)

  useEffect(() => {
    if (!isFetching) {
      resetInView() // Сбросить только после того, как данные были успешно загружены
    }
  }, [isFetching])

  const shouldFetchMoreData = !isFetching && isLastRowInView && hasNextPage && !rendering

  useEffect(() => {
    if (!shouldFetchMoreData) return
    fetchNextPage()
  }, [shouldFetchMoreData])

  const getRowId = useCallback(({ data }) => {
    return data?.uId
  }, [])

  useLayoutEffect(() => {
    if (isFetching || isLoading) {
      setRendering(true)
      gridApiRef.current?.showLoadingOverlay()
    } else {
      if (filteredRows?.length) {
        setTimeout(() => {
          setRendering(false)
          gridApiRef.current?.hideOverlay()
        }, 500)
      } else {
        gridApiRef.current?.showNoRowsOverlay()
      }
    }
    return () => {}
  }, [gridApiRef.current, isFetching, filteredRows])

  const rowData = useMemo(() => {
    return filteredRows
  }, [filteredRows])

  const updateColumns = (params: BodyScrollEndEvent<any, any> | GridReadyEvent<any, any>) => {
    const gridContainer = document.querySelector(`.ag-root-wrapper`)
    let allWidth = 0
    const colIDs =
      params?.columnApi?.getColumns()?.map((col) => {
        // if (!grid && col.gridOptionService) {
        // }
        return col.getColId()
      }) || []

    ;(params.columnApi.getColumns() || []).forEach((col: { getActualWidth: () => number }) => {
      allWidth += col.getActualWidth()
    })
    console.log(colIDs)

    if (gridContainer && allWidth > gridContainer.clientWidth) {
      console.log(allWidth, gridContainer.clientWidth)
      console.log(1, params)
      params?.columnApi?.autoSizeColumns(colIDs)
    }
  }

  return (
    <StyledAgGrid
      ref={gridRef}
      gridId="estimate-laboratius"
      rowData={rowData}
      getRowId={getRowId}
      columnDefs={estimateLaboriousGenerateTable.columnDefs({
        total: total,
        projectType,
      })}
      rowHeight={56}
      context={estimateTableContext}
      pinnedBottomRowData={[
        {
          id: 'totalRow',
          name: 'ВСЕГО',
          genContractUnitPrice: null,
          genContractPricePerHour: null,
          genContractTotal: (total as EstimateTotal)?.genContractTotal,
          subContractUnitPrice: null,
          subContractPricePerHour: (total as EstimateTotal)?.subContractPricePerHour,
          subContractContractor: null,
          subContractTotal: (total as EstimateTotal)?.subContractTotal,
          humanHoursQuantity: (total as EstimateTotal)?.humanHoursQuantity,
          machineryHoursQuantity: (total as EstimateTotal)?.machineryHoursQuantity,
          income: (total as EstimateTotal)?.income,
          incomePerHour: (total as EstimateTotal)?.incomePerHour,
          quantity: null,
          rentability: (total as EstimateTotal)?.rentability,
        },
      ]}
      suppressScrollOnNewData={true}
      suppressColumnMoveAnimation={true}
      loadingOverlayComponent={CircularProgress}
      noRowsOverlayComponent={() => <div></div>}
      onGridReady={(params) => {
        gridApiRef.current = params.api // Сохраняем ссылку на GridApi
        // Теперь вы можете использовать gridApiRef.current для доступа к API сетки
      }}
      onColumnEverythingChanged={updateColumns}
      onBodyScrollEnd={updateColumns}
      singleClickEdit
      onCellEditingStopped={(event) => {
        const operation = event.data as IPrPrEstimateOperationWithLvl
        const oldOperation = filteredRows.find((el) => el.id === operation.id)
        if (JSON.stringify(operation) === JSON.stringify(oldOperation)) {
          return
        }
        ProductionProgramAPI.updateOperation(operation as IPrPrEstimateOperation).then((data) => {
          setState((prevState) => {
            let operationsCopy: IPrPrEstimateOperationWithLvl[] =
              prevState.operations && getDeepCopy(prevState.operations)
            let operationIndex = operationsCopy.findIndex((el) => el.id === operation.id)
            operationsCopy[operationIndex] = { ...operation }
            return {
              ...prevState,
              operations: operationsCopy,
              total: data.newTotal,
            }
          })
        })
      }}
    />
  )
}
