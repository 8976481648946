import { Button, Divider, Drawer, Stack, Typography } from '@mui/material'
import { ButtonGroupCustom } from 'components/ButtonGroupCustom/ButtonGroupCustom'
import { Form, FormikProvider } from 'formik'
import { theme } from 'global/styles/theme'
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { DrawerTopBar } from 'pages/ReportPage/components/BdrParametersDrawer/BdrParametersDrawer.styles'
import { BdrCompare } from 'pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrCompare'
import { DrawerScrollableWrapper } from 'pages/ReportPage/components/BdrParametersDrawer/components/BdrParametersDrawerForm/BdrParametersDrawerForm.styles'
import { FC, useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'

interface ICompareDrawerProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: { id: number; val: number }[]) => void
}
interface ICompareFormData {
  compared: { id: number; val: number | null }[] | null
}

const validationSchema = yup.object().shape({
  compared: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        val: yup.number().required(),
      }),
    )
    .nullable()
    .required(),
})

export const CompareDrawer: FC<ICompareDrawerProps> = ({ open, onClose, onSubmit }) => {
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose()
    }
  }, [])

  const dataForConfirmDialog: Record<NonNullable<'exit'>, UseExitConfirmProps> = {
    exit: {
      handleConfirm: handleExitConfirm,
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog['exit'])

  const onDrawerClose = (immediately: boolean) => {
    if (immediately || !isFormDirty) {
      onClose()
    } else {
      // setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }

  const initialValues: ICompareFormData = {
    compared: [
      {
        id: 12345,
        val: null,
      },
      {
        id: 12346,
        val: null,
      },
    ],
  }

  const onFormSubmit = (values: ICompareFormData) => {
    const { compared } = values

    if (compared?.length) {
      const nonNullValues = compared.filter((el) => el.val !== null) as { id: number; val: number }[]
      onSubmit(nonNullValues)
      onDrawerClose(true)
    }
  }

  const { formik } = useForm({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onFormSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    setIsFormDirty(dirty)
  }, [dirty])

  return (
    <Drawer open={open} anchor="right" onClose={() => onDrawerClose(false)}>
      <Stack width={'400px'} height={'100%'} spacing={1}>
        <Stack>
          <DrawerTopBar>
            <Typography variant="h1" color={theme.palette.primary.main}>
              Сравнить отчеты
            </Typography>
          </DrawerTopBar>
          <Divider />
        </Stack>
        <FormikProvider value={formik}>
          <Stack component={Form} spacing={2} overflow={'auto'} height={'100%'}>
            <Stack spacing={1.5} overflow={'auto'} height={'100%'}>
              <DrawerScrollableWrapper spacing={1.5}>
                <Stack spacing={1}>
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    fontSize={14}
                    color={theme.palette.secondary.gray}
                    textAlign={'start'}>
                    Выберите отчеты для сравнения
                  </Typography>
                  <Divider color={theme.palette.secondary.gray} />
                </Stack>
                <BdrCompare drawerMode />
              </DrawerScrollableWrapper>

              <ButtonGroupCustom width={'100%'} padding={'20px'}>
                <Button type="submit" disabled={!dirty || isSubmitting || !isValid} variant="contained" color="success">
                  Сравнить
                </Button>
                <Button onClick={() => onDrawerClose(false)} variant="contained" color="primary">
                  Отменить
                </Button>
              </ButtonGroupCustom>
            </Stack>
          </Stack>
        </FormikProvider>
        <ConfirmDialog />
      </Stack>
    </Drawer>
  )
}
