import { ColDef, ColGroupDef, ICellEditorParams, RowEditingStoppedEvent } from 'ag-grid-community'
import { IProductionProgramGenerateTable } from './ProductionProgram.generateTable'
import { CellRenderer } from '../../components/TableAGGrid/CellRenderer/CellRenderer'
import { tableCellInput, tableCellInput_ViewMode } from '../../global/styles/presets'
import {
  ICellNumber,
  ICellNumberEditor,
  ICellString,
  ICellStringEditor,
} from '../../components/TableAGGrid/CellRenderer/CellRenderer.types'
import { ProductionProgramAPI } from '../../global/api/APIMethods/ProductionProgramAPI'
import { History } from 'history'
import { IProductionProgramQueryParams } from './ProductionProgram-def'
import { IPrPrEstimateTotal, TEstimateType } from '../../global/types/commos-def'
import qs from 'qs'

export const cellRendererParamsNumber: ICellNumber = {
  EENumberProps: {
    align: 'center',
    viewModeStyle: { ...tableCellInput_ViewMode },
    TextFieldProps: {
      sx: tableCellInput,
    },
    name: '',
    mode: 'view',
  },
  hideTotal: false,
}
const cellEditorParamsNumber: ICellNumberEditor = {
  EENumberProps: {
    align: 'center',
    viewModeStyle: { ...tableCellInput_ViewMode },
    TextFieldProps: {
      sx: tableCellInput,
    },
    name: '',
    mode: 'edit',
  },
  hideTotal: false,
  onChangeValue: onChangeValueNumber,
}
export const cellRendererParamsString: ICellString = {
  EEStringProps: {
    align: 'center',
    viewModeStyle: { ...tableCellInput_ViewMode },
    TextFieldProps: {
      sx: tableCellInput,
    },
    mode: 'view',
    name: '',
    value: '',
    maxLength: 160,
  },
  hideTotal: false,
}
const cellEditorParamsString: ICellStringEditor = {
  EEStringProps: {
    align: 'center',
    viewModeStyle: { ...tableCellInput_ViewMode },
    TextFieldProps: {
      sx: tableCellInput,
    },
    mode: 'edit',
    name: '',
    value: '',
  },
  hideTotal: false,
  onChangeValue: onChangeValueString,
}

export function onChangeValueNumber(props: ICellNumberEditor & ICellEditorParams, value: any) {
  props.api.applyTransaction({
    update: [{ ...props.node.data, [props.colDef.field || 'none']: value?.floatValue }],
  })
}

export async function onChangeValueString(props: ICellStringEditor & ICellEditorParams, value: any) {
  props.api.applyTransaction({
    update: [{ ...props.node.data, [props.colDef.field || 'none']: value.trim() }],
  })
}

const checkId = (params: any): boolean => params?.data?.id !== 'totalRow' && !params?.data?.hasChild

export const estimateLaboriousGenerateTable = {
  onRowEditingStopped: async function (event: RowEditingStoppedEvent<any, any>) {
    const result: any = await ProductionProgramAPI.updateOperationV2(event.data)
    if (result.updatedTotal)
      event.api.setPinnedBottomRowData([
        {
          id: 'totalRow',
          name: 'Всего',
          humanHoursQuantity: (result.updatedTotal as any)?.humanHoursQuantity || 0,
          machineryHoursQuantity: (result.updatedTotal as any)?.machineryHoursQuantity || 0,
          genContractPricePerHour: (result.updatedTotal as any)?.genContractPricePerHour || 0,
          genContractTotal: (result.updatedTotal as any)?.genContractTotal || 0,
          subContractPricePerHour: (result.updatedTotal as any)?.subContractPricePerHour || 0,
          subContractTotal: (result.updatedTotal as any)?.subContractTotal || 0,
          incomePerHour: (result.updatedTotal as any)?.incomePerHour || 0,
          income: (result.updatedTotal as any)?.income || 0,
          rentability: (result.updatedTotal as any)?.rentability || 0,
        },
      ])
  },
  pinnedBottomRowData: function (props: IProductionProgramGenerateTable): any[] {
    return [
      {
        id: 'totalRow',
        name: 'Всего',
        humanHoursQuantity: (props.total as IPrPrEstimateTotal)?.humanHoursQuantity || 0,
        machineryHoursQuantity: (props.total as IPrPrEstimateTotal)?.machineryHoursQuantity || 0,
        genContractPricePerHour: (props.total as IPrPrEstimateTotal)?.genContractPricePerHour || 0,
        genContractTotal: (props.total as IPrPrEstimateTotal)?.genContractTotal || 0,
        subContractPricePerHour: (props.total as IPrPrEstimateTotal)?.subContractPricePerHour || 0,
        subContractTotal: (props.total as IPrPrEstimateTotal)?.subContractTotal || 0,
        incomePerHour: (props.total as IPrPrEstimateTotal)?.incomePerHour || 0,
        income: (props.total as IPrPrEstimateTotal)?.income || 0,
        rentability: (props.total as IPrPrEstimateTotal)?.rentability || 0,
      },
    ]
  },
  columnDefs: function (props: IProductionProgramGenerateTable) {
    const columns: (ColDef<any, any> | ColGroupDef<any>)[] = [
      {
        field: 'lvl',
        headerName: '',
        sortable: false,
        cellRenderer: CellRenderer.Lvl,
        maxWidth: 44,
        minWidth: 44,
      },
      {
        valueGetter: 'node',
        headerName: '',
        sortable: false,
        cellRenderer: CellRenderer.Arrow,
        maxWidth: 44,
        minWidth: 44,
      },
      {
        field: 'name',
        headerName: 'Наименование работ',
        minWidth: 350,
        maxWidth: 350,
        valueGetter: (params) => params?.data?.name || '',
        cellRenderer: CellRenderer.String,
        cellRendererParams: {
          ...cellRendererParamsString,
          EEStringProps: {
            ...cellRendererParamsString.EEStringProps,
            align: 'left',
            tooltip: true,
          },
        },
      },
      {
        field: 'humanHoursQuantity',
        headerClass: ['column-center'],
        headerName: 'Кол-во ч/ч',
        minWidth: 80,
        maxWidth: 80,
        editable: checkId,
        cellEditor: CellRenderer.NumberEditor,
        cellRenderer: CellRenderer.EstimateNumber,
        cellRendererParams: {
          ...cellRendererParamsNumber,
        },
        cellEditorParams: {
          ...cellEditorParamsNumber,
        },
      },
      {
        field: 'machineryHoursQuantity',
        headerClass: ['column-center'],
        headerName: 'Кол-во м/ч',
        minWidth: 80,
        maxWidth: 80,
        editable: checkId,
        cellEditor: CellRenderer.NumberEditor,
        cellRenderer: CellRenderer.EstimateNumber,
        cellRendererParams: {
          ...cellRendererParamsNumber,
        },
        cellEditorParams: {
          ...cellEditorParamsNumber,
        },
      },
      ...(props.projectType === 'GEN'
        ? ([
            {
              headerName: 'ГЕНПОДРЯД',
              headerClass: ['column-center', 'dark-background'],
              flex: 1,
              children: [
                {
                  field: 'genContractPricePerHour',
                  headerName: 'Цена за час',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 80,
                  flex: 1,
                  valueGetter: (params) => {
                    const totalHoursQty =
                      (Number(params.getValue('humanHoursQuantity')) || 0) +
                      Number(params.getValue('machineryHoursQuantity') || 0)
                    return checkId(params)
                      ? params.getValue('genContractTotal') / totalHoursQty
                      : params.data.genContractPricePerHour
                  },
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
                {
                  field: 'genContractTotal',
                  headerName: 'Сумма',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 120,
                  flex: 1,
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
              ],
            },
            {
              headerName: 'СУБПОДРЯД',
              headerClass: ['column-center', 'dark-background'],
              flex: 1,
              children: [
                {
                  field: 'subContractPricePerHour',
                  headerName: 'Цена за час',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 80,
                  flex: 1,
                  valueGetter: (params) => {
                    const totalHoursQty =
                      (Number(params.getValue('humanHoursQuantity')) || 0) +
                      Number(params.getValue('machineryHoursQuantity') || 0)
                    return checkId(params)
                      ? totalHoursQty > 0
                        ? params.getValue('subContractTotal') / totalHoursQty
                        : 0
                      : params.data.subContractPricePerHour
                  },
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
                {
                  field: 'subContractTotal',
                  headerName: 'Сумма',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 120,
                  flex: 1,
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
                {
                  field: 'subContractContractor',
                  headerName: 'Субподрядчик',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 80,
                  maxWidth: 200,
                  flex: 1,
                  cellRendererParams: {
                    align: 'center',
                  },
                },
              ],
            },
            {
              field: 'incomePerHour',
              headerClass: ['column-center'],
              headerName: 'Прибыль в час',
              minWidth: 80,
              flex: 1,
              valueGetter: (params) => {
                const totalHoursQty =
                  (Number(params.getValue('humanHoursQuantity')) || 0) +
                  Number(params.getValue('machineryHoursQuantity') || 0)
                return checkId(params) ? params.getValue('income') / totalHoursQty : params.data.subContractPricePerHour
              },
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
              },
            },
            {
              field: 'income',
              headerClass: ['column-center'],
              headerName: 'Прибыль всего',
              minWidth: 120,
              flex: 1,
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
              },
            },
            {
              field: 'rentability',
              headerClass: ['column-center'],
              headerName: 'Рентабельность',
              minWidth: 120,
              flex: 1,
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
              },
            },
          ] as (ColDef<any, any> | ColGroupDef<any>)[])
        : ([
            {
              headerName: 'ЗАТРАТЫ',
              headerClass: ['column-center', 'dark-background'],
              flex: 1,
              children: [
                {
                  field: 'subContractPricePerHour',
                  headerName: 'Цена за час',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 80,
                  flex: 1,
                  valueGetter: (params) => {
                    const totalHoursQty =
                      (Number(params.getValue('humanHoursQuantity')) || 0) +
                      Number(params.getValue('machineryHoursQuantity') || 0)
                    return checkId(params)
                      ? totalHoursQty > 0
                        ? params.getValue('subContractTotal') / totalHoursQty
                        : 0
                      : params.data.subContractPricePerHour
                  },
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
                {
                  field: 'subContractTotal',
                  headerName: 'Сумма',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 120,
                  flex: 1,
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
              ],
            },
          ] as (ColDef<any, any> | ColGroupDef<any>)[])),
    ]
    return columns
  },
}

export const estimateWorkloadGenerateTable = {
  onRowEditingStopped: async function (event: RowEditingStoppedEvent<any, any>) {
    const result: any = await ProductionProgramAPI.updateOperationV2(event.data)
    if (result.updatedTotal)
      event.api.setPinnedBottomRowData([
        {
          id: 'totalRow',
          name: 'Всего',
          genContractTotal: (result.updatedTotal as any)?.genContractTotal || 0,
          subContractTotal: (result.updatedTotal as any)?.subContractTotal || 0,
          income: (result.updatedTotal as any)?.income || 0,
          rentability: (result.updatedTotal as any)?.rentability || 0,
        },
      ])
  },
  pinnedBottomRowData: function (props: IProductionProgramGenerateTable): any[] {
    console.log(props.total)
    return [
      {
        id: 'totalRow',
        name: 'Всего',
        genContractTotal: (props.total as IPrPrEstimateTotal)?.genContractTotal || 0,
        subContractTotal: (props.total as IPrPrEstimateTotal)?.subContractTotal || 0,
        income: (props.total as IPrPrEstimateTotal)?.income || 0,
        rentability: (props.total as IPrPrEstimateTotal)?.rentability || 0,
      },
    ]
  },
  columnDefs: function (props: IProductionProgramGenerateTable) {
    const columns: (ColDef<any, any> | ColGroupDef<any>)[] = [
      {
        valueGetter: 'node',
        sortable: false,
        cellRenderer: CellRenderer.Button.EstimateDelete,
        cellRendererParams: {
          onCellClicked: (value: any) => props.switchDeleteConfirm && props.switchDeleteConfirm(value?.data),
          align: 'center',
          hideTotal: true,
        },
        maxWidth: 44,
        minWidth: 44,
      },
      {
        field: 'lvl',
        headerName: '',
        sortable: false,
        cellRenderer: CellRenderer.Lvl,
        maxWidth: 44,
        minWidth: 44,
      },
      {
        valueGetter: 'node',
        headerName: '',
        sortable: false,
        cellRenderer: CellRenderer.Arrow,
        maxWidth: 44,
        minWidth: 44,
      },
      {
        field: 'name',
        headerName: 'Наименование работ',
        minWidth: 350,
        maxWidth: 350,
        editable: checkId,
        cellEditor: CellRenderer.StringEditor,
        cellRenderer: CellRenderer.String,
        cellRendererParams: {
          ...cellRendererParamsString,
          EEStringProps: {
            ...cellRendererParamsString.EEStringProps,
            align: 'left',
            tooltip: true,
          },
        },
        cellEditorParams: {
          ...cellEditorParamsString,
          EEStringProps: {
            ...cellEditorParamsString.EEStringProps,
            align: 'left',
          },
        },
        valueGetter: (params) => params?.data?.name || '',
      },
      {
        field: 'quantity',
        headerClass: ['column-center'],
        headerName: 'Кол-во',
        minWidth: 80,
        flex: 1,
        editable: checkId,
        cellEditor: CellRenderer.NumberEditor,
        cellRenderer: CellRenderer.EstimateNumber,
        cellRendererParams: {
          ...cellRendererParamsNumber,
          hideTotal: true,
        },
        cellEditorParams: {
          ...cellEditorParamsNumber,
        },
      },
      {
        field: 'unitType',
        headerClass: ['column-center'],
        headerName: 'Ед. изм.',
        minWidth: 80,
        flex: 1,
        cellEditor: CellRenderer.StringEditor,
        cellRenderer: CellRenderer.String,
        cellRendererParams: {
          ...cellRendererParamsString,
        },
        cellEditorParams: {
          ...cellEditorParamsString,
        },
        editable: checkId,
      },
      ...(props.projectType === 'GEN'
        ? ([
            {
              headerName: 'ГЕНПОДРЯД',
              headerClass: ['column-center', 'dark-background'],
              flex: 1,
              children: [
                {
                  field: 'genContractUnitPrice',
                  headerName: 'Цена за ед.',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 80,
                  flex: 1,
                  editable: checkId,
                  cellEditor: CellRenderer.NumberEditor,
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                    hideTotal: true,
                  },
                  cellEditorParams: {
                    ...cellEditorParamsNumber,
                  },
                },
                {
                  field: 'genContractTotal',
                  headerName: 'Сумма',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 120,
                  flex: 2,
                  valueGetter: (params) => {
                    return checkId(params)
                      ? (params.getValue('quantity') || 0) * (params.getValue('genContractUnitPrice') || 0)
                      : params.data.genContractTotal
                  },
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
              ],
            },
            {
              headerName: 'СУБПОДРЯД',
              headerClass: ['column-center', 'dark-background'],
              flex: 1,
              children: [
                {
                  field: 'subContractUnitPrice',
                  headerName: 'Цена за ед.',
                  headerClass: ['column-center', 'dark-background'],
                  flex: 1,
                  minWidth: 80,
                  editable: checkId,
                  cellEditor: CellRenderer.NumberEditor,
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                    hideTotal: true,
                  },
                  cellEditorParams: {
                    ...cellEditorParamsNumber,
                  },
                },
                {
                  field: 'subContractTotal',
                  headerName: 'Сумма',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 120,
                  flex: 1,
                  valueGetter: (params) => {
                    return checkId(params)
                      ? (params.getValue('quantity') || 0) * (params.getValue('subContractUnitPrice') || 0)
                      : params.data.subContractTotal
                  },
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
                {
                  field: 'subContractContractor',
                  headerName: 'Субподрядчик',
                  headerClass: ['column-center', 'dark-background'],
                  minWidth: 200,
                  maxWidth: 200,
                  flex: 1,
                  editable: checkId,
                  cellRenderer: CellRenderer.String,
                  cellEditor: CellRenderer.StringEditor,
                  cellRendererParams: {
                    ...cellRendererParamsString,
                  },
                  cellEditorParams: {
                    ...cellEditorParamsString,
                  },
                },
              ],
            },
            {
              field: 'income',
              headerClass: ['column-center'],
              headerName: 'Прибыль',
              minWidth: 120,
              flex: 1,
              valueGetter: (params) => {
                return checkId(params)
                  ? (params.getValue('genContractTotal') || 0) - (params.getValue('subContractTotal') || 0)
                  : params.data.income
              },
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
              },
            },
            {
              field: 'rentability',
              headerClass: ['column-center'],
              headerName: 'Рентабельность',
              minWidth: 120,
              flex: 1,
              valueGetter: (params) => {
                return checkId(params)
                  ? params.getValue('genContractTotal') && params.getValue('genContractTotal') > 0
                    ? (params.getValue('income') / params.getValue('genContractTotal')) * 100
                    : 0
                  : params.data.rentability
              },
              cellRenderer: CellRenderer.EstimateNumber,
              cellRendererParams: {
                ...cellRendererParamsNumber,
                EENumberProps: {
                  ...cellRendererParamsNumber.EENumberProps,
                  NumberFormatProps: {
                    suffix: ' %',
                  },
                  viewModeStyle: { marginLeft: '14px', ...cellRendererParamsNumber.EENumberProps.viewModeStyle },
                },
              },
            },
          ] as (ColDef<any, any> | ColGroupDef<any>)[])
        : ([
            {
              headerName: 'ЗАТРАТЫ',
              headerClass: ['column-center', 'dark-background'],
              flex: 1,
              children: [
                {
                  field: 'subContractUnitPrice',
                  headerName: 'Цена за ед.',
                  headerClass: ['column-center', 'dark-background'],
                  flex: 1,
                  minWidth: 80,
                  editable: checkId,
                  cellEditor: CellRenderer.NumberEditor,
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                    hideTotal: true,
                  },
                  cellEditorParams: {
                    ...cellEditorParamsNumber,
                  },
                },
                {
                  field: 'subContractTotal',
                  headerName: 'Сумма',
                  headerClass: ['column-center', 'dark-background'],
                  flex: 1,
                  minWidth: 120,
                  valueGetter: (params) => {
                    return checkId(params)
                      ? (params.getValue('quantity') || 0) * (params.getValue('subContractUnitPrice') || 0)
                      : params.data.subContractTotal
                  },
                  cellRenderer: CellRenderer.EstimateNumber,
                  cellRendererParams: {
                    ...cellRendererParamsNumber,
                  },
                },
              ],
            },
          ] as (ColDef<any, any> | ColGroupDef<any>)[])),
    ]
    return columns
  },
}
