import { Box, BoxProps, SxProps, TextField, TextFieldProps, Tooltip } from '@mui/material'
import { getFlexJustifyContent } from './utils'
import { ForwardedRef, ReactNode, useState } from 'react'
import React from 'react'

export interface IEEStringProps {
  isDefaultValue?: boolean
  align?: 'center' | 'left' | 'right'
  name: string
  mode: 'edit' | 'view'
  value: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  TextFieldProps?: TextFieldProps
  viewModeStyle?: SxProps
  BoxProps?: BoxProps
  maxLength?: number
  tooltip?: boolean
  tooltipTitle?: string | ReactNode
}

export const EEString = (props: IEEStringProps) => {
  const { align, mode, value, onChange, TextFieldProps, viewModeStyle, BoxProps, maxLength, tooltip, tooltipTitle } =
    props

  return mode === 'edit' ? (
    !props.isDefaultValue ? (
      <TextField
        value={value}
        onChange={onChange}
        {...TextFieldProps}
        inputProps={{
          maxLength: maxLength ? maxLength : 81,
        }}
        sx={{
          ...TextFieldProps?.sx,
          input: {
            textAlign: align || 'center',
            //@ts-ignore
            ...TextFieldProps?.sx['input'],
          },
        }}
      />
    ) : (
      <TextField
        defaultValue={value}
        onChange={onChange}
        {...TextFieldProps}
        inputProps={{
          maxLength: maxLength ? maxLength : 81,
        }}
        sx={{
          ...TextFieldProps?.sx,
          input: {
            textAlign: align || 'center',
            //@ts-ignore
            ...TextFieldProps?.sx['input'],
          },
        }}
      />
    )
  ) : tooltip ? (
    <Tooltip title={tooltipTitle || (value.length > 42 ? value : undefined)} arrow>
      <Box
        {...BoxProps}
        sx={{
          ...viewModeStyle,
          justifyContent: align ? getFlexJustifyContent(align) : 'center',
          textAlign: align || 'center',
        }}>
        {value}
      </Box>
    </Tooltip>
  ) : (
    <Box
      {...BoxProps}
      sx={{
        ...viewModeStyle,
        justifyContent: align ? getFlexJustifyContent(align) : 'center',
        textAlign: align || 'center',
      }}>
      {value}
    </Box>
  )
}

export const EEStringWithRef = React.forwardRef<HTMLInputElement, IEEStringProps>((props: IEEStringProps, ref) => {
  const { align, mode, value, onChange, TextFieldProps, viewModeStyle, BoxProps, maxLength, tooltip, tooltipTitle } =
    props

  return mode === 'edit' ? (
    <TextField
      inputRef={ref} // Передаем ref в TextField
      value={!props.isDefaultValue ? value : undefined}
      defaultValue={props.isDefaultValue ? value : undefined}
      onChange={onChange}
      {...TextFieldProps}
      inputProps={{
        maxLength: maxLength ? maxLength : 81,
      }}
      fullWidth
      required={true}
      sx={{
        ...TextFieldProps?.sx,
        input: {
          textAlign: align || 'center',
          //@ts-ignore
          ...TextFieldProps?.sx['input'],
        },
      }}
    />
  ) : tooltip ? (
    <Tooltip title={tooltipTitle} arrow>
      <Box
        {...BoxProps}
        sx={{
          ...viewModeStyle,
          justifyContent: align ? getFlexJustifyContent(align) : 'center',
          textAlign: align || 'center',
        }}>
        {value}
      </Box>
    </Tooltip>
  ) : (
    <Box
      {...BoxProps}
      sx={{
        ...viewModeStyle,
        justifyContent: align ? getFlexJustifyContent(align) : 'center',
        textAlign: align || 'center',
      }}>
      {value}
    </Box>
  )
})
