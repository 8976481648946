import { Close, Delete, List } from '@mui/icons-material'
import {
  Button,
  MenuItem,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useGetProjectsQuery } from 'api/projects'
import { History } from 'history'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonGroupCustom } from '../../components/ButtonGroupCustom/ButtonGroupCustom'
import { ControlPanel } from '../../components/ControlPanel/ControlPanel'
import { MyModal } from '../../components/MyModal/MyModal'
import { EENumber } from '../../components/editableElements/EENumber'
import { InterfaceContext } from '../../contexts/context.interface'
import { ProjectsAPI } from '../../global/api/APIMethods/ProjectsAPI'
import { ReportAPI } from '../../global/api/APIMethods/ReportAPI'
import { createFullReport } from '../../global/functions'
import { hoverableRow, scrollableWrapper } from '../../global/styles/presets'
import { IProjectInfo, ISavedBDRReportInfo, TBudgetType } from '../../global/types/commos-def'
import { Params } from '../../global/types/params-def'
import { MONTHS } from '../../global/variables'
import { setBdrYears, setBdrParameters } from '../../store/slices/bdrParameters'
import { bdrParametersSelector } from '../../store/slices/bdrParameters/selectors'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { TReportVariant, TReportVariantModalType } from './ReportListPage.types'
import { SelectFinCenter } from './components/SelectFinCenter'
import { BudgetAPI } from 'global/api/APIMethods/BudgetAPI'
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { CompareDrawer } from './components/CompareDrawer'
import { theme } from 'global/styles/theme'

interface IBDRReportListProps {
  budgetType: TBudgetType
  day: string | null
  month: string | null
  year: string | null
  allSavedReports: ISavedBDRReportInfo[] | null
  filteredReports: ISavedBDRReportInfo[] | null
  reportVariant: TReportVariant
  isSelectReportVariantOpen: boolean
  isSelectFinCentersDrawerOpen: boolean
  project: IProjectInfo | null
  refresh: boolean
  openCompareDrawer: boolean
}

export function ReportListPage() {
  const history = useHistory()

  const { t } = useTranslation('ENUMS')

  const { refetch: refreshProjectsList } = useGetProjectsQuery()

  const { projectID } = useParams() as Params

  const interfaceCTX = useContext(InterfaceContext)

  const [state, setState] = React.useState({
    budgetType: getRerortTypeFromPathname(history.location.pathname),
    day: null,
    month: null,
    year: null,
    allSavedReports: null,
    filteredReports: null,
    reportVariant: 'byProject',
    isSelectReportVariantOpen: false,
    isSelectFinCentersDrawerOpen: false,
    refresh: false,
    openCompareDrawer: false,
  } as IBDRReportListProps)

  const reportVariantDescription: Record<TReportVariant, string> = {
    byProject: `Будет создан ${t('BUDGET_TYPE.' + state.budgetType)} всего проекта.`,
    INVEST: `Будет создан отчет капвложений всего проекта.`,
    byFinCenters: `${t('BUDGET_TYPE.' + state.budgetType)} будет создан из бюджетов ЦФО которые
        вы выбрали.`,
    byComparison: `Будет создан ${t('BUDGET_TYPE.' + state.budgetType)} со сравнением выбранных бюджетов`,
  }

  const dispatch = useAppDispatch()
  const bdrParameters = useTypedSelector(bdrParametersSelector)

  React.useEffect(() => {
    Promise.all([
      ReportAPI.getSavedReports(state.budgetType, {
        projects: [projectID],
      }),
      ProjectsAPI.getProjectById(projectID),
    ]).then((data) => {
      const savedReports = data[0]
      const project = data[1]
      setState((prevState) => ({
        ...prevState,
        allSavedReports: savedReports,
        filteredReports: savedReports,
        project: project,
      }))
      initiateBreadcrumbs(project, state.budgetType, history)
    })
  }, [])

  React.useEffect(() => {
    ReportAPI.getSavedReports(state.budgetType, {
      projects: [projectID],
    }).then((data) => {
      const savedReports = data
      setState((prevState) => ({
        ...prevState,
        allSavedReports: savedReports,
      }))
    })
  }, [state.refresh])

  React.useEffect(() => {
    let targetArr = state.allSavedReports

    if (state.year) {
      targetArr = targetArr!.filter((report: any) => report.createdAt.split('.')[2].includes(state.year))
    }
    if (state.month) {
      targetArr = targetArr!.filter((report: any) => report.createdAt.split('.')[1].includes(state.month))
    }
    if (state.day) {
      targetArr = targetArr!.filter((report: any) => report.createdAt.split('.')[0].includes(state.day))
    }

    state.allSavedReports &&
      setState((prevState) => ({
        ...prevState,
        filteredReports: targetArr,
      }))
  }, [state.day, state.month, state.year, state.allSavedReports])

  function getRerortTypeFromPathname(pathname: string): TBudgetType {
    return pathname.includes('capital') || pathname.includes('bdr') ? 'bdr' : 'bdds'
  }

  function changeSelectValue(type: 'year' | 'month' | 'day', newValue: string) {
    setState((prevState) => ({
      ...prevState,
      [type]: newValue,
    }))
  }

  function resetFilters() {
    setState((prevState) => ({
      ...prevState,
      day: null,
      month: null,
      year: null,
    }))
  }

  function controlModals(modalType: TReportVariantModalType, status: boolean) {
    setState((prevState) => ({
      ...prevState,
      [modalType]: status,
    }))
  }

  function changeReportVariant(reportVariant: IBDRReportListProps['reportVariant']) {
    setState((prevState) => ({
      ...prevState,
      reportVariant: reportVariant,
    }))
  }

  function acceptChosenReportVariant(project: IProjectInfo, budgetType: TBudgetType, history: History) {
    switch (state.reportVariant) {
      case 'byProject':
        createFullReport(project, budgetType, history, undefined, undefined)
        break
      case 'byFinCenters':
        controlModals('isSelectFinCentersDrawerOpen', true)
        controlModals('isSelectReportVariantOpen', false)
        break
      case 'byComparison':
        controlModals('isSelectFinCentersDrawerOpen', true)
        controlModals('isSelectReportVariantOpen', false)
        break
    }
  }

  function getAvailableOptions(optionType: 'year' | 'month' | 'day') {
    if (state.allSavedReports != null) {
      let targetArray = state.allSavedReports.map((report: any) => {
        if (optionType == 'year') {
          return report.createdAt.split('.')[2]
        }
        if (optionType == 'month') {
          return report.createdAt.split('.')[1]
        }
        if (optionType == 'day') {
          return report.createdAt.split('.')[0]
        }
      })
      targetArray = Array.from(new Set(targetArray)).sort((a, b) => Number(a) - Number(b))
      targetArray = targetArray.map((value: any) => {
        return {
          label: value,
          value: value,
        }
      })
      return targetArray.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {optionType == 'month' ? t('MONTHS_ABBR.' + MONTHS[item.value - 1]) : item.label}
          </MenuItem>
        )
      })
    }
  }

  function createBDRFromChosenFinCenters(finCentersIDArr: string[], history: History) {
    if (!state.project) return

    dispatch(
      setBdrYears({
        yearStart: Number(state.project.dateStart.split('.')[2]),
        yearEnd: Number(state.project.dateEnd.split('.')[2]),
      }),
    )
    createFullReport(state.project, state.budgetType, history, undefined, finCentersIDArr)
  }

  function initiateBreadcrumbs(project: IProjectInfo, budgetType: TBudgetType, history: History) {
    const isCapital = history.location.pathname.endsWith('capital') && budgetType === 'bdr'
    interfaceCTX.setBreadcrumbsProps([
      {
        variant: 'link',
        title: `Проект ${project.name}`,
        path: `/projects/${project.id}/menu`,
      },
      {
        variant: 'title',
        title: `Сохраненные отчеты ${isCapital ? 'Капвложений' : t('BUDGET_TYPE.' + state.budgetType)}`,
      },
    ])
  }

  function onRowClick(id: string | number) {
    if (state.project) {
      dispatch(
        setBdrYears({
          yearStart: Number(state.project.dateStart.split('.')[2]),
          yearEnd: Number(state.project.dateEnd.split('.')[2]),
        }),
      )

      createFullReport(state.project, state.budgetType, history, String(id))
    }
  }

  function onCompareReports(values: { id: number; val: number }[]) {
    if (state.project) {
      dispatch(
        setBdrYears({
          yearStart: Number(state.project.dateStart.split('.')[2]),
          yearEnd: Number(state.project.dateEnd.split('.')[2]),
        }),
      )
      dispatch(
        setBdrParameters({
          ...bdrParameters,
          compared: values.slice(1),
        }),
      )
      const ids = values.map((el) => el.val)

      createFullReport(state.project, state.budgetType, history, String(ids[0]), undefined, undefined, ids.slice(1))
    }
  }

  function handleDeleteReport(confirm: boolean) {
    if (confirm && reportIdForDelete) {
      ReportAPI.deleteReport(state.budgetType, reportIdForDelete).then(() => {
        setState((prevState) => ({
          ...prevState,
          refresh: !prevState.refresh,
        }))
      })
    }
  }

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState('deleteReport')
  const [reportIdForDelete, setReportIdForDelete] = useState<number | null>(null)

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    deleteReport: {
      handleConfirm: handleDeleteReport,
      title: 'Вы действительно хотите удалить отчет?',
      body: 'Выбранный Вами отчет удалится безвозвратно.',
      confirmButtonText: 'Удалить',
      confirmButtonColor: 'error',
    },
  }

  const { ConfirmDialog, openConfirm, isConfirmDialogOpened } = useConfirmDialog(
    dataForConfirmDialog[confirmDialogTrigger],
  )

  const onDeleteReport = (id: number) => {
    setConfirmDialogTrigger('deleteReport')
    openConfirm()
    setReportIdForDelete(id)
  }

  return (
    <Box>
      <SelectFinCenter
        budgetType={state.budgetType}
        isCapital={state.budgetType === 'bdr' && state.project?.type === 'INVEST'}
        isOpen={state.isSelectFinCentersDrawerOpen}
        onCreateReport={(finCentersIDArr) => createBDRFromChosenFinCenters(finCentersIDArr, history)}
        onClose={() => controlModals('isSelectFinCentersDrawerOpen', false)}
        onCancel={() => controlModals('isSelectFinCentersDrawerOpen', false)}
      />
      <MyModal.Wrapper
        open={state.isSelectReportVariantOpen}
        onClose={(e, r) => controlModals('isSelectReportVariantOpen', false)}>
        <MyModal.Inner>
          <MyModal.Title>
            Создать{' '}
            {state.project?.type === 'INVEST' && state.budgetType === 'bdr'
              ? 'отчет капвложений'
              : t('BUDGET_TYPE.' + state.budgetType)}
          </MyModal.Title>
          <MyModal.Guide>
            Выберите способ создания бюджета
            <br />
            доходов и расходов
          </MyModal.Guide>
          <MyModal.ModalRadioGroup
            value={state.reportVariant}
            onChange={(event, value) => changeReportVariant(value as IBDRReportListProps['reportVariant'])}>
            <MyModal.ModalRadio
              label={
                state.project?.type === 'INVEST' && state.budgetType === 'bdr'
                  ? 'Капвложения по всему проекту'
                  : t('BUDGET_TYPE.' + state.budgetType) + ' по всему проекту'
              }
              value="byProject"
            />
            <MyModal.ModalRadio label="Выбрать бюджеты ЦФО" value="byFinCenters" />
            {/* <MyModal.ModalRadio label="Сравнить выбранные бюджеты ЦФО" value="byComparison" /> */}
          </MyModal.ModalRadioGroup>
          <MyModal.Description>
            {state.project?.type === 'INVEST' && state.budgetType === 'bdr'
              ? reportVariantDescription[state.project.type]
              : reportVariantDescription[state.reportVariant]}
          </MyModal.Description>
          <ButtonGroupCustom>
            <Button
              variant="contained"
              color="success"
              onClick={(e) =>
                state.budgetType && state.project && acceptChosenReportVariant(state.project, state.budgetType, history)
              }>
              Выбрать
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => controlModals('isSelectReportVariantOpen', false)}>
              Отменить
            </Button>
          </ButtonGroupCustom>
        </MyModal.Inner>
      </MyModal.Wrapper>
      <ControlPanel.Wrapper>
        <ControlPanel.InnerContainer align="left">
          <ControlPanel.FilterTitle title="СОЗДАН:" />
          <ControlPanel.Select
            key="day"
            value={state.day}
            onSelectChange={(newValue) => changeSelectValue('day', newValue)}
            sx={{ width: '70px' }}
            label="день"
            InputLabelProps={{
              shrink: state.day != null,
            }}>
            {getAvailableOptions('day')}
          </ControlPanel.Select>
          <ControlPanel.Select
            key="month"
            value={state.month}
            onSelectChange={(newValue) => changeSelectValue('month', newValue)}
            sx={{ width: '80px' }}
            label="месяц"
            InputLabelProps={{
              shrink: state.month != null,
            }}>
            {getAvailableOptions('month')}
          </ControlPanel.Select>
          <ControlPanel.Select
            key="year"
            value={state.year}
            onSelectChange={(newValue) => changeSelectValue('year', newValue)}
            sx={{ width: '80px' }}
            label="год"
            InputLabelProps={{
              shrink: state.year != null,
            }}>
            {getAvailableOptions('year')}
          </ControlPanel.Select>
          {(state.day || state.month || state.year) && (
            <Button size="small" sx={{ ml: 1 }} onClick={resetFilters}>
              <Close fontSize="small" />
            </Button>
          )}
        </ControlPanel.InnerContainer>

        <ControlPanel.InnerContainer align="right">
          {state.budgetType === 'bdr' && (
            <>
              <Stack direction="row" mr={2.5} spacing={1} alignItems="center">
                <Box
                  sx={{
                    width: '22px',
                    height: '12px',
                    borderRadius: '10px',
                    background: '#2B364861',
                  }}
                />
                <Typography
                  variant="subtitle2"
                  fontSize="13px"
                  textTransform="uppercase"
                  sx={{ color: theme.palette.text.dark }}>
                  Отчет неактуален
                </Typography>
              </Stack>
              {state.filteredReports && state.filteredReports.length > 1 && (
                <ControlPanel.Btn
                  startIcon={<List />}
                  onClick={(e) => setState((prev) => ({ ...prev, openCompareDrawer: true }))}
                  sx={{ marginRight: 1.5 }}>
                  сравнить отчеты
                </ControlPanel.Btn>
              )}
            </>
          )}
          <ControlPanel.Btn startIcon={<List />} onClick={(e) => controlModals('isSelectReportVariantOpen', true)}>
            Сформировать{' '}
            {state.project?.type === 'INVEST' && state.budgetType === 'bdr'
              ? 'отчет капвложений'
              : t('BUDGET_TYPE.' + state.budgetType)}
          </ControlPanel.Btn>
        </ControlPanel.InnerContainer>
      </ControlPanel.Wrapper>

      <Box mr={1}>
        <TableContainer sx={{ ...scrollableWrapper, height: 'calc(100vh - 155px)' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Тип отчета</TableCell>
                <TableCell>Главный</TableCell>
                <TableCell>Список бюджетов</TableCell>
                {state.project?.type === 'INVEST' ? (
                  <>
                    <TableCell>Затраты план</TableCell>
                    <TableCell>Затраты факт</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{state.budgetType === 'bdr' ? 'Доходы' : 'Операционный поток'}</TableCell>
                    <TableCell>{state.budgetType === 'bdr' ? 'Расходы' : 'Инвестиционный поток'}</TableCell>
                    {state.budgetType === 'bdds' && <TableCell>Финансовый поток</TableCell>}
                    {state.budgetType === 'bdr' && <TableCell>Прибыль до налогообложения</TableCell>}
                    {state.budgetType === 'bdr' && <TableCell>Маржа по ч/п</TableCell>}
                    {state.budgetType === 'bdr' && <TableCell>Рентабельность</TableCell>}
                  </>
                )}
                <TableCell>Сформировал</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.filteredReports &&
                state.filteredReports.map((report) => {
                  const { reportID: id, createdAt, totalSummary, createdBy, synchronizable, isActualState } = report

                  return (
                    <TableRow
                      onClick={() => onRowClick(id)}
                      sx={{
                        '.MuiTableCell-root': {
                          textAlign: 'center !important',
                          backgroundColor: synchronizable ? '#F6F7FB' : '#fff',
                          color: state.budgetType === 'bdr' ? (isActualState ? '#2B3648' : '#9AA2B0') : '#2B3648',
                        },
                        ...hoverableRow,
                      }}>
                      <TableCell>{id}</TableCell>
                      <TableCell>{createdAt}</TableCell>
                      <TableCell>
                        {report.totalSummary.financeCenters && report.totalSummary.financeCenters.length > 0
                          ? 'Сформирован из бюджетов'
                          : 'Полный'}
                      </TableCell>
                      <TableCell
                        onClick={(e) => {
                          e.stopPropagation()
                        }}>
                        <Radio
                          checked={synchronizable}
                          disabled={state.allSavedReports?.length === 1}
                          onClick={(e) => {
                            ReportAPI.setMain(state.budgetType, id as number, synchronizable).then(() => {
                              setState((prevState) => ({
                                ...prevState,
                                allSavedReports: prevState.allSavedReports?.map((item) => {
                                  if (item.reportID === id) {
                                    return {
                                      ...item,
                                      synchronizable: !item.synchronizable,
                                    }
                                  } else
                                    return {
                                      ...item,
                                      synchronizable: false,
                                    }
                                }) as ISavedBDRReportInfo[] | null,
                                filteredReports: prevState.filteredReports?.map((item) => {
                                  if (item.reportID === id) {
                                    return {
                                      ...item,
                                      synchronizable: !item.synchronizable,
                                    }
                                  } else
                                    return {
                                      ...item,
                                      synchronizable: false,
                                    }
                                }) as ISavedBDRReportInfo[] | null,
                              }))
                              refreshProjectsList()
                            })
                            e.stopPropagation()
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            report.totalSummary.financeCenters && report.totalSummary.financeCenters!.length > 0 ? (
                              <Box display="flex" flexDirection="column">
                                {report.totalSummary.financeCenters?.map((finCenter, index) => {
                                  return <Box>{finCenter.name}</Box>
                                })}
                              </Box>
                            ) : (
                              ''
                            )
                          }
                          placement="right">
                          <Box>
                            {report.totalSummary.financeCenters &&
                              (report.totalSummary.financeCenters!.length == 0
                                ? '----'
                                : report.totalSummary.financeCenters.length)}
                          </Box>
                        </Tooltip>
                      </TableCell>
                      {state.project?.type === 'INVEST' ? (
                        <>
                          <TableCell>
                            <EENumber
                              name="Затраты план"
                              mode="view"
                              value={totalSummary.outcomes || 0}
                              NumberFormatProps={{
                                allowNegative: true,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <EENumber
                              name="Затраты факт"
                              mode="view"
                              value={totalSummary.factExpenses || 0}
                              NumberFormatProps={{
                                allowNegative: true,
                              }}
                            />
                          </TableCell>
                        </>
                      ) : (
                        <>
                          {state.budgetType == 'bdds' && (
                            <>
                              <TableCell>
                                <EENumber
                                  name="Операционный поток"
                                  mode="view"
                                  value={totalSummary.saldo.operations || 0}
                                  NumberFormatProps={{
                                    allowNegative: true,
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <EENumber
                                  name="Инвестиционный поток"
                                  mode="view"
                                  value={totalSummary.saldo.invest || 0}
                                  NumberFormatProps={{
                                    allowNegative: true,
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <EENumber
                                  name="Финансовый поток"
                                  mode="view"
                                  value={totalSummary.saldo.financial || 0}
                                  NumberFormatProps={{
                                    allowNegative: true,
                                  }}
                                />
                              </TableCell>
                            </>
                          )}
                          {state.budgetType == 'bdr' && (
                            <>
                              <TableCell>
                                <EENumber name="Доходы" mode="view" value={totalSummary.incomes || 0} />
                              </TableCell>
                              <TableCell>
                                <EENumber name="Расходы" mode="view" value={totalSummary.outcomes || 0} />
                              </TableCell>
                              {/* <TableCell>
                                                        <EENumber
                                                            name="Прочие доходы и расходы"
                                                            mode="view"
                                                            value={totalSummary.otherIncomesOutcomes || 0}
                                                            allowNegative={true}
                                                        />
                                                    </TableCell> */}
                              <TableCell>
                                <EENumber
                                  name="Прибыль до налогообложения"
                                  mode="view"
                                  value={totalSummary.profit_before_tax || 0}
                                  NumberFormatProps={{
                                    allowNegative: true,
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <EENumber
                                  name="Маржа по ч/п"
                                  mode="view"
                                  value={totalSummary.marja || 0}
                                  NumberFormatProps={{
                                    suffix: ' %',
                                    allowNegative: true,
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <EENumber
                                  name="Рентабельность"
                                  mode="view"
                                  value={totalSummary.rentability || 0}
                                  NumberFormatProps={{
                                    suffix: ' %',
                                    allowNegative: true,
                                  }}
                                />
                              </TableCell>
                            </>
                          )}
                        </>
                      )}

                      <TableCell>
                        {`${createdBy.lastName} ${createdBy.firstName[0]}. ${createdBy.middleName?.[0] || ''}`}
                      </TableCell>
                      <TableCell>
                        <Button
                          color={'error'}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation()
                            onDeleteReport(Number(id))
                          }}>
                          <Delete color={'error'} fontSize="small" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ConfirmDialog />

      <CompareDrawer
        open={state.openCompareDrawer}
        onClose={() => setState((prev) => ({ ...prev, openCompareDrawer: false }))}
        onSubmit={onCompareReports}
      />
    </Box>
  )
}
