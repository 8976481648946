import { Tab, Tabs } from '@mui/material'
import { STYLED } from 'global/styles/presets'
import { SyntheticEvent } from 'react'
import { ISubcategoryTabsProps } from './SubcategoryTabs.types'
import { IBDRBudgetPPCategoryInfo, IBDRBudgetSubCategoryInfo } from 'global/api/definitions'

export const SubcategoryTabs: React.FC<ISubcategoryTabsProps> = ({
  subcategory,
  setState,
  options,
  isProductionProgram,
}) => {
  const changeSubcategory = (
    _: SyntheticEvent<Element, Event>,
    subcategoryVal: IBDRBudgetSubCategoryInfo | IBDRBudgetPPCategoryInfo,
  ) => {
    setState((prev) => ({
      ...prev,
      operations: null,
      subcategory: isProductionProgram ? null : (subcategoryVal as IBDRBudgetSubCategoryInfo),
      ppSubcategory: isProductionProgram ? (subcategoryVal as IBDRBudgetPPCategoryInfo) : null,
    }))
  }
  return (
    <Tabs value={subcategory} onChange={changeSubcategory} sx={{ ...STYLED.TABS.OUTLINED, marginLeft: 'auto' }}>
      {options.map((ctgr) => (
        <Tab key={ctgr.first} label={ctgr.second} value={ctgr} />
      ))}
    </Tabs>
  )
}
