import { Stack, Typography } from "@mui/material"
import { AgGridReact } from "ag-grid-react"
import { theme } from "global/styles/theme"
import styled from "styled-components"

export const TABLE_CELL_HEIGHT: number = 30

export const TOTAL_ROW_BACKGROUND_COLOR = "#D1DFF5"

export const BudgetTotalCellStyle = {
  color: theme.palette.primary.main,
  fontWeight: 500,
  textAlign: "center",
  height: "fit-content",
  fontSize: "14px",
}

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CellValue = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`

export const BudgetCellWrapper = styled(Stack)`
  border-right: 1px solid #d1d8fa;
  border-bottom: 1px solid #d1d8fa;
`
interface IStyledAgGridProps {
  isBudgetTable?: boolean
  footerHeight?: number
}

export const StyledAgGrid = styled(AgGridReact<any>)<IStyledAgGridProps>`
  --ag-borders: 1px solid;
  --ag-borders-critical: 1px solid;
  --ag-border-color: #d1d8fa !important;
  --ag-cell-horizontal-border: solid #d1d8fa;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: #d1d8fa !important;
  --ag-header-column-separator-width: 1px;
  --ag-row-border-color: #d1d8fa;
  --ag-header-cell-hover-background-color: #fff !important;
  --ag-header-column-resize-handle-width: 0px;
  --ag-header-column-resize-handle-color: transparent;
  --ag-header-background-color: #fff;
  --ag-header-foreground-color: #fff;
  --ag-header-cell-moving-background-color: #fff;

  height: min-content !important;
  max-height: calc(100vh - 250px);

  .ag-header-cell,
  .ag-header-group-cell {
    color: white !important;
    border-right: 1px solid #d1d8fa;
    // border-left: none;
  }

  .ag-root-wrapper {
    border-radius: 8px 8px 0 0 !important;
    border-right: none;
    border-bottom: none;
  }

  .ag-cell {
    padding: 4px 8px;
    border-right: none;

    div,
    span {
      line-height: 1.3em;
      height: 100%;
    }
  }

  & .ag-row:last-of-type {
    border-bottom: none;
  }

  .ag-layout-normal {
    .ag-body {
      flex-grow: 0;
    }
  }

  .ag-root.ag-unselectable.ag-layout-normal {
    height: auto;
  }

  .ag-root-wrapper.ag-ltr.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 250px);
  }

  .ag-root-wrapper.ag-ltr.ag-keyboard-focus.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 250px);
  }

  .ag-root-wrapper-body.ag-focus-managed.ag-layout-normal {
    height: min-content;
    max-height: calc(100vh - 250px);
  }

  .ag-header-cell-label {
    color: #0044b4;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.17px;
  }

  .ag-row-pinned {
    color: #0044b4;
    font-weight: 500;
    border-bottom: 1px solid #d1d8fa;

    .MuiTypography-body2 {
      color: #0044b4;
      font-weight: 500;
    }
    .ag-cell {
      border-color: transparent;

      &:nth-of-type(2) {
        .ag-cell-value > div {
          border-color: transparent;
        }
      }
      .ag-cell-value > div {
        border-bottom-color: transparent;
      }
    }
  }
  .ag-floating-bottom {
    border-top: none;
  }
  .ag-floating-bottom .ag-cell {
    min-height: 100%;

    div {
      justify-content: center;
    }
  }

  .ag-header-cell-label,
  .header-cell-center {
    justify-content: center;
  }

  .ag-cell-last-left-pinned {
    line-height: 1.5em;
  }
`
