import { IReportRelationshipItem, ISavedBDRReportInfo, ProjectType, TBudgetType } from '../../types/commos-def'
import { errorHandler, IQueryObj, req, successHandler } from '../api'
import {
  IBdrReportBudgetsInfoResponse,
  IBdrReportBudgetsQuery,
  IBdrReportBySubBillQuery,
  IBdrReportBySubBillResponse,
  IBdrReportRequestBody,
  ICompareReportResponse,
  IFinCenterBudgetQuery,
  IPrPrBySubBillQuery,
  IPrPrBySubBillResponse,
  IReportExcelQuery,
  IReportQuery,
  IReportResponse,
  IReportSavedListQuery,
  TReportCapitalQuery,
} from '../definitions'
import { Buffer } from 'buffer'
import { getReportValueName } from '../../functions'

export class ReportAPI {
  static async checkIsReportEmpty(
    budgetType: TBudgetType,
    query: Pick<IReportQuery, 'financeCenters' | 'projects'>,
  ): Promise<boolean> {
    return await req
      .get(`/${budgetType}/mapping/report/is-empty`, query)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getReport(budgetType: TBudgetType, query: Partial<TReportCapitalQuery>): Promise<IReportResponse> {
    return await req
      .get(`/${budgetType}/mapping/report`, query)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getBdrReport(
    budgetType: TBudgetType,
    params: Partial<IReportQuery>,
    body: IBdrReportRequestBody,
  ): Promise<IReportResponse> {
    return await req
      .post(`/${budgetType}/mapping/report/combined`, body, params)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getBdrCompareReport(
    budgetType: TBudgetType,
    params: Partial<IReportQuery>,
    body: IBdrReportRequestBody,
  ): Promise<ICompareReportResponse> {
    return await req
      .post(`/${budgetType}/mapping/report/combined/V2`, body, params)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getNewBdrReport(
    params: Partial<IReportQuery>,
    body: IBdrReportRequestBody,
  ): Promise<ICompareReportResponse> {
    return await req
      .post(`/bdr/mapping/report/preview-combined`, body, params)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getAllRelationships(
    budgetType: TBudgetType,
    projectType?: ProjectType,
  ): Promise<{ data: IReportRelationshipItem[]; maxDeep: number }> {
    return await req
      .get(`/${budgetType}/mapping/all-relationships`, { budgetType: projectType })
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getSubBills(
    budgetType: TBudgetType,
    query: IFinCenterBudgetQuery | IQueryObj,
  ): Promise<{ data: IReportRelationshipItem[]; maxDeep: number }> {
    return await req
      .get(`/${budgetType}/budget/report/get/sub-bills`, query)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }

  static async getSavedReports(budgetType: TBudgetType, query: IReportSavedListQuery): Promise<ISavedBDRReportInfo[]> {
    return await req
      .get(`/${budgetType}/mapping/report/saved/list`, query)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => errorHandler(error))
  }

  static async saveReport(
    budgetType: TBudgetType,
    query: IReportSavedListQuery,
    setSavedReportID: (savedReportID: number) => void,
  ): Promise<void> {
    return await req
      .get(`/${budgetType}/mapping/report/save`, query)
      .then(({ data }) => {
        setSavedReportID(data)
        successHandler('Отчет сохранен')
      })
      .catch((error) => errorHandler(error))
  }

  static async setMain(budgetType: TBudgetType, param: number, prevStatus: boolean): Promise<void> {
    return await req
      .post(`/${budgetType}/mapping/report/saved/${param}/set-main`)
      .then((data) => {
        if (!prevStatus) {
          successHandler(`Отчет помечен как главный`)
        }
      })
      .catch((error) => errorHandler(error))
  }

  static async deleteReport(budgetType: TBudgetType, savedReportID: number): Promise<void> {
    return await req
      .delete(`/${budgetType}/mapping/report/saved/${savedReportID}/delete`)
      .then((data) => {
        successHandler('Отчет удален')
      })
      .catch((error) => errorHandler(error))
  }

  static async getExcelReport({
    query,
    file,
  }: {
    query: Partial<IReportExcelQuery>
    file: { projectName: string; typeName: string }
  }): Promise<void> {
    return await req
      .get(`/${query.budgetType}/mapping/report/excel`, query)
      .then(({ data }) => {
        const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]))
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute(
          'download',
          `${file.typeName} проекта ${file.projectName}, отчет №${query.savedReportID}.xlsx`,
        )
        link.click()
        window.URL.revokeObjectURL(blobUrl)
        return
      })
      .catch((error) => errorHandler(error))
  }

  static async getCombinedBdrExcelReport({
    file,
    params,
    body,
  }: {
    file: { projectName: string; typeName: string }
    params: Partial<IReportExcelQuery>
    body: IBdrReportRequestBody
  }): Promise<void> {
    return await req
      .post(`/bdr/mapping/report/excel/combined`, body, params)
      .then(({ data }) => {
        const blobUrl = window.URL.createObjectURL(new Blob([Buffer.from(data, 'base64')]))
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute(
          'download',
          `${file.typeName} проекта ${file.projectName}, отчет №${params.savedReportID}.xlsx`,
        )
        link.click()
        window.URL.revokeObjectURL(blobUrl)
        return
      })
      .catch((error) => errorHandler(error))
  }

  static async getBdrReportBudgetsInfo(
    params: IBdrReportBudgetsQuery,
    onError: () => void,
  ): Promise<IBdrReportBudgetsInfoResponse> {
    return await req
      .get(`/bdr/budget/report/get/included-finance-centers`, params)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        console.error(error)
        onError()
      })
  }

  static async getBdrReportBySubBill(params: IBdrReportBySubBillQuery): Promise<IBdrReportBySubBillResponse> {
    return await req
      .get(`/bdr/budget/report/get/by-sub-bill`, params)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
  static async getPrPrBySubBill(params: IPrPrBySubBillQuery): Promise<IPrPrBySubBillResponse> {
    return await req
      .get(`/production-program/estimate/get-list-by-sub-bill`, params)
      .then(({ data }) => {
        return data
      })
      .catch((error) => errorHandler(error))
  }
}
