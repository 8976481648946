import { FC, useCallback, useEffect, useMemo } from 'react'
import {
  IBdrParametersDrawerFormProps,
  IBdrParametersFormData,
  IBdrPeriod,
  TBdrPeriodType,
  bdrPeriodTypeRuByEn,
  bdrPeriodTypes,
} from './BdrParametersDrawerForm.types'
import { theme } from '../../../../../../global/styles/theme'
import { Box, Button, Checkbox, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'
import { STYLED } from '../../../../../../global/styles/presets'
import {
  DrawerAccordion,
  DrawerAccordionContent,
  DrawerAccordionSummary,
  DrawerScrollableWrapper,
  WarningWrapper,
} from './BdrParametersDrawerForm.styles'
import { Add as AddIcon, Error as ErrorIcon, Delete } from '@mui/icons-material'
import { ButtonGroupCustom } from '../../../../../../components/ButtonGroupCustom/ButtonGroupCustom'
import { Form, FormikProvider } from 'formik'
import { useForm } from '../../../../../../hooks/useForm'
import { validationBdrParameters } from './validation'
import { BdrPeriodItem } from '../BdrPeriodItem'
import { BdrResultParameters } from '../BdrResultParameters'
import { useAppDispatch, useTypedSelector } from '../../../../../../store/store'
import { setBdrParameters } from '../../../../../../store/slices/bdrParameters'
import { bdrParametersSelector } from '../../../../../../store/slices/bdrParameters/selectors'
import { useHistory } from 'react-router-dom'
import { changeQueryParams, getMonthRange, getQuarterRange, getQueryParam } from '../../../../../../global/functions'
import { MONTHS, QUARTERS } from '../../../../../../global/variables'
import {
  TMonthName,
  TQuarterName,
  monthByQuarterEnd,
  monthByQuarterStart,
} from '../../../../../../global/types/commos-def'
import { useTranslation } from 'react-i18next'
import RemoveIcon from '@mui/icons-material/Remove'
import { BdrCompare } from './BdrCompare'

export const BdrParametersDrawerForm: FC<IBdrParametersDrawerFormProps> = ({
  availableYears,
  onFormChange,
  onDrawerClose,
}) => {
  const maxBdrPeriodsLimit: number = 3
  const dispatch = useAppDispatch()
  const bdrParameters = useTypedSelector(bdrParametersSelector)
  const history = useHistory()
  // const reportType = getQueryParam(history, 'reportType') as TBdrPeriodType
  const { t } = useTranslation('ENUMS')

  const historySavedReportID = getQueryParam(history, 'savedReportID')

  const emptyBdrPeriod = (indicators: boolean): IBdrPeriod => {
    return {
      id: Date.now(),
      // @ts-ignore
      start: { month: null, quarter: null, year: null },
      // @ts-ignore
      end: { month: null, quarter: null, year: null },
      plan: indicators,
      fact: indicators,
      deviation: indicators,
      columnsHeader: [],
    }
  }

  const initialValues: IBdrParametersFormData = useMemo(
    () => ({
      // если  годы, то отображается два выпадающих списка с годами
      // второй больше первого (остальные скрываются из списка)
      // первый меньше второго (остальные скрываются из списка)
      periodType: bdrParameters.periodType,

      periods: bdrParameters.periods,
      result: bdrParameters.result,
      yearStart: Number(getQueryParam(history, 'yearStart')) || null,
      yearEnd: Number(getQueryParam(history, 'yearEnd')) || null,
      isResultShown: bdrParameters.isResultShown,
      // isResultDisabled: bdrParameters.isResultDisabled,

      // disabled, если нет ни одного периода, у которого включено отклонение (done)
      isPercentageShown: bdrParameters.isPercentageShown,

      compared: bdrParameters.compared,
    }),
    [bdrParameters],
  )

  const onSubmit = useCallback((values: IBdrParametersFormData) => {
    const { periodType, result, isResultShown, isPercentageShown } = values

    let yearStart = Infinity
    let yearEnd = -Infinity
    const yersLength = new Set(
      values.periods.reduce((acc, period) => {
        acc.push(period.start.year)
        acc.push(period.end.year)
        return acc
      }, [] as number[]),
    )

    const localPeriods = values.periods.map((period) => {
      if (period.start.year < yearStart) yearStart = period.start.year

      if (period.end.year > yearEnd) yearEnd = period.end.year
      function getName(
        keyTranslate: string,
        isStart: boolean = true,
        value: TMonthName | TQuarterName,
        iterator?: number,
      ) {
        const periodKey = isStart ? 'start' : 'end'
        return yersLength.size > 1
          ? `${t(keyTranslate + value)},\u00A0${period[periodKey].year + (iterator !== undefined ? iterator + 1 : 0)}`
          : `${t(keyTranslate + value)}`
      }

      if (periodType === 'MONTHLY') {
        const monthStart = period.start.month
        const monthEnd = period.end.month
        let itemsArr: any[] = []

        if (period.start.year !== period.end.year) {
          let monthRange = getMonthRange(monthStart!, 'DECEMBER', MONTHS).map((month: TMonthName) =>
            getName('MONTHS.', true, month),
          )

          itemsArr = monthRange

          for (let i = 0; i < period.end.year - period.start.year - 1; i++) {
            itemsArr = [
              ...itemsArr,
              ...getMonthRange('JANUARY', 'DECEMBER', MONTHS).map((month: TMonthName) =>
                getName('MONTHS.', true, month, i),
              ),
            ]
          }

          monthRange = getMonthRange('JANUARY', monthEnd!, MONTHS).map((month: TMonthName) =>
            getName('MONTHS.', false, month),
          )

          itemsArr = [...itemsArr, ...monthRange]
        } else {
          console.log('first else')
          itemsArr = getMonthRange(monthStart!, monthEnd!, MONTHS).map((month: TMonthName) =>
            getName('MONTHS.', true, month),
          )
        }

        return { ...period, columnsHeader: itemsArr }
      }

      if (periodType === 'QUARTERLY') {
        console.log('QUARTERLY')
        const quarterStart = period.start.quarter as TQuarterName
        const quarterEnd = period.end.quarter as TQuarterName
        let itemsArr: any[] = []

        if (period.start.year !== period.end.year) {
          let quarterRange = getQuarterRange(quarterStart!, 'FOURTH', QUARTERS).map((quarter: TQuarterName) =>
            getName('QUARTERS.', true, quarter),
          )

          itemsArr = quarterRange

          for (let i = 0; i < period.end.year - period.start.year - 1; i++) {
            itemsArr = [
              ...itemsArr,
              ...getQuarterRange('FIRST', 'FOURTH', QUARTERS).map((quarter: TQuarterName) =>
                getName('QUARTERS.', true, quarter, i),
              ),
            ]
          }

          quarterRange = getQuarterRange('FIRST', quarterEnd!, QUARTERS).map((quarter: TQuarterName) =>
            getName('QUARTERS.', false, quarter),
          )

          itemsArr = [...itemsArr, ...quarterRange]
        } else {
          itemsArr = getQuarterRange(quarterStart!, quarterEnd!, QUARTERS).map((quarter: TQuarterName) =>
            getName('QUARTERS.', true, quarter),
          )
        }
        return { ...period, columnsHeader: itemsArr }
      }

      if (periodType === 'YEARLY') {
        console.log('YEARLY')
        return {
          ...period,
          columnsHeader: Array.from(
            { length: period.end.year - period.start.year + 1 },
            (_, index) => period.start.year + index,
          ),
        }
      }
      console.log('itemsArr>>>', period)

      return period
    })
    console.log('localPeriods>>>', localPeriods)

    dispatch(
      setBdrParameters({
        ...values,
        periods: localPeriods,
        isResultShown: values.isResultShown ? Object.values(values.result).some((item) => item) : values.isResultShown,
        yearStart,
        yearEnd,
      }),
    )
    changeQueryParams(history, {
      reportType: periodType,
      yearStart,
      yearEnd,
      comparedReporsIds: values.compared ? values.compared.map((el) => el.val).toString() : undefined,
    })
    onDrawerClose(true)
  }, [])

  const { formik } = useForm({
    validationSchema: validationBdrParameters,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useEffect(() => {
    // console.log('values: ', values)
  }, [values])

  const onChangeBdrPeriodType = (periodType: TBdrPeriodType) => {
    let localPeriods = [...values.periods]
    // firstPeriod

    localPeriods = localPeriods.map((period) => {
      switch (periodType) {
        case 'MONTHLY':
          return {
            ...period,
            start: { month: 'JANUARY', quarter: null, year: period.start.year },
            end: { month: 'DECEMBER', quarter: null, year: period.end.year },
          }

        case 'QUARTERLY':
          return {
            ...period,
            start: { month: null, quarter: 'FIRST', year: period.start.year },
            end: { month: null, quarter: 'FOURTH', year: period.end.year },
          }

        default:
          return period
      }
    })

    setFieldValue('periodType', periodType)
    setFieldValue('periods', localPeriods)
  }

  const onAddBdrPeriod = () => {
    setFieldValue('periods', [...values.periods, emptyBdrPeriod(false)])
  }

  const onChangeResultVisibility = (checked: boolean) => {
    setFieldValue('isResultShown', checked)
  }

  const onChangePercentageVisibility = (checked: boolean) => {
    setFieldValue('isPercentageShown', checked)
  }

  const getPeriodDate = (year: number, month?: TMonthName) => {
    if (month) return new Date(`${year}-${MONTHS.indexOf(month) + 1}`).getTime()
    else return new Date(String(year)).getTime()
  }

  const isPeriodsOverlap = useMemo(() => {
    let isOverlap: boolean = false

    if (values.periods.length > 1) {
      switch (values.periodType) {
        case 'MONTHLY':
          isOverlap = values.periods.some((period) => {
            if (!period.start.month || !period.end.month || !period.start.year || !period.end.year) {
              return false
            }

            const dateStart = getPeriodDate(period.start.year, period.start.month)
            const dateEnd = getPeriodDate(period.end.year, period.end.month)

            return values.periods.some((innerPeriod) => {
              if (
                innerPeriod.id === period.id ||
                !innerPeriod.start.month ||
                !innerPeriod.end.month ||
                !innerPeriod.start.year ||
                !innerPeriod.end.year
              ) {
                return false
              }

              const innerDateStart = getPeriodDate(innerPeriod.start.year, innerPeriod.start.month)
              const innerDateEnd = getPeriodDate(innerPeriod.end.year, innerPeriod.end.month)

              let isDateStartOverlap = innerDateStart === dateStart || innerDateEnd === dateStart
              let isDateEndOverlap = innerDateStart === dateEnd || innerDateEnd === dateEnd

              if (!isDateStartOverlap && innerDateStart < dateStart && dateStart < innerDateEnd) {
                isDateStartOverlap = true
              }

              if (!isDateEndOverlap && innerDateStart < dateEnd && dateEnd < innerDateEnd) {
                isDateEndOverlap = true
              }

              return isDateStartOverlap || isDateEndOverlap
            })
          })

          break

        case 'QUARTERLY':
          isOverlap = values.periods.some((period) => {
            if (!period.start.quarter || !period.end.quarter || !period.start.year || !period.end.year) {
              return false
            }

            const dateStart = getPeriodDate(period.start.year, monthByQuarterStart[period.start.quarter])
            const dateEnd = getPeriodDate(period.end.year, monthByQuarterEnd[period.end.quarter])

            return values.periods.some((innerPeriod) => {
              if (
                innerPeriod.id === period.id ||
                !innerPeriod.start.quarter ||
                !innerPeriod.end.quarter ||
                !innerPeriod.start.year ||
                !innerPeriod.end.year
              ) {
                return false
              }

              const innerDateStart = getPeriodDate(
                innerPeriod.start.year,
                monthByQuarterStart[innerPeriod.start.quarter],
              )
              const innerDateEnd = getPeriodDate(innerPeriod.end.year, monthByQuarterEnd[innerPeriod.end.quarter])

              let isDateStartOverlap = innerDateStart === dateStart || innerDateEnd === dateStart
              let isDateEndOverlap = innerDateStart === dateEnd || innerDateEnd === dateEnd

              if (!isDateStartOverlap && innerDateStart < dateStart && dateStart < innerDateEnd) {
                isDateStartOverlap = true
              }

              if (!isDateEndOverlap && innerDateStart < dateEnd && dateEnd < innerDateEnd) {
                isDateEndOverlap = true
              }

              return isDateStartOverlap || isDateEndOverlap
            })
          })
          break

        case 'YEARLY':
          isOverlap = values.periods.some((period) => {
            const yearStart = period.start.year
            const yearEnd = period.end.year
            if (!yearStart || !yearEnd) return false

            return values.periods.some((innerPeriod) => {
              if (innerPeriod.id === period.id || !innerPeriod.start.year || !innerPeriod.end.year) return false

              const isSameYearStart = innerPeriod.start.year === yearStart || innerPeriod.end.year === yearStart
              const isSameYearEnd = innerPeriod.start.year === yearEnd || innerPeriod.end.year === yearEnd

              const isYearStartInvalid = innerPeriod.start.year < yearStart && yearStart < innerPeriod.end.year
              const isYearEndnvalid = innerPeriod.start.year < yearEnd && yearEnd < innerPeriod.end.year

              return isSameYearStart || isSameYearEnd || isYearStartInvalid || isYearEndnvalid
            })
          })

          break

        default:
          break
      }
    }

    return isOverlap
  }, [values.periods])

  const isSubmitBtnDisabled = useMemo(() => {
    if (isPeriodsOverlap) return true

    return values.periods.some((period) => {
      const allSwitchesDisabled = !period.plan && !period.fact && !period.deviation
      let fieldsEmpty = false

      if (values.periodType === 'MONTHLY') {
        if (!period.start.year || !period.end.year || !period.start.month || !period.end.month) fieldsEmpty = true
      }
      if (values.periodType === 'QUARTERLY') {
        if (!period.start.year || !period.end.year || !period.start.quarter || !period.end.quarter) fieldsEmpty = true
      }

      if (values.periodType === 'YEARLY') {
        if (!period.start.year || !period.end.year) fieldsEmpty = true
      }

      return allSwitchesDisabled || fieldsEmpty
    })
  }, [values.periods, values.result, values.isResultShown])

  const isAddBtnDisabled = useMemo(() => {
    return values.periods.length === maxBdrPeriodsLimit || isSubmitBtnDisabled
  }, [values.periods])

  const isPercentageDisabled = useMemo(() => {
    const anyPeriodDeviationSwitchActived = values.periods.some((period) => period.deviation)

    if (!anyPeriodDeviationSwitchActived) setFieldValue('isPercentageShown', false)

    return !anyPeriodDeviationSwitchActived
  }, [values.periods])

  const getErrorInfo = useMemo(() => {
    // Итого не может быть отображен: периоды накладываются:
    // Если есть период, у которого начало или конец меньше или равны, чем конец любого другого периода
    // и при этом больше, чем начало любого другого периода + (id должны быть не равны)
    const warningInfo: string[] = []

    if (isPeriodsOverlap) {
      warningInfo.push('Периоды накладываются.')
    }

    return warningInfo.length ? warningInfo : null
  }, [values.periods])

  return (
    <FormikProvider value={formik}>
      <Stack component={Form} spacing={2} overflow={'auto'} height={'100%'}>
        <Stack padding={'0 20px'} spacing={2}>
          <Stack spacing={1}>
            <Typography variant="body1" fontSize={14} color={theme.palette.secondary.gray} textAlign={'start'}>
              Период отображения
            </Typography>
            <Divider color={theme.palette.secondary.gray} />
          </Stack>

          <Tabs
            onChange={(e, periodType) => onChangeBdrPeriodType(periodType)}
            value={values.periodType}
            sx={{ ...STYLED.TABS.OUTLINED }}>
            {bdrPeriodTypes.map((periodType) => (
              <Tab key={periodType} label={bdrPeriodTypeRuByEn[periodType]} value={periodType} style={{ flex: 1 }} />
            ))}
          </Tabs>
        </Stack>

        <Stack spacing={1.5} overflow={'auto'} height={'100%'}>
          <DrawerScrollableWrapper spacing={1.5}>
            <DrawerAccordion defaultExpanded={true}>
              <DrawerAccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="periods-content"
                id="periods-header">
                <Typography
                  variant="body1"
                  fontWeight={500}
                  fontSize={14}
                  color={theme.palette.secondary.gray}
                  textAlign={'start'}>
                  Анализ
                </Typography>
              </DrawerAccordionSummary>
              <DrawerAccordionContent>
                <Stack spacing={1.5}>
                  {values.periods.map((periodItem, i) => (
                    <BdrPeriodItem
                      key={periodItem.id}
                      availableYears={availableYears}
                      periodItem={periodItem}
                      isDeleteBtnShown={i !== 0}
                    />
                  ))}

                  <Button
                    onClick={onAddBdrPeriod}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{ bgcolor: 'transparent' }}
                    disabled={isAddBtnDisabled}>
                    Добавить период
                  </Button>

                  {values.periods.length === maxBdrPeriodsLimit && (
                    <Typography variant="body2" textAlign={'center'} color={theme.palette.primary.main} fontSize={12}>
                      Максимальное количество периодов - {maxBdrPeriodsLimit}.
                    </Typography>
                  )}

                  {getErrorInfo?.length && (
                    <WarningWrapper>
                      <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <ErrorIcon style={{ fill: theme.palette.text.light }} />
                        <Typography variant="body1" fontSize={18} color={theme.palette.text.light}>
                          Внимание
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        {getErrorInfo.map((error, index) => (
                          <Typography variant="body2" fontSize={16} color={theme.palette.text.light}>
                            {error}
                          </Typography>
                        ))}
                      </Stack>
                    </WarningWrapper>
                  )}
                </Stack>
              </DrawerAccordionContent>
            </DrawerAccordion>

            {historySavedReportID && (
              <DrawerAccordion defaultExpanded={true}>
                <DrawerAccordionSummary
                  expandIcon={<CustomExpandIcon />}
                  aria-controls="compare-content"
                  id="compare-header">
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    fontSize={14}
                    color={theme.palette.secondary.gray}
                    textAlign={'start'}>
                    Отчеты для сравнения
                  </Typography>
                </DrawerAccordionSummary>
                <DrawerAccordionContent>
                  <BdrCompare />
                </DrawerAccordionContent>
              </DrawerAccordion>
            )}

            <BdrResultParameters disabled={!values.isResultShown} />

            <Stack spacing={1}>
              <Stack direction={'row'} alignItems={'center'}>
                <Checkbox
                  checked={values.isResultShown}
                  onChange={(e, checked) => onChangeResultVisibility(checked)}
                  // disabled={values.isResultDisabled}
                />
                <Typography variant="body2">Отображать Итого</Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <Checkbox
                  checked={values.isPercentageShown}
                  onChange={(e, checked) => onChangePercentageVisibility(checked)}
                  disabled={isPercentageDisabled}
                />
                <Typography
                  variant="body2"
                  color={isPercentageDisabled ? theme.palette.text.disabled : theme.palette.text.dark}>
                  Показать прирост в процентах
                </Typography>
              </Stack>
            </Stack>
          </DrawerScrollableWrapper>

          <ButtonGroupCustom width={'100%'} padding={'20px'}>
            <Button
              type="submit"
              disabled={!dirty || isSubmitBtnDisabled || !isValid}
              variant="contained"
              color="success">
              Применить
            </Button>
            <Button onClick={() => onDrawerClose(false)} variant="contained" color="primary">
              Отменить
            </Button>
          </ButtonGroupCustom>
        </Stack>
      </Stack>
    </FormikProvider>
  )
}

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        color: '#0044B4',

        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
      }}>
      <div className="expandIconWrapper">
        <RemoveIcon fontSize="small" />
      </div>
      <div className="collapsIconWrapper">
        <AddIcon fontSize="small" />
      </div>
    </Box>
  )
}
